import { Button, Col, FormInstance, Modal, Row, Select, Typography, UploadFile } from 'antd'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import {
	IIssueAttachments,
	IIssueDrawerPermissions,
	IIssueStatus,
	IStatusesIds
} from 'interfaces/IIssue'
import { IAppUser } from 'interfaces/IUser'
import { FC } from 'react'
import { setDtoValue } from 'store/issueSlice'
// import { useIssueState } from 'widgets/issue/model/issue-state'

const { Option } = Select
const { Title } = Typography

interface IIssueDrawerExtraProps {
	isLoading: boolean
	user: IAppUser | null
	statusList: IIssueStatus[]
	permissions: IIssueDrawerPermissions
	statuses: IStatusesIds
	disabled: boolean
	issueForm: FormInstance
	filesToUpload: UploadFile[]
	attachments: IIssueAttachments[]
}
const { confirm } = Modal

const IssueDrawerExtra: FC<IIssueDrawerExtraProps> = ({
	isLoading,
	user,
	statusList,
	permissions,
	statuses,
	disabled,
	issueForm,
	filesToUpload,
	attachments
}) => {
	const dispatch = useAppDispatch()
	const { init: issueInit, dto: issueDto } = useAppSelector(state => state.issues)
	// const { issueInit, issueDto } = useIssueState()

	const { issueTypes, issueSubTypes, violationTypes } = useAppSelector(state => state.environment)

	const onStatusChange = (statusId: string) => {
		dispatch(setDtoValue({ prop: 'issueStatusId', value: statusId }))
	}

	function morph(int: number, array?: any) {
		return (
			(array = array || ['файл', 'файла', 'файлов']) &&
			array[int % 100 > 4 && int % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][int % 10 < 5 ? int % 10 : 5]]
		)
	}

	const checkIsDeletedType = () => {
		const isDeleted =
			issueTypes?.find(x => x.id === issueDto!?.issueTypeId!)?.isDeleted! ||
			issueSubTypes?.find(x => x.id === issueDto!?.issueSubTypeId!)?.isDeleted! ||
			violationTypes?.find(x => x.id === issueDto!?.violationTypeId!)?.isDeleted!

		if (isDeleted) {
			confirm({
				title: 'Внимание!',
				content: (
					<>
						<p>Среди выбранных типов, присутствует тип помеченный на удаление.</p>
						<p>Вы действительно хотите сохранить изменения?</p>
					</>
				),
				onOk() {
					issueForm.submit()
				}
			})
		} else if (
			issueDto?.attachments?.length! > 50 &&
			issueDto?.issueStatusId === '2d9df9da-5340-4f2a-ad30-77a24bb1bfea' &&
			issueInit?.issueStatusId === '5758e9f3-b35b-4d97-8616-aa3bbb96131f'
		) {
			confirm({
				title: 'Внимание!',
				content: `Для перевода в статус Закрыто необходимо удалить лишние ${
					issueDto?.attachments?.length! - 50
				} ${morph(issueDto?.attachments?.length! - 50)}.`
			})
		} else if (attachments!.length + filesToUpload.length > 50) {
			issueForm.validateFields(['filesLimit'])
		} else {
			issueForm.submit()
		}
	}

	return (
		<>
			<Row style={{ width: '405px' }} justify="end" align="bottom" gutter={8}>
				{issueDto?.id !== null && (
					<Col style={{ display: 'flex', flexDirection: 'column' }}>
						{!disabled &&
						(permissions!?.canEdit ||
							(permissions!?.canSetToReady && issueInit!?.issueStatusId !== statuses!?.ready!)) ? (
							<>
								<Title level={5}>Статус:</Title>
								<Select
									style={{ width: 180 }}
									onChange={el => {
										onStatusChange(el)
									}}
									defaultValue={issueDto?.issueStatusId}
									value={issueDto?.issueStatusId}
								>
									{statusList!?.map(status => (
										<Option
											key={status.id}
											value={status.id}
											title={status.name}
											disabled={
												(user?.id !== issueDto?.ownerId &&
													user?.companyId === issueDto?.contractorId &&
													status.id !== statuses.ready!) ||
												(status.id === statuses.ready! && !permissions.canSetToReady) ||
												(status.id !== statuses.ready! && !permissions.canEdit) ||
												(status.id === statuses.ready && issueInit?.issueStatusId !== statuses.open)
											}
										>
											{status.name}
										</Option>
									))}
								</Select>
							</>
						) : (
							statusList!?.find(status => status.id === issueDto?.issueStatusId!)!?.name!
						)}
					</Col>
				)}

				{!disabled &&
					((issueDto?.id === null && permissions.canCreate) ||
						(issueDto?.id !== null && permissions.canEdit) ||
						(issueDto?.id !== null &&
							permissions.canSetToReady &&
							issueDto?.issueStatusId === statuses.ready! &&
							issueInit?.issueStatusId !== statuses.ready!)) && (
						<Col>
							<Button
								htmlType="submit"
								onClick={checkIsDeletedType}
								ghost
								loading={isLoading}
								disabled={
									issueDto!?.issueStatusId!?.length! === 0 || issueDto!?.issueStatusId! === null
								}
							>
								Сохранить
							</Button>
						</Col>
					)}
			</Row>
		</>
	)
}
export default IssueDrawerExtra
