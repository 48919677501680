import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Modal,
	Row,
	Select,
	Tooltip,
	Typography
} from 'antd'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import useDebounce from 'hooks/useDebounce'
import { usePermissions } from 'hooks/usePermissions'
import { IUser } from 'interfaces/IUser'
import { FC, useEffect, useState } from 'react'
import { getUsers } from 'services/AdminService'
import { setCurrentPage, setCurrentReportNotificationFilters } from 'store/reportNotificationSlice'

const { Title, Text } = Typography

interface IProps {
	show: boolean
	onClose: () => void
}

export interface IReportNotificationsFilter {
	projects?: string[]
	contactPersonId?: string
	contractorId?: string
	objectId?: string
	allPeriod?: boolean
	range?: string
	userId?: string
	object?: number
}

const ReportNotificationsModal: FC<IProps> = ({ show, onClose }) => {
	const [filtersForm] = Form.useForm()
	const { project } = useAppSelector(state => state.environment)
	const projects = useAppSelector(state => state.environment.user?.projects)
	const { contractors } = useAppSelector(state => state.environment)
	const [isLoading, setIsLoading] = useState(false)
	const { data: permissions } = usePermissions()
	const [users, setUsers] = useState<IUser[] | null>(null)
	const allPeriod = Form.useWatch('allPeriod', filtersForm)
	const dispatch = useAppDispatch()
	const reportNotificationsFilter = useAppSelector(state => state.reportNotification.filters)
	const contracts = [
		{ id: '1', name: 'Замечание' },
		{ id: '2', name: 'Предписание' },
		{ id: '3', name: 'Чек-лист' }
	]

	const debounceFetch = (value: string) => {
		if (value.length >= 3) {
			setIsLoading(true)
			getUsers({ search: value })
				.then(data => {
					setUsers(data.data)
				})
				.finally(() => setIsLoading(false))
		} else {
			setUsers(permissions!)
			setIsLoading(false)
		}
	}
	const debounce = useDebounce((deb: string) => debounceFetch(deb), 1000)

	useEffect(() => {
		filtersForm.setFieldsValue({ allPeriod: true, range: undefined, projects: [project.id] })
	}, [show])

	const onModalClose = () => {
		filtersForm.resetFields()
		filtersForm.setFieldsValue({ allPeriod: true, range: undefined })
		setUsers(null)
		onClose()
	}

	const setAllProjects = () => {
		filtersForm.setFieldValue(
			'projects',
			projects?.map(p => p.id)
		)
		filtersForm.validateFields()
	}

	useEffect(() => {
		filtersForm.validateFields()
	}, [allPeriod])

	const onSetFilters = (filters: IReportNotificationsFilter, show: boolean) => {
		if (filters) {
			const filtersReportNotification = {
				...reportNotificationsFilter,
				projects: filters.projects,
				from: filters.allPeriod ? undefined : dayjs(filters.range?.[0]).format('YYYY-MM-DD'),
				to: filters.allPeriod ? undefined : dayjs(filters.range?.[1]).format('YYYY-MM-DD'),
				userId: filters.userId,
				object: filters.object,
				contractorId: filters.contractorId
			}
			dispatch(
				setCurrentReportNotificationFilters({
					filters: filtersReportNotification
				})
			)
			if (show) {
				onClose()
			}
		}
		dispatch(setCurrentPage(1))
	}

	const onReportNotification = () => {
		onSetFilters(filtersForm.getFieldsValue(), true)
		filtersForm.resetFields()
		setUsers(null)
	}

	return (
		<Form form={filtersForm} name="filtersForm" onFinish={onReportNotification}>
			<Modal
				destroyOnClose
				onCancel={onModalClose}
				centered
				open={show}
				title="Создать отчёт"
				footer={
					<Row justify="space-between">
						<Col>
							<Button
								danger
								onClick={() => {
									filtersForm.resetFields()
									setUsers(null)
								}}
							>
								Сбросить
							</Button>
						</Col>
						<Col>
							<Button onClick={onModalClose}>Отмена</Button>,
							<Button type="primary" onClick={() => filtersForm.submit()}>
								Сформировать
							</Button>
						</Col>
					</Row>
				}
			>
				<Row justify="center">
					<Col
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '.5rem',
							padding: '1rem',
							width: '100%'
						}}
					>
						<>
							<Col>
								<Row align="middle" justify="space-between">
									<Col>
										<Title level={5}>Проекты:</Title>
									</Col>
									<Col>
										<Button type="link" size="small" onClick={setAllProjects}>
											Выбрать все
										</Button>
									</Col>
								</Row>
								<Form.Item
									name="projects"
									rules={[
										{
											required: true,
											message: 'Выберете хотя бы один проект'
										}
									]}
								>
									<Select
										mode="multiple"
										maxTagCount="responsive"
										allowClear
										style={{ width: '100%' }}
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										options={projects?.map(p => ({
											key: p.id,
											label: p.name,
											value: p.id
										}))}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Title level={5}>Получатель:</Title>
								<Tooltip
									zIndex={1000000}
									title={'Для получения списка получателей начните вводить имя или e-mail'}
								>
									<Form.Item name="userId">
										<Select
											loading={isLoading}
											disabled={users?.length === 0}
											allowClear
											showSearch
											onSearch={debounce}
											optionFilterProp="children"
											filterOption={(input, option) =>
												(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
											}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '')
													.toLowerCase()
													.localeCompare((optionB?.label ?? '').toLowerCase())
											}
											style={{ width: '100%' }}
											options={(users ? users : permissions!)?.map(p => ({
												key: p.id,
												label: p.lastName + ' ' + p.firstName + ' / ' + p.email,
												value: p.id
											}))}
										/>
									</Form.Item>
								</Tooltip>
							</Col>
							<Col>
								<Title level={5}>Генподрядчик:</Title>
								<Form.Item name="contractorId">
									<Select
										disabled={contractors?.length === 0}
										allowClear
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										style={{ width: '100%' }}
										options={contractors?.map(p => ({
											key: p.id,
											label: p.name,
											value: p.id
										}))}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Title level={5}>Документ:</Title>
								<Form.Item name="object">
									<Select
										disabled={contracts?.length === 0}
										allowClear
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										style={{ width: '100%' }}
										options={contracts?.map(p => ({
											key: p.id,
											label: p.name,
											value: p.id
										}))}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Title level={5}>Период:</Title>
								<Form.Item
									name="range"
									rules={[
										{
											required: !allPeriod,
											message: 'Укажите период'
										}
									]}
								>
									<DatePicker.RangePicker
										disabled={allPeriod}
										format="DD.MM.YYYY"
										style={{ width: '100%' }}
									/>
								</Form.Item>
							</Col>
							<Row justify="end">
								<Text style={{ marginRight: '1rem' }}>За всё время</Text>
								<Form.Item name="allPeriod">
									<Checkbox
										checked={allPeriod}
										onChange={value => filtersForm.setFieldValue('allPeriod', value.target.checked)}
									/>
								</Form.Item>
							</Row>
						</>
					</Col>
				</Row>
			</Modal>
		</Form>
	)
}

export default ReportNotificationsModal
