import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { Row } from '@tanstack/table-core'
import { Row as AntRow, Button, Col, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { memo, useEffect } from 'react'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { findNestedObj, moveRow, moveSomeRow } from 'shared/helpers'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { IAdjustmentPosition, IAdjustmentSection } from '../model/adjustment-schema'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	sectionId: string
	rowId: string
	isMultiply?: boolean
	row?: Row<IAdjustmentPosition> | Row<IAdjustmentSection>
	isAuthor?: boolean
}

export const AdjustmentPositionMove = memo(function (params: IProps) {
	const { sectionId, rowId, isMultiply = false, row, isAuthor } = params
	const { user } = useAppSelector(state => state.environment)
	const { selectedRows, sections, container, changePosition } = useAdjustmentState()
	const checkPermissions = useCheckPermissions()
	const positionEstimates: any = findNestedObj<IAdjustmentSection>(
		sections,
		'id',
		sectionId
	)?.children

	const rowMainIndex = positionEstimates!?.findIndex((elem: any) => elem.id === rowId)

	useEffect(() => {
		if (moveSomeRow(positionEstimates, selectedRows, true) === true) {
			useAdjustmentState.setState({ canDelete: false })
		} else useAdjustmentState.setState({ canDelete: true })
	}, [selectedRows])

	return (
		<AntRow align="middle" wrap={false}>
			<Col>
				<Tooltip title="Поднять">
					<Button
						size="small"
						type="text"
						disabled={
							Object.keys(selectedRows.rows).includes(row?.originalSubRows![0]?.id!) ||
							('order' in row?.original! && row?.original.order === 1) ||
							('isUnrelated' in row?.original! && row?.original.isUnrelated === false) ||
							(isMultiply == false
								? moveRow(positionEstimates, rowMainIndex, 'up', true)
								: moveSomeRow(positionEstimates, selectedRows, true, 'up')) ||
							(!(
								typeof container?.isLocalEstimate === 'undefined' &&
								// 'isUnrelated' in row?.original! &&
								// row?.original.isUnrelated === false &&
								(user?.isAdmin ||
									((container?.status.name === CONTAINER_STATUS.new ||
										container?.status.name === CONTAINER_STATUS.preparation) &&
										checkPermissions([PERMISSIONS.AdjustmentCreate]) &&
										isAuthor))
							) &&
								!container?.isLocalEstimate === false)
						}
						icon={<CaretUpOutlined />}
						onClick={e => {
							e.stopPropagation()
							changePosition(sectionId, isMultiply ? Object.keys(selectedRows.rows) : [rowId], 'up')
						}}
					/>
				</Tooltip>
			</Col>
			<Col>
				<Tooltip title="Опустить">
					<Button
						size="small"
						type="text"
						disabled={
							Object.keys(selectedRows.rows).includes(
								row?.originalSubRows![row?.originalSubRows?.length! - 1]?.id!
							) ||
							('order' in row?.original! &&
								row?.original.order === row?.getParentRow()?.original.children?.length) ||
							('isUnrelated' in row?.original! && row?.original.isUnrelated === false) ||
							(isMultiply == false
								? moveRow(positionEstimates, rowMainIndex, 'down', true)
								: moveSomeRow(positionEstimates, selectedRows, true, 'down')) ||
							(!(
								typeof container?.isLocalEstimate === 'undefined' &&
								// 'isUnrelated' in row?.original! &&
								// row?.original.isUnrelated === false &&
								(user?.isAdmin ||
									((container?.status.name === CONTAINER_STATUS.new ||
										container?.status.name === CONTAINER_STATUS.preparation) &&
										checkPermissions([PERMISSIONS.AdjustmentCreate]) &&
										isAuthor))
							) &&
								!container?.isLocalEstimate === false)
						}
						icon={<CaretDownOutlined />}
						onClick={e => {
							e.stopPropagation()
							changePosition(
								sectionId,
								isMultiply ? Object.keys(selectedRows.rows) : [rowId],
								'down'
							)
						}}
					/>
				</Tooltip>
			</Col>
		</AntRow>
	)
})
