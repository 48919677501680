import { Button } from 'antd'
import { FC, useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { MobileReportDownloadModal } from './MobileReportDownloadModal'

interface IProps {}

export const MobileReportDownload: FC<IProps> = () => {
  const [show, setShow] = useState(false)

  return (
    <>
      <MobileReportDownloadModal show={show} onClose={() => setShow(false)} />
      <Button
        type="default"
        icon={<DownloadOutlined />}
        onClick={() => setShow(true)}
      >
        Скачать отчёт
      </Button>
    </>
  )
}
