import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { useAppSelector } from 'hooks/appReduxHook'
import { IContainer } from 'interfaces/IContainer'
import { MutableRefObject } from 'react'
import { useLocation } from 'react-router-dom'
import { exportService } from 'services/exportService'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useAdjustmentState } from 'widgets/adjustment/model/adjustment-state'
import { useEstimateState } from 'widgets/estimate'
import { ILocalEstimate } from 'widgets/localEstimate'
import { useWbsPermits } from 'widgets/wbs'

interface IProps {
	record: IContainer | ILocalEstimate
	uploadRef: MutableRefObject<any>
}

export const WbsAndLocalActionsImportExport = (params: IProps) => {
	const checkPermissions = useCheckPermissions()
	const { pathname } = useLocation()
	const { record, uploadRef } = params
	const { user } = useAppSelector(state => state.environment)
	useEstimateState()
	const wbsPermissions = useWbsPermits()
	const documentImportExportWbs = [
		{
			key: 'exportExcelWithPrice',
			label: 'Выгрузка с ценами',
			icon: <DownloadOutlined />,
			disabled:
				!(wbsPermissions.canExport && !record.adjustmentId) &&
				!(checkPermissions([PERMISSIONS.AdjustmentExport]) && record.adjustmentId),
			onClick: () => {
				const dto = {
					id: record.adjustmentId ?? record.id,
					name: record.name,
					createdAt: record.createdDate
				}
				exportService.exportWbsToExcel(dto, 'wbs', record.adjustmentId ? true : false, true)
			}
		},
		{
			key: 'exportExcel',
			label: 'Выгрузка без цен',
			icon: <DownloadOutlined />,
			disabled:
				!(wbsPermissions.canExport && !record.adjustmentId) &&
				!(checkPermissions([PERMISSIONS.AdjustmentExport]) && record.adjustmentId),
			onClick: () => {
				const dto = {
					id: record.adjustmentId ?? record.id,
					name: record.name,
					createdAt: record.createdDate
				}
				exportService.exportWbsToExcel(dto, 'wbs', record.adjustmentId ? true : false)
			}
		},
		{
			key: 'importWBS',
			label: record.adjustmentId ? 'Загрузить цены в версию Подрядчика' : 'Загрузить',
			icon: <UploadOutlined />,
			disabled:
				record.ready ||
				(!wbsPermissions.canImport &&
					!(
						record.adjustmentId !== null &&
						((checkPermissions([PERMISSIONS.AdjustmentImportContractorVersion]) &&
							record?.status?.name === CONTAINER_STATUS.priceApprovalOuter) ||
							user?.isAdmin)
					)),
			onClick: () => {
				uploadRef.current!.click()
				useAdjustmentState.setState({ isImportSdu: false })
			}
		}
	]

	const documentImportExportLocal: any[] = [
		{
			key: 'exportExcelWithPrice',
			label: 'Выгрузка с ценами',
			icon: <DownloadOutlined />,
			disabled:
				!(
					checkPermissions([PERMISSIONS.ExportEstimateCorrectionToExcel]) && !record.adjustmentId
				) && !(checkPermissions([PERMISSIONS.AdjustmentExport]) && record.adjustmentId),
			onClick: () => {
				const dto = {
					id: record.adjustmentId ?? record.id,
					name: record.name,
					createdAt: record.createdDate
				}
				exportService.exportWbsToExcel(dto, 'local', record.adjustmentId ? true : false, true)
			}
		},
		{
			key: 'exportExcel',
			label: 'Выгрузка без цен',
			icon: <DownloadOutlined />,
			disabled:
				!(
					checkPermissions([PERMISSIONS.ExportEstimateCorrectionToExcel]) && !record.adjustmentId
				) && !(checkPermissions([PERMISSIONS.AdjustmentExport]) && record.adjustmentId),
			onClick: () => {
				const dto = {
					id: record.adjustmentId ?? record.id,
					name: record.name,
					createdAt: record.createdDate
				}
				exportService.exportWbsToExcel(dto, 'local', record.adjustmentId ? true : false)
			}
		},
		{
			key: 'importWBS',
			label: record.adjustmentId ? 'Загрузить цены в версию Подрядчика' : 'Загрузить',
			icon: <UploadOutlined />,
			disabled:
				record.ready ||
				(!(
					checkPermissions([PERMISSIONS.ImportEstimateCorrectionFromExcel]) &&
					record.adjustmentId === null
				) &&
					!(
						record.adjustmentId !== null &&
						((checkPermissions([PERMISSIONS.AdjustmentImportContractorVersion]) &&
							record?.status?.name === CONTAINER_STATUS.priceApprovalOuter) ||
							user?.isAdmin)
					)),
			onClick: () => {
				uploadRef.current!.click()
				useAdjustmentState.setState({ isImportSdu: false })
			}
		}
	]

	const documentImportExportAdjustment: any[] = [
		{
			key: 'exportExcelWithPrice',
			label: 'Выгрузка с ценами',
			icon: <DownloadOutlined />,
			disabled:
				!(
					checkPermissions([PERMISSIONS.ExportEstimateCorrectionToExcel]) && !record.adjustmentId
				) && !(checkPermissions([PERMISSIONS.AdjustmentExport]) && record.adjustmentId),
			onClick: () => {
				const dto = {
					id: record.adjustmentId ?? record.id,
					name: record.name,
					createdAt: record.createdDate
				}
				exportService.exportWbsToExcel(dto, 'local', record.adjustmentId ? true : false, true)
			}
		},
		{
			key: 'exportExcel',
			label: 'Выгрузка без цен',
			icon: <DownloadOutlined />,
			disabled:
				!(
					checkPermissions([PERMISSIONS.ExportEstimateCorrectionToExcel]) && !record.adjustmentId
				) && !(checkPermissions([PERMISSIONS.AdjustmentExport]) && record.adjustmentId),
			onClick: () => {
				const dto = {
					id: record.adjustmentId ?? record.id,
					name: record.name,
					createdAt: record.createdDate
				}
				exportService.exportWbsToExcel(dto, 'local', record.adjustmentId ? true : false)
			}
		},
		{
			key: 'importWBS',
			label: record.adjustmentId ? 'Загрузить цены в версию Подрядчика' : 'Загрузить',
			icon: <UploadOutlined />,
			disabled:
				record.ready ||
				(!(
					checkPermissions([PERMISSIONS.ImportEstimateCorrectionFromExcel]) &&
					record.adjustmentId === null
				) &&
					!(
						record.adjustmentId !== null &&
						((checkPermissions([PERMISSIONS.AdjustmentImportContractorVersion]) &&
							record?.status?.name === CONTAINER_STATUS.priceApprovalOuter) ||
							user?.isAdmin)
					)),
			onClick: () => {
				uploadRef.current!.click()
				useAdjustmentState.setState({ isImportSdu: false })
			}
		},
		{
			key: 'importSdu',
			label: 'Загрузить цены в версию СДУ',
			icon: <UploadOutlined />,
			disabled:
				record?.ready ||
				!(
					((checkPermissions([PERMISSIONS.AdjustmentImportSduVersionPriceService]) ||
						checkPermissions([PERMISSIONS.AdjustmentImportSduVersionNominated])) &&
						record?.status?.name === CONTAINER_STATUS.priceApproval) ||
					user?.isAdmin
				),
			onClick: () => {
				uploadRef.current!.click()
				useAdjustmentState.setState({ isImportSdu: true })
			}
		}
	]

	return record?.adjustmentId !== null &&
		(pathname.includes('unmodel') || pathname.includes('local'))
		? documentImportExportAdjustment
		: pathname.includes('unmodel')
		? documentImportExportWbs
		: documentImportExportLocal
}
