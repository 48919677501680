import { API } from 'services/ApiService'

export interface IWbsContainerComments {
	id: string
	order: number
	name: string
	isCommentsClosed: boolean
	mainCommentAuthor: {
		id: string
		name: string
		isDeleted: boolean
	} | null
	section: { id: string; codifier: string }
}

export const getWbsContainerComments = async (containerId: string, isCommentsClosed?: boolean, myComments?: boolean) => {
	return await API<IWbsContainerComments[]>({
		url: `/v1/containers/${containerId}/estimatePositionsComments`,
		method: 'get',
		params: { isCommentsClosed, myComments }
	}).then(async response => response.data)
}

export const getWbsAuthorCommentStatus = async (id: string) => {
	return await API<boolean>({
		url: `/v1/estimatePositions/${id}/comments/authorCommentsStatus`,
		method: 'get'
	}).then(async response => response.data)
}