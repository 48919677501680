import { SettingOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Dropdown, MenuProps, Row, Typography } from 'antd'
import { memo, useState } from 'react'
import { TEstimateType } from 'shared/schema'
import { useShallow } from 'zustand/react/shallow'
import { useEstimateState } from '..'
import { useEstimateColumnConfig } from '../model/estimate-column-config-state'
const { Text } = Typography

const colNames = (item: string) => {
	switch (item) {
		case 'price':
			return 'Цена за ед.изм., руб. с НДС'
		case 'totalPrice':
			return 'Всего за единицу, руб. с НДС'
		case 'sum':
			return 'Стоимость за единицу, руб. с НДС'
		case 'totalSum':
			return 'Всего стоимость, руб. с НДС'
		case 'noteSDU':
			return 'Примечание СДУ'
		case 'noteDZ':
			return 'Примечание ДЗ'
		case 'noteDP':
			return 'Примечание ДП'
		case 'isNominated':
			return 'НМТР'
		case 'confirmedVolume':
			return 'Выполнено'
	}
}
export const EstimateColumnsConfig = memo(function ({ type }: { type: TEstimateType }) {
	const [visible, setVisible] = useState(false)
	const { columns, setVisibleColumns } = useEstimateColumnConfig(
		useShallow(state => ({
			columns: state[type],
			setVisibleColumns: state.setVisibleColumns
		}))
	)
	const { container } = useEstimateState(
		useShallow(state => ({
			container: state.container
		}))
	)
	const filteredColumns = Object.entries(columns).filter(([key]) => {
		if (
			(type === 'local' && (key === 'noteSDU' || key === 'noteDZ' || key === 'noteDP')) ||
			(container?.isLocalEstimate === true && key === 'confirmedVolume')
		) {
			return false
		}
		return true
	})

	const items: MenuProps['items'] = filteredColumns.map((item, index) => ({
		key: index,
		label: (
			<Row
				onClick={() => setVisibleColumns(item[0], !item[1], type)}
				style={{ cursor: 'pointer', flexWrap: 'inherit' }}
			>
				<Col span={3}>
					<Checkbox checked={item[1]} />
				</Col>
				<Col flex={1}>
					<Text>{colNames(item[0])}</Text>
				</Col>
			</Row>
		)
	}))
	return (
		<Dropdown
			menu={{ items }}
			trigger={['click']}
			open={visible}
			overlayStyle={{ width: 300 }}
			onOpenChange={() => setVisible(!visible)}
		>
			<Button
				type={Object.values(columns).some(c => c === false) ? 'primary' : 'default'}
				icon={<SettingOutlined />}
			/>
		</Dropdown>
	)
})
