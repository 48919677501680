import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Tooltip, Typography, message } from 'antd'
import axios, { AxiosError } from 'axios'
import { IUserDto } from 'interfaces/IUser'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASEURL } from 'services/ApiService'

const { Title } = Typography
interface IRegistryErrorData {
	type: string
	title: string
	status: number
	traceId: string
	errors: {
		Email: string[]
	}
}

const RegistrationForm: FC = () => {
	const navigate = useNavigate()
	const [registrationForm] = Form.useForm()
	const [canSave, setCanSave] = useState(true)
	const validateForm = () => {
		const errors = registrationForm
			.getFieldsError()
			.map(err => err.errors.length)
			.reduce((partialSum, a) => partialSum + a, 0)
		setCanSave(errors > 0)
	}

	const onRegistrationFormFinish = async () => {
		const userDto: IUserDto = {
			...registrationForm.getFieldsValue(),
			email: registrationForm.getFieldValue('email').toLowerCase()
		}

		await axios({
			baseURL: BASEURL,
			headers: {
				Accept: 'application/json'
			},
			url: `/v1/users`,
			method: 'post',
			data: userDto
		})
			.then(response => {
				if (response.status === 200) {
					message.success('На указанную почту отравлено письмо для подтверждения', 5)
					navigate('/')
				} else {
					message.error('Произошла ошибка, попробуйте позже', 5)
				}
			})
			.catch((error: AxiosError) => {
				const errorMessage = error.response?.data as IRegistryErrorData
				message.error(errorMessage.title, 5)
			})
	}

	const onRegistrationFormFailed = () => {}

	return (
		<>
			<Title level={4} style={{ width: '100%', textAlign: 'center' }}>
				Регистрация
			</Title>

			<Form
				layout="vertical"
				name="registrationForm"
				form={registrationForm}
				onFinish={onRegistrationFormFinish}
				onFinishFailed={onRegistrationFormFailed}
				onFieldsChange={validateForm}
				autoComplete="off"
			>
				<Form.Item
					label="E-mail"
					name="email"
					rules={[
						{ required: true, message: 'Поле не должно быть пустым' },
						{ type: 'email', message: 'Не корректный e-mail' },
						{
							pattern: new RegExp(/^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\\"\\/?>.<,-\d]+$/i),
							message: 'Допускаются только английские буквы'
						}
					]}
				>
					<Input className="at-input" placeholder="E-mail" />
				</Form.Item>
				<Form.Item
					label="Фамилия"
					name="lastName"
					rules={[{ required: true, message: 'Поле не должно быть пустым' }]}
				>
					<Input className="at-input" placeholder="Фамилия" />
				</Form.Item>
				<Form.Item
					label="Имя"
					name="firstName"
					rules={[{ required: true, message: 'Поле не должно быть пустым' }]}
				>
					<Input className="at-input" placeholder="Имя" />
				</Form.Item>
				<Form.Item
					label="Кодовое слово"
					name="secretWord"
					rules={[
						{ required: true, message: 'Поле не должно быть пустым' },
						{ min: 8, message: 'Минимальная длина - 8 символов' }
					]}
				>
					<Input
						className="at-input"
						placeholder="Кодовое слово"
						suffix={
							<Tooltip title="Необходимо для восстановления пароля">
								<QuestionCircleOutlined />
							</Tooltip>
						}
					/>
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						className="at-btn at-btn--fill"
						disabled={canSave}
					>
						Отправить
					</Button>
				</Form.Item>
				<Button className="at-btn at-btn--fill" type="link" onClick={() => navigate('/')}>
					На страницу авторизации
				</Button>
			</Form>
		</>
	)
}

export default RegistrationForm
