import { useQueryClient } from '@tanstack/react-query';
import { Form, Input, Modal, Select, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { IWorkPackage } from 'services/NormativeActsService';
import { IViolation } from 'services/violationsService';
import { normativeActsService } from 'services/NormativeActsService';
import { useWorkPackages } from 'hooks/useWorkPackage';
import { useViolations } from 'hooks/useViolations';


interface IProps {
  show: boolean;
  onCloseModal: () => void;
}

export const AddNormativeActModal: FC<IProps> = ({ show, onCloseModal }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [workPackages, setWorkPackages] = useState<IWorkPackage[]>([]);
  const [violations, setViolations] = useState<IViolation[]>([]);
  const { data: workPackagesData } = useWorkPackages();
  const { data: violationsData } = useViolations();


  useEffect(() => {
    if (show) {
      if (workPackagesData) {
        setWorkPackages(workPackagesData);
      }
      if (violationsData) {
        setViolations(violationsData);
      }
    }
  }, [show, workPackagesData, violationsData]);


  const onAddNormativeAct = async () => {
    try {
      const formData = form.getFieldsValue();
      await normativeActsService.addNormativeAct({
        id: formData.id,
        name: formData.name,
        workPackageIds: formData.workPackageIds,
        violationIds: formData.violationIds,
      });
      queryClient.invalidateQueries(['normativeActs']);
      message.success('Нормативный акт создан');
      onClose();
    } catch (error) {
      message.error('Ошибка при создании нормативного акта');
    }
  };


  const onClose = () => {
    form.resetFields();
    onCloseModal();
  };

  return (
    <Modal
      centered
      open={show}
      onCancel={onClose}
      title="Добавить нормативный акт"
      onOk={() => form.submit()}
      okText="Создать"
    >
      <Form form={form} name="addNormativeActForm" onFinish={onAddNormativeAct} layout="vertical">
        <Form.Item
          name="name"
          label="Название нормативного акта"
          rules={[{ required: true, message: 'Введите название нормативного акта' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="workPackageIds"
          label="Выберите пакеты СМР"
          rules={[{ required: true, message: 'Выберите пакеты СМР' }]}
        >
          <Select
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[...workPackages!]
              ?.sort((a, b) => a.code! - b.code!)
              .map((pkg) => ({
                key: pkg.id,
                value: pkg.id,
                label: `${pkg.code} ${pkg.name}`,
              }))}
          />
        </Form.Item>

        <Form.Item
          name="violationIds"
          label="Выберите нарушения"
          rules={[{ required: true, message: 'Выберите нарушения' }]}
        >
          <Select
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(a, b) =>
              (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
            }
            options={violations.map((violation) => ({
              key: violation.id,
              value: violation.id,
              label: violation.name,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
