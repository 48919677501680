import { useQueryClient } from '@tanstack/react-query'
import History from 'UI/history'
import AttentionModal from 'UI/modals/AttentionModal'
import { Button, Col, Drawer, Form, Row, Select, Tabs, message } from 'antd'
import Title from 'antd/lib/typography/Title'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { IChecklistUpdateData, ICurrentChecklist } from 'interfaces/IChecklist'
import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	createChecklist,
	getChecklist,
	setChecklistCancel,
	updateChecklist
} from 'services/ChecklistsService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import {
	resetCurrentChecklist,
	setCurrentChecklist,
	setCurrentChecklistId
} from 'store/checklistsSlice'
import WarrantyModal from '../modals/WarrantyModal'
import CheckListAttachments from './CheckListAttachments'
import ChecklistForm from './ChecklistForm'
const { Option } = Select

interface ICreateChecklistProps {
	open: boolean
	onClose: () => void
	// checkPermissions: (currentPermits: string[]) => boolean
	checklistItemId?: string
}

const ChecklistsDrawer: FC<ICreateChecklistProps> = ({
	open,
	onClose,
	// checkPermissions,
	checklistItemId
}) => {
	const checkPermissions = useCheckPermissions()
	const [showDrawer, setShowDrawer] = useState(open)
	const { id: checklistId } = useParams()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const [checklistForm] = Form.useForm()
	const [attention, setAttention] = useState(false)
	const [activeKey, setActiveKey] = useState('details')
	const { currentChecklist, initialStatuses, currentChecklistId } = useAppSelector(
		state => state.checklists.init
	)
	const templateType = currentChecklist?.templateType
	const [warrantyModalVisible, setWarrantyModalVisible] = useState(false)
	const { user: currentUser, permissions } = useAppSelector(state => state.environment)
	const isCurrentUserOwner = currentUser?.id! === currentChecklist?.owner?.id!
	const isCurrentUserAssigned = currentChecklist?.assignedTo!?.some(
		user => user!?.id! === currentUser?.id!
	)

	const Cancel_UKS_NDC =
		templateType === 0 && checkPermissions([PERMISSIONS.ChecklistsCancelUksNdc])
	const Cancel_UK_KC = templateType === 1 && checkPermissions([PERMISSIONS.ChecklistsCancelUkKc])
	const Create_UKS_NDC =
		templateType === 0 && checkPermissions([PERMISSIONS.ChecklistsCreateUksNdc])
	const Create_UK_KC = templateType === 1 && checkPermissions([PERMISSIONS.ChecklistsCreateUkKc])
	const Process_UKS_NDC =
		templateType === 0 && checkPermissions([PERMISSIONS.ChecklistsProcessingUksNdc])
	const Process_UK_KC =
		templateType === 1 && checkPermissions([PERMISSIONS.ChecklistsProcessingUkKc])
	const Under_Warranty =
		templateType === 1 && checkPermissions([PERMISSIONS.ChecklistsAcceptedUnderWarranty])

	const isCreated = currentChecklist?.id === '00000000-0000-0000-0000-000000000000'

	useEffect(() => {
		if (checklistId) {
			dispatch(setCurrentChecklistId(checklistId))
		}
		if (checklistId || open) {
			setShowDrawer(true)
		} else {
			setShowDrawer(false)
		}
	}, [checklistId, open])

	useEffect(() => {
		if (!showDrawer) {
			setActiveKey('details')
			checklistForm.resetFields()
			dispatch(resetCurrentChecklist())
		}
	}, [showDrawer])

	const handleClose = () => {
		setAttention(false)
		checklistForm.resetFields()
		dispatch(resetCurrentChecklist())
		onClose()
		checklistId && navigate('/checklist')
	}

	const onReloadChecklist = async (refresh: boolean = true) => {
		if (currentChecklistId) {
			await getChecklist(currentChecklistId).then(res => {
				dispatch(
					setCurrentChecklist({
						...res,
						plannedDate: dayjs(res!?.plannedDate),
						factDate: dayjs(res!?.factDate)
					})
				)
				checklistForm.setFieldsValue({
					...res,
					contractorId: res.contractor?.id,
					subContractorId: res.subContractor?.id,
					plannedDate: res!?.plannedDate && dayjs(res!?.plannedDate),
					factDate: res!?.factDate && dayjs(res!?.factDate)
				})
			})
			queryClient.invalidateQueries(['checkListList'])
		}
	}
	const onUpdateChecklist = async (updateData: any, keepOpen?: boolean) => {
		const formData = checklistForm.getFieldsValue()
		const newData: IChecklistUpdateData = {
			...currentChecklist!,
			...updateData,
			assignedTo: updateData?.assignedTo
				? updateData.assignedTo
				: currentChecklist?.assignedTo!?.flatMap(user => user.id),
			doorSections: updateData?.doorSections
				? updateData?.doorSections
				: currentChecklist?.doorSections!
				? currentChecklist?.doorSections!
				: [],
			plannedDate: formData['plannedDate'] ?? currentChecklist?.plannedDate,
			// dayjs(updateData.plannedDate).isValid()
			// 	? dayjs(updateData.plannedDate).format('YYYY-MM-DDTHH:mm:00+03:00')
			// 	: null,
			factDate: dayjs(updateData.factDate).isValid()
				? dayjs(updateData.factDate).format('YYYY-MM-DD')
				: null,
			contractorId: formData['contractorId'] ?? currentChecklist?.contractor?.id,
			subContractorId: formData['subContractorId'] ?? currentChecklist?.subContractor?.id,
			checkListCipher: formData['checkListCipher'] ?? currentChecklist?.checkListCipher,
			workPackageId: formData['workPackageId'] ?? currentChecklist?.workPackageId,
			capacity: formData['capacity'] ?? currentChecklist?.capacity
		}
		const formAssignedTo: any[] = (checklistForm.getFieldValue('assignedTo') as any[]).every(
			item => typeof item === 'object'
		)
			? checklistForm.getFieldValue('assignedTo').map((item: any) => item.id)
			: checklistForm.getFieldValue('assignedTo')
		const formDoorSections = checklistForm.getFieldValue('doorSections')
			? checklistForm.getFieldValue('doorSections')
			: currentChecklist?.doorSections!
			? currentChecklist?.doorSections!
			: []
		const validate = checklistForm
			.validateFields()
			.then(() => {
				newData.assignedTo = formAssignedTo
				newData.doorSections = formDoorSections
				return true
			})
			.catch(() => {
				return false
			})

		const createData = {
			checkListTemplateId: currentChecklist?.checkListTemplate.id,
			title: newData.title,
			contractorId: formData['contractorId'] ?? currentChecklist?.contractor?.id,
			subContractorId: formData['subContractorId'] ?? currentChecklist?.subContractor?.id,
			description: newData.description,
			location: newData.location,
			plannedDate: dayjs(formData['plannedDate']).isValid()
				? dayjs(formData['plannedDate']).format('YYYY-MM-DDTHH:mm:00+03:00')
				: null,
			potentialAssignedUsersId: newData.assignedTo,
			doorSections: newData.doorSections,
			checkListCipher: newData.checkListCipher,
			workPackageId: newData.workPackageId,
			capacity: newData.capacity
		}

		if (await validate) {
			setIsLoading(true)
			isCreated
				? await createChecklist(createData)
						.then(() => {
							message.success('Чек-лист успешно создан')
							handleClose()
							queryClient.invalidateQueries(['checkListList'])
							queryClient.invalidateQueries(['checklists'])
						})
						.catch((err: AxiosError<IErrorDetail>) => message.error(err.response?.data.detail))
						.finally(() => setIsLoading(false))
				: await updateChecklist(
						newData,
						currentChecklist?.id ? currentChecklist.id : currentChecklistId
				  )
						.then(() => {
							message.success('Чек-лист успешно обновлен')
							queryClient.invalidateQueries(['checkListList'])
							queryClient.invalidateQueries(['checklists'])
							if (!keepOpen) {
								handleClose()
							} else {
								onReloadChecklist()
							}
						})
						.catch((err: AxiosError<IErrorDetail>) => message.error(err.response?.data.detail))
						.finally(() => setIsLoading(false))
		}
	}

	const onChangeStatus = async (status: string) => {
		if (currentUser?.isAdmin) {
			return onUpdateChecklist({ status: initialStatuses.indexOf(status) }, true)
		}
		if (initialStatuses.indexOf(status) === 4) {
			if (!Under_Warranty) {
				return message.error('У вас нет прав на установку этого статуса!')
			} else {
				return onUpdateChecklist({ status: initialStatuses.indexOf(status) }, true)
			}
		}
		if (initialStatuses.indexOf(status) === 3) {
			if (
				// Право аннулировать из любого статуса
				Cancel_UKS_NDC ||
				Cancel_UK_KC ||
				// Право аннулировать из статуса "новый"
				(currentChecklist?.status === 0 &&
					(Create_UKS_NDC || Create_UK_KC || Process_UKS_NDC || Process_UK_KC)) ||
				// Право аннулировать из статуса "в работе"
				(currentChecklist?.status === 1 && (Process_UKS_NDC || Process_UK_KC))
			) {
				await setChecklistCancel(currentChecklist!.id).then(() => onReloadChecklist())
			} else message.error('У вас нет прав на установку этого статуса!')
		}
	}
	const acceptedUnderWarrantyHandler = () => {
		setWarrantyModalVisible(true)
	}
	useEffect(() => {
		if (!isCreated && currentChecklistId) {
			getChecklist(currentChecklistId).then(res => {
				const normalizedChecklist: ICurrentChecklist = {
					...res,
					// period: dayjs(res.period),
					plannedDate: res.plannedDate ? dayjs(res.plannedDate) : null,
					factDate: res.factDate ? dayjs(res.factDate) : null
				}
				dispatch(setCurrentChecklist(normalizedChecklist))
				checklistForm.setFieldsValue({
					...normalizedChecklist,
					contractorId: res.contractor?.id,
					subContractorId: res.subContractor?.id
				})
			})
		}
	}, [currentChecklistId])

	useEffect(() => {
		!open && onReloadChecklist(false)
	}, [open, currentChecklist?.status])

	// Статусы:
	//   0 = 'Новый',
	//   1 = 'В работе',
	//   2 = 'Завершен',
	//   3 = 'Аннулирован',
	//   4 = 'Принято под гарантийные обязательства'

	const checkDisabledStatuses = (status?: number) => {
		// Create - новый - аннулирован
		// Cancel - из всех - аннулирован
		// Processing - в работе - аннулирован
		let disabled = true
		if (currentUser?.isAdmin) {
			disabled = false
		} else {
			if (status === 3) {
				if (
					Cancel_UKS_NDC ||
					Cancel_UK_KC ||
					Process_UKS_NDC ||
					Process_UK_KC ||
					Create_UKS_NDC ||
					Create_UK_KC
				) {
					if (isCurrentUserOwner || isCurrentUserAssigned) disabled = false
				}
			}
			if (status === 4) {
				if (Under_Warranty && (currentChecklist?.status === 0 || currentChecklist?.status === 1)) {
					if (!isCurrentUserOwner && isCurrentUserAssigned) disabled = false
				}
			}
		}
		return disabled
	}

	const onCheckUnsavedData = () => {
		const format = 'YYYY-MM-DDT00:00:00+00:00'
		const formatPlannedDate = 'YYYY-MM-DDT00:00:00+00:00'
		const initState = {
			...currentChecklist,
			period: null,
			assignedTo: currentChecklist?.assignedTo.map(x => x.id),
			plannedDate:
				currentChecklist?.plannedDate && dayjs(currentChecklist?.plannedDate!).isValid()
					? dayjs(currentChecklist?.plannedDate).format(formatPlannedDate)
					: null,
			factDate:
				currentChecklist?.factDate && dayjs(currentChecklist?.factDate).isValid()
					? dayjs(currentChecklist?.factDate).format(format)
					: null
		}
		const curState: ICurrentChecklist = {
			...currentChecklist,
			...checklistForm.getFieldsValue(),
			period: null,
			plannedDate: checklistForm.getFieldValue('plannedDate')
				? dayjs(checklistForm.getFieldValue('plannedDate')).format(formatPlannedDate)
				: null,
			factDate: checklistForm.getFieldValue('factDate')
				? dayjs(checklistForm.getFieldValue('factDate')).format(format)
				: null
		}

		_.isEqual(curState!, initState!) ? handleClose() : setAttention(true)
	}

	if (checklistItemId!) {
		const element = document.querySelector(`[data-attr="${checklistItemId}"]`)
		element?.scrollIntoView()
	}

	const normalizeForm = (values: any) => {
		checklistForm.setFieldValue(
			'doorSections',
			values?.doorSections?.sort((a: number, b: number) => a - b)
		)
	}

	return (
		<>
			<AttentionModal
				title={'Внимание!'}
				description={'Не сохранённые данные будут утеряны'}
				opened={attention}
				onOk={handleClose}
				onCancel={() => setAttention(false)}
			/>
			<Drawer
				destroyOnClose={true}
				open={showDrawer}
				onClose={onCheckUnsavedData}
				styles={{ body: { background: '#fafafa', paddingTop: 0 } }}
				width={window.outerWidth < 1300 ? '75vw' : '50vw'}
				title={
					isCreated
						? `Новый чек-лист №${currentChecklist?.number ?? ''}`
						: `Чеклист №${currentChecklist?.number || ''} от ${
								dayjs(currentChecklist?.createdAt).format('DD.MM.YY') || ''
						  }`
				}
				extra={
					<Row
						style={{
							width: '370px',
							height: '65px',
							boxSizing: 'border-box',
							display: 'flex',
							justifyContent: 'end',
							alignItems: 'end'
						}}
						gutter={8}
					>
						<Col style={{ display: 'flex', flexDirection: 'column' }}>
							<WarrantyModal
								warrantyModalVisible={warrantyModalVisible}
								setWarrantyModalVisible={setWarrantyModalVisible}
							/>
							<Title level={5}>Статус:</Title>
							<Select
								disabled={isCreated}
								onChange={el => {
									if (
										initialStatuses.indexOf(el) === 4 &&
										checkPermissions([PERMISSIONS.ChecklistsAcceptedUnderWarranty])
									) {
										acceptedUnderWarrantyHandler()
									} else {
										onChangeStatus(el)
									}
								}}
								value={currentChecklist && initialStatuses[currentChecklist.status]}
								style={{ width: 180 }}
							>
								{initialStatuses.map((item, index) => (
									<Option
										key={index}
										value={item}
										disabled={
											(currentChecklist?.templateType === 0 && index === 4) ||
											(currentChecklist && checkDisabledStatuses(index))
										}
									>
										{item}
									</Option>
								))}
							</Select>
						</Col>
						<Col>
							<Button
								loading={isLoading}
								disabled={
									!(
										currentUser?.isAdmin ||
										isCurrentUserOwner ||
										checkPermissions([
											PERMISSIONS.ChecklistsEditUksNdc,
											PERMISSIONS.ChecklistsEditUkKc,
											PERMISSIONS.ChecklistsEditAssignedToUkNdk,
											PERMISSIONS.ChecklistsEditAssignedToUkKc,
											PERMISSIONS.ChecklistPlanedDate
										])
									)
								}
								onClick={() => onUpdateChecklist({ ...checklistForm.getFieldsValue() }, false)}
							>
								Сохранить
							</Button>
						</Col>
					</Row>
				}
			>
				<Form
					name="createChecklistForm"
					form={checklistForm}
					onValuesChange={(_, v) => normalizeForm(v)}
					onFinish={value => {
						onUpdateChecklist(value)
					}}
				>
					<Tabs
						style={{ width: '100%', minHeight: '100%' }}
						activeKey={activeKey}
						size="large"
						tabBarGutter={16}
						onChange={activeKey => {
							if (activeKey === 'history') queryClient.invalidateQueries(['checklistHistory'])
							setActiveKey(activeKey)
						}}
						items={[
							{
								label: 'Детали',
								key: 'details',
								children: (
									<ChecklistForm
										// currentTemplate={currentTemplate}
										onReloadChecklist={onReloadChecklist}
										onUpdateChecklist={onUpdateChecklist}
										form={checklistForm}
										// checkPermissions={checkPermissions}
									/>
								)
							},
							{
								label: 'Файлы',
								key: 'attachments',
								disabled: isCreated,
								children: <CheckListAttachments attachments={currentChecklist!?.attachments} />
							},
							{
								label: 'История',
								key: 'history',
								disabled: isCreated,
								children: <History id={currentChecklist!?.id} target={'checklist'} /> //<ChecklistHistory checklistId={currentChecklist!?.id} />
							}
						]}
					/>
				</Form>
			</Drawer>
		</>
	)
}

export default ChecklistsDrawer
