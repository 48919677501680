import { message, Modal, Upload, UploadProps } from 'antd'
import { RcFile } from 'antd/es/upload'
import { useAppSelector } from 'hooks/appReduxHook'
import { IContainer } from 'interfaces/IContainer'
import { IWbsContainer } from 'pages/unmodelPage/wbsContainer'
import { FC, MutableRefObject, useState } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useAdjustmentState } from 'widgets/adjustment/model/adjustment-state'
import { useWbsPermits } from 'widgets/wbs'
import { EstimateImportDataApi, EstimateImportModal } from '..'

interface IProps {
	containerId?: string
	disabled?: boolean
	registry?: 'wbsRegistry' | 'localEstimateRegistry'
	trigger: MutableRefObject<any>
	container?: IContainer | IWbsContainer
	isAdjustment?: boolean
}

export const EstimateImportData: FC<IProps> = ({
	containerId,
	disabled,
	registry,
	trigger,
	container,
	isAdjustment
}) => {
	const wbsPermissions = useWbsPermits()
	const [showModal, setShowModal] = useState(false)
	const [info, setInfo] = useState([])
	const { user: currentUser } = useAppSelector(state => state.environment)
	const isRedactor = container?.redactors.some(r => r.id === currentUser?.id)
	const checkPermissions = useCheckPermissions()
	const { isImportSdu } = useAdjustmentState()
	const props: UploadProps = {
		beforeUpload: file => {
			const isXlsx = file.name.slice(-4, file.name.length) === 'xlsx'
			if (!isXlsx) {
				message.error(`${file.name} не является файлом Excel`)
				useAdjustmentState.setState({ isImportSdu: false })
			} else {
				if (containerId && isImportSdu) {
					const formData = new FormData()
					formData.append('File', file as RcFile)
					EstimateImportDataApi.getPrepDataSdu(
						formData,
						containerId,
						checkPermissions([PERMISSIONS.AdjustmentImportSduVersionNominated]) &&
							!currentUser?.isAdmin
							? 'nominated'
							: 'priceService'
					)
						.then(res => {
							setShowModal(true)
							setInfo(res)
						})
						.catch(err => {
							Modal.error({
								title: 'Ошибка загрузки',
								content:
									err.response?.data.detail ?? 'Произошла ошибка. Обратитесь к администратору'
							})
							useAdjustmentState.setState({ isImportSdu: false })
						})
				} else if (containerId) {
					const formData = new FormData()
					formData.append('File', file as RcFile)
					EstimateImportDataApi.getPrepData(
						formData,
						containerId,
						registry === 'wbsRegistry' ? 'wbs' : 'local',
						isAdjustment
					)
						.then(res => {
							setShowModal(true)
							setInfo(res)
						})
						.catch(err => {
							Modal.error({
								title: 'Ошибка загрузки',
								content:
									err.response?.data.detail ?? 'Произошла ошибка. Обратитесь к администратору'
							})
						})
				} else {
					return false
				}
			}
			return false
		},
		showUploadList: false
	}
	const infoError = () => {
		Modal.info({
			title: `При загрузке файла разницы в данных не обнаружено, данные не будут обновлены в ${
				registry === 'wbsRegistry' ? 'ВОР' : 'Корректировке локальной сметы'
			}.`,
			width: window.innerWidth - (window.innerWidth * 70) / 100,
			onOk() {
				setShowModal(false)
				useAdjustmentState.setState({ isImportSdu: false })
			}
		})
	}
	return (
		<>
			<Upload
				data-attr="buttonUploadExcel"
				disabled={
					disabled ||
					(!wbsPermissions.canImport &&
						(!wbsPermissions.canLocalImport ||
							(wbsPermissions.canLocalImport && wbsPermissions.canLocalExternal && !isRedactor)) &&
						!(
							(checkPermissions([PERMISSIONS.AdjustmentImportContractorVersion]) ||
								checkPermissions([PERMISSIONS.AdjustmentImportSduVersionPriceService]) ||
								checkPermissions([PERMISSIONS.AdjustmentImportSduVersionNominated])) &&
							isAdjustment === true
						))
				}
				{...props}
			>
				<span ref={trigger} />
			</Upload>
			{showModal &&
				(!!info.length ? (
					<EstimateImportModal
						res={info}
						show={showModal}
						onClose={() => {
							setShowModal(false)
							useAdjustmentState.setState({ isImportSdu: false })
						}}
						registry={registry}
						isAdjustment={isAdjustment}
						containerId={containerId!}
						permission={
							checkPermissions([PERMISSIONS.AdjustmentImportSduVersionNominated]) &&
							!currentUser?.isAdmin
								? 'nominated'
								: 'priceService'
						}
					/>
				) : (
					infoError()
				))}
		</>
	)
}
