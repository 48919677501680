import { useQueryClient } from '@tanstack/react-query'
import { Form, Input, Modal } from 'antd'
import { ASM_POSITION_PROPS } from 'enums'
import { memo, useEffect } from 'react'
import { useWbsPermits } from 'widgets/wbs'
import { useEstimateState } from '..'
import { EstimateApi } from '../api/estimate-api'

export const EstimateNote = memo(function () {
	const wbsPermissions = useWbsPermits()
	const queryClient = useQueryClient()
	const { isLoading, container, note, unSetNote, setEstimatePositions, updateSections } =
		useEstimateState()
	const [estimateNoteForm] = Form.useForm()
	const onClose = () => {
		estimateNoteForm.resetFields()
		unSetNote()
	}
	enum NOTE_TYPE {
		noteDP = 'Примечания ДП',
		noteSDU = 'Примечания СДУ',
		noteDZ = 'Примечания ДЗ'
	}

	const canEdit = (): boolean => {
		switch (note?.main?.type) {
			case 'noteDP':
				return wbsPermissions.canSetDP || wbsPermissions.isOuter || wbsPermissions.canLocalExternal
			case 'noteSDU':
				return wbsPermissions.canSetSDU || wbsPermissions.canLocalSdu
			case 'noteDZ':
				return (
					wbsPermissions.canSetDZ ||
					wbsPermissions.canSetDZSmr ||
					wbsPermissions.canLocalDzMTR ||
					wbsPermissions.canLocalDzSMR
				)
			default:
				return false
		}
	}

	useEffect(() => {
		note?.main.value && estimateNoteForm.setFieldValue('value', note.main.value)
	}, [note])

	const onSaveNote = async (values: any) => {
		if (note) {
			const dto = {
				id: 'materialName' in note.row ? note.row.estimatePositionId : note.row.id,
				workId: note.row.estimatePositionTechnologyWorkId,
				materialId: 'materialName' in note.row ? note.row.id : null,
				estimatePositionProps: ASM_POSITION_PROPS[note.main.type],
				value: values.value,
				version: note.row.versionId
			}
			await EstimateApi.setEstimatePositionNote(dto).then(() => {
				updateSections(note.row.containerSectionId!)
				// setEstimatePositions(note.row.containerSectionId)
				onClose()
			})
		}
	}

	return (
		<Modal
			open={typeof note !== 'undefined'}
			width={768}
			destroyOnClose
			centered
			title={`${note && NOTE_TYPE[note.main.type]} - ${
				note?.main.isWork ? 'Работа' : 'Материал'
			}: ${note?.main.name}`}
			onCancel={onClose}
			onOk={() => (!container?.ready && canEdit() ? estimateNoteForm.submit() : onClose())}
			okButtonProps={{ htmlType: 'submit', loading: isLoading }}
		>
			<Form form={estimateNoteForm} name="estimateNoteForm" onFinish={onSaveNote}>
				<Form.Item name="value">
					<Input.TextArea
						disabled={container?.ready || !canEdit()}
						allowClear
						autoSize
						showCount
						maxLength={5000}
						placeholder="Примечание"
						style={{ marginBottom: '1rem' }}
						styles={{ textarea: { minHeight: 256 } }}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
})
