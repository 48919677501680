import { ATIconSigma } from 'UI/icons'
import { Button, Card, Col, Divider, Form, FormInstance, InputNumber, Row, Tooltip } from 'antd'
import { all, create } from 'mathjs'
import { FC, useEffect } from 'react'
import { ROUND_VALUE } from 'shared/constants'
import { EstimatePositionHelpers } from 'widgets/estimate-position-card/model/estimate-position-helpers'
import {
	IEstimatePositionPermissions,
	useEstimatePositionState
} from 'widgets/estimate-position-card/model/estimate-position-state'
import { useWbsPermits } from 'widgets/wbs'
import { EstimatePositionWork } from './estimate-position-work'

interface IProps {
	disabled: boolean
	estimatePositionForm: FormInstance
	canEdit: boolean
	isClosed: boolean
	permissions?: IEstimatePositionPermissions
}

const config = {}
const math = create(all, config)

export const EstimatePositionWorks: FC<IProps> = ({
	canEdit,
	isClosed,
	disabled,
	estimatePositionForm,
	permissions
}) => {
	const wbsPermissions = useWbsPermits()

	const { currentTechnology, workData, workProperties, dto, setWorkAmount } =
		useEstimatePositionState()
	useEffect(() => {
		workData?.forEach(d => {
			estimatePositionForm.setFieldValue([d.work.id, 'notes', 'noteDP'], d.work.noteDP)
			estimatePositionForm.setFieldValue([d.work.id, 'notes', 'noteDZ'], d.work.noteDZ)
			estimatePositionForm.setFieldValue([d.work.id, 'notes', 'noteSDU'], d.work.noteSDU)
		})
	}, [workData])
	return workData?.find(el => el.sourceData && el.sourceData.length) ? (
		<>
			<Divider children="Список материалов" orientation="left" />
			{workData.map(
				techWork =>
					!!techWork.sourceData.length ? (
						<Card
							className="at-workcard"
							key={techWork.work.id}
							title={
								currentTechnology?.expandable && (
									<Row gutter={8} align="middle" justify="space-between">
										<Col>
											{/* <Flex gap={8}>
										<AppstoreOutlined />
										<h3 style={{ margin: 0 }}>{techWork?.work?.work?.name}</h3>
									</Flex> */}
										</Col>
										{currentTechnology?.expandable && (
											<Col style={{ paddingTop: '1rem' }}>
												<Row align="middle" gutter={8}>
													<Col>
														<b>Кол-во работ:</b>
													</Col>
													<Col>
														{!disabled && canEdit ? (
															<Form.Item
																name={[techWork.work.id, 'workAmount']}
																help={false}
																rules={[
																	{ required: true, message: 'Поле не должно быть пустым' },
																	() => ({
																		validator(_, value) {
																			if (value > 0) {
																				return Promise.resolve()
																			}
																			return Promise.reject(new Error('Не может быть равным 0'))
																		}
																	})
																]}
															>
																<InputNumber
																	// formatter={value => `${value}`.replace(/\./g, ',')}
																	parser={value => value!?.replace(/,/, '.')}
																	disabled={
																		disabled ||
																		techWork.work.isClosed === true ||
																		(techWork.work.isClosed === false &&
																			techWork.work.isEditable === true &&
																			dto?.technology.expandable === false) ||
																		(techWork.work.isClosed === false &&
																			techWork.work.isEditable === false) ||
																		(techWork.work.measureUnit?.isRounded === true &&
																			(isClosed ||
																				(techWork.work?.confirmedVolumeAmount! > 0 &&
																					currentTechnology?.expandable === true)))
																	}
																	// precision={ROUND_VALUE}
																	addonAfter={
																		<Button
																			ghost
																			type="default"
																			onClick={() =>
																				EstimatePositionHelpers.getWorkAmountSum(
																					techWork.work?.id!,
																					techWork.work.work.id
																				)
																			}
																			icon={<ATIconSigma style={{ fontSize: 12 }} />}
																		/>
																	}
																	controls={false}
																	keyboard={false}
																	changeOnWheel={false}
																	onBlur={el => {
																		const currentTarget = +el.currentTarget.value.replace(/,/, '.')
																		const check =
																			currentTechnology?.works.find(
																				w => w.work.id === techWork.work.work.id
																			)?.measureUnit.isRounded === true
																		if (!Number.isNaN(currentTarget) && check) {
																			setWorkAmount(
																				techWork.work.work.id,
																				String(math.ceil(currentTarget))
																			)
																		} else if (!Number.isNaN(currentTarget)) {
																			setWorkAmount(
																				techWork.work.work.id,
																				String(math.round(currentTarget, ROUND_VALUE))
																			)
																		}
																	}}
																></InputNumber>
															</Form.Item>
														) : (
															techWork.work.amount ?? 0
														)}
													</Col>
													<Col>
														<Tooltip title={techWork.work.measureUnit?.fullName}>
															{techWork.work.measureUnit?.name}
														</Tooltip>
													</Col>
												</Row>
												<div
													style={{
														maxWidth: 240,
														marginLeft: 110,
														fontSize: 12,
														color: 'gray',
														fontWeight: 'lighter'
													}}
												>
													{workProperties?.find(wp => wp.baseQuantity)?.commentAdditionalProperty}
												</div>
											</Col>
										)}
									</Row>
								)
							}
						>
							<EstimatePositionWork
								canEdit={canEdit}
								estimatePositionForm={estimatePositionForm}
								sourceData={techWork}
								expandable={currentTechnology?.expandable ?? false}
								disabled={disabled}
							/>
							{/* <EstimatePositionWorkNotes workId={techWork.work.id} style={{ marginTop: '8px' }} /> */}
						</Card>
					) : null
				// <Card className="at-workcard">
				// 	<Flex gap={8}>
				// 		<AppstoreOutlined />
				// 		<h3 style={{ margin: 0 }}>{techWork.work.work.name}</h3>
				// 	</Flex>
				// 	{/* <EstimatePositionWorkNotes workId={techWork.work.id} style={{ marginTop: '8px' }} /> */}
				// </Card>
			)}
		</>
	) : null
}
