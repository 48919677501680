import { useIsFetching } from '@tanstack/react-query'
import { Button, Col, DatePicker, Drawer, Form, List, Row, Select, Typography } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import dayjs from 'dayjs'
import { useAsmTypeSections } from 'hooks/useAsm'
import { ITypeSection } from 'interfaces/IContainer'
import {
	useCurrentConstructionObjects,
	useLocalEstimateAuthors
} from 'pages/unmodelPage/localEstimate/model/localEstimateQuery'
import { FC, useEffect, useState } from 'react'
import { DATE_FORMAT } from 'shared/constants'
import { ILocalEstimate, ILocalEstimateRegistryFilters, useLocalEstimateRegistryState } from '..'
import { useLocalEstimateStatus } from '../model/local-estimate-registry-query'

const { Text } = Typography

interface IFilterItem {
	title: string
	formItemName: string
	list: ITypeSection[] | ILocalEstimate[] | any[] | null | undefined
	date: boolean
}
interface IProps {}

export const LocalEstimateRegistryFilters: FC<IProps> = props => {
	const isLoading = useIsFetching() !== 0
	const { filters, showFilters, setFilters, setShowFilters, resetFilters } =
		useLocalEstimateRegistryState()
	const { data: asmTypeSection, isSuccess: isSuccessAsmTypeSection } = useAsmTypeSections()
	const { data: asmAuthors, isSuccess: isSuccessAsmAuthors } = useLocalEstimateAuthors()
	const { data: wbsStatuses, isSuccess: isSuccessWbsStatuses } = useLocalEstimateStatus()
	const { data: constructionObjects, isSuccess: isSuccessConstructionObjects } =
		useCurrentConstructionObjects()

	const [wbsRegistryFiltersForm] = Form.useForm<ILocalEstimateRegistryFilters>()

	useEffect(() => {
		showFilters &&
			wbsRegistryFiltersForm.setFieldsValue({
				...filters,
				createdDate:
					filters.createdDate && dayjs(filters.createdDate).isValid()
						? dayjs(filters.createdDate)
						: null
			})
	}, [showFilters])

	const filterList: IFilterItem[] = [
		{
			title: 'Статус',
			formItemName: 'containerStatusId',
			list: wbsStatuses,
			date: false
		},
		{
			title: 'Автор',
			formItemName: 'authorId',
			list: asmAuthors,
			date: false
		},
		{
			title: 'Дата создания',
			formItemName: 'createdDate',
			list: null,
			date: true
		},
		{
			title: 'Вид раздела',
			formItemName: 'typeSectionId',
			list: asmTypeSection,
			date: false
		},
		{
			title: 'Объект строительства',
			formItemName: 'constructionObjectId',
			list: constructionObjects,
			date: false
		},
		{
			title: 'Локальная смета подготовлена',
			formItemName: 'ready',
			list: [
				{ label: 'Да', value: true },
				{ label: 'Нет', value: false }
			],
			date: false
		}
	]
	const [filterItems, setFilterItems] = useState<IFilterItem[]>(filterList)
	useEffect(() => {
		setFilterItems(filterList)
	}, [
		isSuccessAsmTypeSection,
		isSuccessAsmAuthors,
		isSuccessConstructionObjects,
		isSuccessWbsStatuses
	])

	const onSetFilters = (values: ILocalEstimateRegistryFilters) => {
		const prepare = {
			...values,
			createdDate:
				values?.createdDate && dayjs(values?.createdDate).isValid()
					? dayjs(values?.createdDate).format(DATE_FORMAT.dto)
					: undefined
		}
		console.log('values', values)
		console.log('prepare', prepare)
		setFilters(prepare)
		setShowFilters(false)
	}

	const onResetFilters = () => {
		wbsRegistryFiltersForm.resetFields()
		resetFilters()
		setShowFilters(false)
	}

	return (
		<Drawer
			destroyOnClose
			open={showFilters}
			onClose={() => setShowFilters(false)}
			placement="left"
			title="Фильтры"
			footer={
				<Row gutter={8} justify="end">
					<Col>
						<Button loading={isLoading} ghost danger onClick={onResetFilters}>
							Сбросить
						</Button>
					</Col>
					<Col>
						<Button
							loading={isLoading}
							type="primary"
							htmlType="submit"
							onClick={() => wbsRegistryFiltersForm.submit()}
						>
							Применить
						</Button>
					</Col>
				</Row>
			}
		>
			<Form name="wbsRegistryFiltersForm" form={wbsRegistryFiltersForm} onFinish={onSetFilters}>
				<List
					loading={isLoading}
					dataSource={filterItems}
					renderItem={(item, index) => (
						<Row align="middle" gutter={8}>
							<Col style={{ flex: '0 0 100%' }}>
								<Text strong>{item.title}:</Text>
							</Col>
							<Col style={{ flex: '1 0 100%' }}>
								<Form.Item key={index} name={item.formItemName}>
									{!item.date ? (
										<Select
											loading={isLoading}
											showSearch
											allowClear
											optionFilterProp="children"
											filterOption={(input, option) =>
												(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
											}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '')
													.toString()
													.toLowerCase()
													.localeCompare((optionB?.label ?? '').toString().toLowerCase())
											}
											style={{ width: '100%' }}
											placeholder="Не выбран"
											options={
												item.formItemName !== 'ready'
													? item.list?.map((option, index) => ({
															key: option.id
																? option.id
																: option.authorId
																? option.authorId
																: index,
															value: option.id
																? option.id
																: option.authorId
																? option.authorId
																: index,
															label:
																item.formItemName === 'containerStatusId'
																	? option.description
																	: option.name
																	? option.name
																	: option
													  }))
													: item.list?.map((option, index) => ({
															key: option.id ? option.id : index,
															value: option.label === 'Да' ? true : false,
															label: option.label
													  }))
											}
											// onChange={value => onChangeFilter(item.formItemName, value)}
										/>
									) : (
										<DatePicker
											locale={locale}
											style={{ width: '100%' }}
											format={'DD.MM.YYYY'}
											// onChange={date => onChangeFilter(item.formItemName, date!)}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>
					)}
				/>
			</Form>
		</Drawer>
	)
}
