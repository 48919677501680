import { FileTextOutlined } from '@ant-design/icons'
import type { DatePickerProps } from 'antd'
import {
	Badge,
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Descriptions,
	Divider,
	Form,
	FormInstance,
	Input,
	Row,
	Select,
	Tag,
	Tooltip,
	UploadFile
} from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useCheckListTemplatesActiveUKKS } from 'hooks/useChecklistTemplateList'
import { useNormativeActs } from 'hooks/useNormativeActs'
import { IContract } from 'interfaces/IContract'
import { IContractor } from 'interfaces/IEnvironment'
import {
	IContactPerson,
	IIssue,
	IIssueComment,
	IIssueDTOExtended,
	IIssueDrawerPermissions,
	IIssueStatus,
	IIssueSubType,
	IIssueViolationType
} from 'interfaces/IIssue'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getContracts } from 'services/ContractsService'
import {
	getContractors,
	getContractsByProject1CId,
	getPotentialAuthors,
	issuesElementTranslate
} from 'services/IssuesService'
import { INormativeAct } from 'services/NormativeActsService'
import { getBuildingPermitsList, getContactPersonsList } from 'services/OrdinanceService'
import { IViolation } from 'services/violationsService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setDtoValue } from 'store/issueSlice'
import { YandexTrackerButton } from 'widgets/yandex-tracker/ui/yandex-tracker-button'
import { CommentsList } from '../IssueComments'

const { Option } = Select

interface IIssueDetailsProps {
	issue: IIssue | null
	issueForm: FormInstance<IIssueDTOExtended>
	statusList: IIssueStatus[]
	permissions: IIssueDrawerPermissions
	disabled: boolean
	filesToUpload: UploadFile[]
	issueComments: IIssueComment[]
	issueSource?: number
	setFilesToUpload: (x: UploadFile[]) => void
	drawerOpen: boolean
}

export const IssueDetails: FC<IIssueDetailsProps> = ({
	issue,
	drawerOpen,
	issueForm,
	statusList,
	permissions,
	disabled,
	filesToUpload,
	issueComments,
	setFilesToUpload
}) => {
	const ordinance = issue?.ordinance ?? undefined
	return ordinance && typeof ordinance !== 'undefined' && ordinance !== null ? (
		<Badge.Ribbon
			style={{
				width: 32,
				height: 32,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
			text={
				<Tooltip
					title={`Предписание: ${ordinance!?.name! ? ordinance!?.name! : ordinance!?.number!}`}
				>
					<FileTextOutlined style={{ fontSize: 24 }} rev={undefined} />
				</Tooltip>
			}
			color={'blue'}
		>
			{/* <IssueDetail issueForm={issueForm} disabled={disabled} /> */}
			<IssueDetailsCard
				drawerOpen={drawerOpen}
				issue={issue}
				issueForm={issueForm}
				statusList={statusList}
				permissions={permissions}
				disabled={disabled}
				filesToUpload={filesToUpload}
				issueComments={issueComments}
				setFilesToUpload={setFilesToUpload}
			/>
		</Badge.Ribbon>
	) : (
		// <IssueDetail issueForm={issueForm} disabled={disabled} />
		<IssueDetailsCard
			drawerOpen={drawerOpen}
			issue={issue}
			issueForm={issueForm}
			statusList={statusList}
			permissions={permissions}
			disabled={disabled}
			filesToUpload={filesToUpload}
			issueComments={issueComments}
			setFilesToUpload={setFilesToUpload}
		/>
	)
}
export default IssueDetails

const IssueDetailsCard: FC<IIssueDetailsProps> = ({
	issue,
	issueForm,
	statusList,
	permissions,
	disabled,
	drawerOpen
}) => {
	const checkPermissions = useCheckPermissions()
	const dispatch = useAppDispatch()
	const { id: issueId } = useParams()
	const {
		user,
		project,
		allProjects,
		contracts,
		issueTypes,
		workPackages,
		buildingPermits: buildingPermitsCurrent,
		permissions: userPermissions,
		contractors: envContractors,
		issueSubTypes: envSubTypes,
		violationTypes: envViolationTypes,
		violations: envViolations
	} = useAppSelector(state => state.environment)
	const issueTypeField = Form.useWatch('issueTypeId', issueForm)
	const cancelId = '958da9b4-0ac3-43bc-8f0c-3b61341083b7'
	const [contractsCurr, setContractsCurr] = useState<IContract[]>()
	const { init: issueInit, dto: issueDto } = useAppSelector(state => state.issues)
	const [issueSubTypes, setIssueSubTypes] = useState<IIssueSubType[]>([])
	const [buildingPermits, setBuildingPermits] = useState(buildingPermitsCurrent)
	const [issueViolationTypes, setIssueViolationTypes] = useState<IIssueViolationType[]>([])
	const [violations, setViolations] = useState<IViolation[]>([])
	const [issueContract, setIssueContract] = useState<IContract[]>([])
	const [contractors, setContractors] = useState<IContractor[]>([])
	const [subContractors, setSubContractors] = useState<IContractor[]>([])
	const [contactPersons, setContactPersons] = useState<IContactPerson[]>([])
	const [isViolationGuide, setIsViolationGuide] = useState(false)
	const { data: checkListTemplatesActiveUkKS } = useCheckListTemplatesActiveUKKS()
	const { data: actPointsData } = useNormativeActs(drawerOpen)
	const [owners, setOwners] = useState<any[]>([])
	const [filteredActs, setFilteredActs] = useState<INormativeAct[]>([])
	const currentStatus = statusList.find(
		status => status.id === issueDto.issueStatusId
	)?.identityName
	const canEdit =
		!disabled &&
		((issueDto.id !== null && permissions.canEdit) ||
			(issueDto.id === null && permissions.canCreate)) &&
		issueDto.issueStatusId !== cancelId
	const contractorIdFiled = Form.useWatch('contractorId', issueForm)

	const projectUser = user?.projects?.find(p => p.id === issueDto?.projectId)

	const isAcceptance =
		issueTypes?.find(type => type.id === issueTypeField)?.name.includes('Приемка УК') ?? false

	const selectedViolationId = issueForm.getFieldValue('violationId')
	const selectedViolationTypeId = issueForm.getFieldValue('violationTypeId')
	const selectedWorkPackageId = issueForm.getFieldValue('workPackageId')
	const selectedActPointId = issueForm.getFieldValue('actPointId')
	useEffect(() => {
		if (selectedWorkPackageId || drawerOpen) {
			const acts =
				actPointsData?.filter(act =>
					act.workPackages.some(
						workPackage => workPackage.id === (selectedWorkPackageId ?? issueDto.workPackageId)
					)
				) ?? []
			const actVio =
				acts?.filter(v =>
					v.violations?.some(vio => vio.id === (selectedViolationId ?? issueDto.violationId))
				) ?? []
			setFilteredActs((selectedViolationId ? actVio : acts) ?? acts)
		}
	}, [issueDto, actPointsData, drawerOpen, selectedViolationTypeId])

	useEffect(() => {
		if (filteredActs && selectedActPointId) {
			const vio = filteredActs?.find(a => a.id === selectedActPointId)?.violations
			setIsViolationGuide(true)
			setViolations(vio!)
		}
	}, [filteredActs, selectedActPointId, selectedViolationTypeId])

	useEffect(() => {
		getPotentialAuthors(project?.id!).then(response => {
			setOwners(response)
		})
	}, [])

	useEffect(() => {
		setContractors([])
		const getCurrentContractors = async () => {
			if (project !== null && project!?.project1C!) {
				if (!permissions.allCompanies) {
					setContractors(envContractors?.filter(item => item!?.id! === user!?.contractorId!) ?? [])
				} else {
					setContractors(envContractors ? envContractors : [])
				}
			}
		}
		getCurrentContractors()
	}, [])

	useEffect(() => {
		getContractors(issueDto.projectId).then(data => setContractors(data))
	}, [issueDto])

	useEffect(() => {
		// setIsViolationGuide(typeof issueDto.violationId !== 'undefined')
		if (typeof issueDto.violationId !== 'undefined' && issueDto.violationId !== null) {
			setIsViolationGuide(true)
		}
	}, [issueDto.violationId])

	useEffect(() => {
		getSubTypes(issueDto.issueTypeId)
	}, [issueDto.issueTypeId])

	useEffect(() => {
		getViolationsTypes(issueDto.issueSubTypeId)
	}, [issueDto.issueSubTypeId])

	useEffect(() => {
		getViolations(issueDto.violationTypeId)
	}, [issueDto.violationTypeId])

	useEffect(() => {
		const contracts = async () => {
			getContractsByProject1CId().then(data => {
				setContractsCurr(data)
			})
		}
		contracts()
	}, [issueId, issueDto])

	useEffect(() => {
		const getContracts = async () => {
			if (contractsCurr) {
				const subs = await getSubContractors(contractsCurr)
				if (subs) {
					setSubContractors(subs)
					if (subs?.length === 1) {
						issueForm.setFieldValue('subcontractorId', subs[0].id)
						if (!issueDto.subcontractorId) {
							dispatch(setDtoValue({ prop: 'subcontractorId', value: subs[0].id }))
						}
					}
				}
			}
		}
		getContracts()
	}, [contracts, issueDto.contractorId, issueDto, contractsCurr])

	useEffect(() => {
		if (issueDto.contractorId?.length > 0) {
			// const selectedContractor = contractors.find(ctr => ctr.id === issueDto.contractorId) ?? null
			const subs = getSubContractors(contractsCurr!)
			if (subs) {
				setSubContractors(subs)
			}
		}
	}, [contractors])

	useEffect(() => {
		projectUser &&
			getBuildingPermitsList(projectUser?.project1C?.id, projectUser.id).then(data => {
				setBuildingPermits(data!)
				if (!issueDto.id && data?.length === 1) {
					data?.length === 1 && issueForm.setFieldValue('buildingPermitId', data[0].id)
					dispatch(setDtoValue({ prop: 'buildingPermitId', value: data[0].id }))
				}
			})
		const setContracts = async (id: string) => {
			await getContracts({
				contractorId: id,
				projectId: issueDto?.projectId
			}).then(data => {
				setIssueContract(data)
				if (data.length === 1) {
					dispatch(setDtoValue({ prop: 'contractId', value: data[0].id }))
					issueForm.setFieldValue('contractId', data[0].id)
				}
			})
			await getContactPersonsList(id).then(data => {
				setContactPersons(data)
			})
		}
		if (contractorIdFiled) {
			setContracts(contractorIdFiled)
		}
		if (!canEdit && issueDto.contractorId) {
			setContracts(issueDto.contractorId)
		}
	}, [contractorIdFiled, issueDto.contractorId, issueDto?.projectId])

	useEffect(() => {
		if (violations?.length === 1 && issueDto.violationId !== selectedViolationId) {
			dispatch(setDtoValue({ prop: 'violationId', value: violations[0].id }))
			dispatch(setDtoValue({ prop: 'description', value: violations[0].name }))
			issueForm.setFieldValue('violationId', violations[0].id)
			setIsViolationGuide(true)
		}
	}, [violations])

	useEffect(() => {
		if (filteredActs?.length === 1 && issueDto.actPointId !== selectedActPointId) {
			dispatch(setDtoValue({ prop: 'actPointId', value: filteredActs[0].id }))
			issueForm.setFieldValue('actPointId', filteredActs[0].id)
		}
	}, [filteredActs])

	const getSubContractors = (contracts: IContract[]) => {
		setSubContractors([])
		let subs: IContractor[] = []
		if (contracts?.length > 0) {
			const objects: IContractor[] = contracts
				.filter((contract: IContract) => contract!?.contractor!?.id! === issueDto.contractorId!)
				.flatMap((contract: IContract) => contract!?.subContractor!)
			objects.forEach(obj => {
				if (obj !== null)
					if (!subs.some(item => item?.id! === obj?.id!)) {
						subs.push(obj)
					}
			})

			// if (typeof subContractors.find(sub => sub?.id === issueDto.contractorId) === 'undefined') {
			const selectedContractor = contractors.find(ctr => ctr.id === issueDto.contractorId!) ?? null
			if (selectedContractor !== null) {
				subs.unshift(selectedContractor)
			}
			// }
		}
		return subs
	}

	const getSubTypes = async (issueTypeId: string) => {
		const subs = envSubTypes!?.filter(sub => sub.issueTypeId === issueTypeId) ?? []
		setIssueSubTypes(subs)
	}

	const getViolationsTypes = async (id: string) => {
		const vioTypes = envViolationTypes!?.filter(vio => vio.issueSubTypeId === id) ?? []
		setIssueViolationTypes(vioTypes)
	}

	const getViolations = async (id: string) => {
		const vio = envViolations!?.filter(vio => vio.violationTypes.some(x => x.id === id)) ?? []
		if (vio.length === 0) setIsViolationGuide(false)
		if (vio.length > 0 && issueForm.getFieldValue('description')?.length === 0)
			setIsViolationGuide(true)
		setViolations(vio)
	}
	const onIssueChange = (value: string, type?: string) => {
		switch (type) {
			case 'issueType':
				{
					const selected = issueTypes?.find(type => type.id === value)?.name
					dispatch(
						setDtoValue({
							prop: 'issueTypeId',
							value: typeof selected !== 'undefined' ? value : ''
						})
					)
					dispatch(
						setDtoValue({
							prop: 'issueSubTypeId',
							value: ''
						})
					)
					dispatch(
						setDtoValue({
							prop: 'violationTypeId',
							value: ''
						})
					)
					dispatch(
						setDtoValue({
							prop: 'violationId',
							value: ''
						})
					)
					issueForm.setFieldValue('issueSubTypeId', '')
					issueForm.setFieldValue('violationTypeId', '')
					issueForm.setFieldValue('violation', '')
				}
				break
			case 'sub':
				{
					const selected = issueSubTypes.find(type => type.id === value)?.name

					dispatch(
						setDtoValue({
							prop: 'issueSubTypeId',
							value: typeof selected !== 'undefined' ? value : ''
						})
					)
					dispatch(
						setDtoValue({
							prop: 'violationTypeId',
							value: ''
						})
					)
					dispatch(
						setDtoValue({
							prop: 'violationId',
							value: ''
						})
					)
					issueForm.setFieldValue('violationTypeId', '')
					issueForm.setFieldValue('violation', '')
				}
				break

			case 'violationType':
				{
					const selected = issueViolationTypes.find(type => type.id === value)?.name
					dispatch(
						setDtoValue({
							prop: 'violationTypeId',
							value: typeof selected !== 'undefined' ? value : ''
						})
					)
					dispatch(
						setDtoValue({
							prop: 'violationId',
							value: null
						})
					)
					issueForm.setFieldValue('violationId', '')
					issueForm.setFieldValue('description', '')
				}
				break

			case 'actPoint':
				dispatch(
					setDtoValue({
						prop: 'actPointId',
						value: value
					})
				)
				break
			case 'actPoints':
				dispatch(
					setDtoValue({
						prop: 'actPoints',
						value: value
					})
				)
				break

			case 'actPointView':
				const act = filteredActs.find(act => act.id === value)
				dispatch(
					setDtoValue({
						prop: 'actPointId',
						value: value
					})
				)
				dispatch(
					setDtoValue({
						prop: 'actPoints',
						value: act?.name ?? ''
					})
				)
				break
			case 'violation':
				const vio = violations.find(v => v.id === value)
				dispatch(
					setDtoValue({
						prop: 'violationId',
						value: value
					})
				)
				dispatch(
					setDtoValue({
						prop: 'description',
						value: vio?.name ?? ''
					})
				)
				break

			case 'contractor':
				dispatch(
					setDtoValue({
						prop: 'contractorId',
						value: value
					})
				)
				dispatch(
					setDtoValue({
						prop: 'contractId',
						value: undefined
					})
				)
				dispatch(
					setDtoValue({
						prop: 'subcontractorId',
						value: null
					})
				)
				dispatch(
					setDtoValue({
						prop: 'constructionObjectId',
						value: null
					})
				)
				dispatch(
					setDtoValue({
						prop: 'contactPersonId',
						value: undefined
					})
				)
				issueForm.setFieldValue('contractId', undefined)
				issueForm.setFieldValue('contactPersonId', undefined)
				issueForm.setFieldValue('constructionObjectId', undefined)
				issueForm.setFieldValue('subcontractorId', undefined)
				break

			case 'subcontractor':
				dispatch(
					setDtoValue({
						prop: 'subcontractorId',
						value: value
					})
				)
				break

			case 'constructionObject':
				dispatch(
					setDtoValue({
						prop: 'constructionObjectId',
						value: value
					})
				)
				break

			case 'contract':
				dispatch(
					setDtoValue({
						prop: 'contractId',
						value: value
					})
				)
				break

			case 'buildingPermit':
				dispatch(
					setDtoValue({
						prop: 'buildingPermitId',
						value: value
					})
				)
				break

			case 'contactPerson':
				dispatch(
					setDtoValue({
						prop: 'contactPersonId',
						value: value
					})
				)
				break

			case 'system':
				dispatch(
					setDtoValue({
						prop: 'systemId',
						value: value
					})
				)
				break

			default: {
				dispatch(
					setDtoValue({
						prop: 'workPackageId',
						value: value
					})
				)
				dispatch(
					setDtoValue({
						prop: 'issueTypeId',
						value: ''
					})
				)
				dispatch(
					setDtoValue({
						prop: 'issueSubTypeId',
						value: ''
					})
				)
				dispatch(
					setDtoValue({
						prop: 'violationTypeId',
						value: ''
					})
				)
				dispatch(
					setDtoValue({
						prop: 'violationId',
						value: ''
					})
				)
				dispatch(
					setDtoValue({
						prop: 'actPoints',
						value: ''
					})
				)
				dispatch(
					setDtoValue({
						prop: 'actPoint',
						value: ''
					})
				)
				issueForm.setFieldValue('actPoints', '')
				issueForm.setFieldValue('violationId', '')
				issueForm.setFieldValue('issueTypeId', '')
				issueForm.setFieldValue('issueSubTypeId', '')
				issueForm.setFieldValue('violationTypeId', '')
				issueForm.setFieldValue('violation', '')
			}
		}
	}

	const onDueDateChange: DatePickerProps['onChange'] = (date, dateString) => {
		dispatch(
			setDtoValue({
				prop: 'dueDate',
				value: date
			})
		)
	}
	const onChangeGuideType = (checked: boolean) => {
		issueForm.setFieldsValue({ isTypical: false })
		setIsViolationGuide(checked)
		dispatch(
			setDtoValue({
				prop: 'violationId',
				value: undefined
			})
		)
		dispatch(
			setDtoValue({
				prop: 'description',
				value: null
			})
		)
		issueForm.setFieldValue('description', undefined)
		issueForm.setFieldValue('violationId', undefined)
	}
	return (
		<>
			<Card
				className="at-workcard"
				title={
					<Row justify="space-between" align="middle">
						<Col>
							<Row align="middle">
								<Col>
									<span style={{ fontSize: '16px' }}>Основные параметры</span>
								</Col>
								{issueDto.isMobile && (
									<Tag style={{ fontSize: '16px', marginLeft: '10px' }} color="blue">
										mobile
									</Tag>
								)}
							</Row>
						</Col>
						<Col>
							<YandexTrackerButton text={'Сообщить об ошибке'} />
						</Col>
					</Row>
				}
			>
				<Descriptions
					layout="horizontal"
					column={12}
					bordered
					className="at-descriptions"
					labelStyle={{
						fontWeight: 'bold',
						width: '20%',
						alignItems: 'center',
						padding: '2px 8px'
					}}
					contentStyle={{
						width: '80%',
						padding: '0px 0px'
					}}
				>
					<Descriptions.Item
						label="Фаза"
						span={12}
						labelStyle={{
							fontWeight: 'bold'
						}}
					>
						<p style={{ paddingLeft: '8px', margin: 0 }}>
							{allProjects.find(ap => ap.id === project?.id)?.phase ?? '-'}
						</p>
					</Descriptions.Item>
					<Descriptions.Item
						label="Корпус"
						span={12}
						labelStyle={{
							fontWeight: 'bold'
						}}
					>
						<p style={{ paddingLeft: '8px', margin: 0 }}>
							{allProjects.find(ap => ap.id === project?.id)?.building ?? '-'}
						</p>
					</Descriptions.Item>
					<Descriptions.Item
						label="Руководитель строительства"
						span={12}
						labelStyle={{
							fontWeight: 'bold'
						}}
					>
						<p style={{ paddingLeft: '8px', margin: 0 }}>
							{allProjects.find(ap => ap.id === project?.id)?.responsibleManager?.name ?? '-'}
						</p>
					</Descriptions.Item>

					<Descriptions.Item
						label="Автор"
						span={12}
						labelStyle={{
							fontWeight: 'bold'
						}}
					>
						{user?.isAdmin ? (
							<Form.Item
								name="ownerId"
								rules={[
									{
										required: true,
										message: 'Выберите автора'
									}
								]}
								initialValue={issueDto.id ? issueDto.ownerId : user?.id}
							>
								<Select
									allowClear
									showSearch
									defaultValue={issueDto.id ? issueDto.ownerId : user?.id}
									onChange={value => {
										dispatch(
											setDtoValue({
												prop: 'ownerId',
												value: value
											})
										)
									}}
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									filterSort={(a, b) =>
										(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
									}
									options={owners.map(item => ({
										key: item.id,
										value: item.id,
										label: item.name
									}))}
								/>
							</Form.Item>
						) : (
							<p style={{ paddingLeft: '8px', margin: 0 }}>
								{issueDto.id ? issueDto?.owner?.name : user?.firstName + ' ' + user?.lastName}
							</p>
						)}
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<>
								Разрешение на строительство:
								{canEdit && !isAcceptance && <span style={{ color: 'red' }}>*</span>}
							</>
						}
						span={12}
					>
						{canEdit ? (
							<Form.Item
								name="buildingPermitId"
								rules={[
									{
										required: !isAcceptance,
										message: 'Выберите разрешение на строительство'
									}
								]}
							>
								<Select
									allowClear
									showSearch
									value={issueDto.buildingPermitId}
									onChange={el => onIssueChange(el, 'buildingPermit')}
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									filterSort={(a, b) =>
										(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
									}
									options={buildingPermits?.map(item => ({
										key: item.id,
										value: item.id,
										label: item.name
									}))}
								/>
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>
								{buildingPermits?.find(b => b.id === issueDto.buildingPermitId)?.name ??
									'не указано'}
							</p>
						)}
					</Descriptions.Item>
				</Descriptions>
			</Card>
			<Card className="at-workcard" title="Нарушение">
				<Descriptions
					layout="horizontal"
					column={12}
					bordered
					className="at-descriptions"
					labelStyle={{
						fontWeight: 'bold',
						width: '20%',
						alignItems: 'center',
						padding: '2px 8px'
					}}
					contentStyle={{
						width: '80%',
						padding: '0px 0px'
					}}
				>
					<Descriptions.Item label="Типовое" span={12}>
						<Form.Item
							name="isTypical"
							valuePropName="checked"
							style={{ marginBottom: 0, paddingLeft: '16px' }}
						>
							<Checkbox
								onChange={e => {
									setIsViolationGuide(true)
									dispatch(
										setDtoValue({
											prop: 'isTypical',
											value: e.target.checked
										})
									)
									if (!e.target.checked) {
										issueForm.setFieldsValue({
											actPointId: '',
											actPoints: undefined,
											violationId: undefined,
											description: undefined,
											issueTypeId: '',
											issueSubTypeId: '',
											violationTypeId: '',
											workPackageId: undefined
										})
									}
								}}
								checked={issueDto.isTypical!}
							/>
						</Form.Item>
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<>
								Пакет СМР
								{issueDto.isTypical && <span style={{ color: 'red' }}>*</span>}
							</>
						}
						span={24}
					>
						{canEdit ? (
							<Form.Item
								name="workPackageId"
								rules={[
									{
										required: issueDto.isTypical!,
										message: 'Пакет СМР является обязательным'
									}
								]}
							>
								<Select
									allowClear
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									onChange={value => {
										onIssueChange(value)
										if (value) {
											dispatch(
												setDtoValue({
													prop: 'isTypical',
													value: true
												})
											)
											issueForm.setFieldsValue({ isTypical: true })
											issueForm.setFieldsValue({ actPointId: '' })
										} else {
											dispatch(
												setDtoValue({
													prop: 'isTypical',
													value: false
												})
											)
											issueForm.setFieldsValue({ isTypical: false })
										}
									}}
									value={issueDto.workPackageId}
									style={{
										width: '100%',
										textOverflow: 'ellipsis',
										border: 'none'
									}}
									options={[...workPackages!]
										?.sort((a, b) => a.code! - b.code!)
										?.map(wp => ({
											key: wp.id,
											value: wp.id,
											label: wp.code + ' ' + wp.name
										}))}
								/>
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>
								{workPackages?.find(p => p.id === issueDto.workPackageId)?.name ?? ''}
							</p>
						)}
					</Descriptions.Item>
					<Descriptions.Item
						label={
							<>
								Тип замечания<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
					>
						{canEdit ? (
							<Form.Item
								name="issueTypeId"
								rules={[
									{
										required: true,
										message: 'Укажите тип замечания'
									}
								]}
							>
								<Select
									allowClear
									onChange={el => onIssueChange(el, 'issueType')}
									value={issueDto.issueTypeId}
									style={{
										width: '100%',
										textOverflow: 'ellipsis',
										border: 'none'
									}}
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? '')!.toLowerCase().includes(input.toLowerCase())
									}
									filterSort={(a, b) =>
										(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
									}
									options={
										issueDto.workPackageId
											? workPackages
												?.find(p => p.id === issueDto.workPackageId)
												?.issueTypes.map(v => ({
													key: v.id,
													value: v.id,
													label: v.name
												})) ?? []
											: issueTypes
												?.filter(x =>
													issueDto.id === null
														? !x.isDeleted
														: !issueTypes?.find(x => x.id === issueDto!?.issueTypeId!)?.isDeleted
															? !x.isDeleted
															: x
												)
												.map(v => ({
													key: v.id,
													value: v.id,
													label: `${v.name}  ${v.isDeleted ? '(Удалено)' : ''}`
												}))
									}
								/>
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>
								{issuesElementTranslate(
									issueTypes!?.find(type => type.id === issueDto.issueTypeId)?.name!
								)}
							</p>
						)}
					</Descriptions.Item>
					<Descriptions.Item
						label={
							<>
								Подтип замечания<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
					>
						{canEdit ? (
							<Form.Item
								name="issueSubTypeId"
								rules={[
									{
										required: true,
										message: 'Укажите подтип замечания'
									}
								]}
							>
								<Select
									allowClear
									disabled={issueDto.issueTypeId?.length === 0}
									onChange={el => onIssueChange(el, 'sub')}
									value={issueDto!?.issueSubTypeId!}
									style={{
										width: '100%',
										textOverflow: 'ellipsis'
									}}
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									// filterSort={(a, b) =>
									// 	(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
									// }
									options={issueSubTypes
										.filter(x =>
											issueDto.id === null
												? !x.isDeleted
												: !issueSubTypes!?.find(x => x.id === issueDto!?.issueSubTypeId!)?.isDeleted
													? !x.isDeleted
													: x
										)
										.sort((a, b) => {
											if (a.order === null) return 1
											if (b.order === null) return -1
											if (a.order === b.order) return 0
											return a.order < b.order ? -1 : 1
										})
										.map(v => ({
											key: v.id,
											value: v.id,
											label: `${v.name}  ${v.isDeleted ? '(Удалено)' : ''}`
										}))}
								/>
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>
								{issueSubTypes!?.find(type => type.id === issueDto!?.issueSubTypeId!)?.name!}
							</p>
						)}
					</Descriptions.Item>
					<Descriptions.Item
						label={
							<>
								Тип нарушения<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
					>
						{canEdit ? (
							<Form.Item
								name="violationTypeId"
								style={{
									display: 'grid'
								}}
								rules={[
									{
										required: true,
										message: 'Укажите тип нарушения'
									}
								]}
							>
								<Select
									allowClear
									disabled={issueDto.issueSubTypeId?.length === 0}
									onChange={el => {
										onIssueChange(el, 'violationType')
									}}
									value={issueDto.violationTypeId}
									style={{
										width: '100%',
										textOverflow: 'ellipsis'
									}}
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									filterSort={(a, b) =>
										(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
									}
									options={issueViolationTypes!
										?.filter(x =>
											issueDto.id === null
												? !x.isDeleted
												: !issueViolationTypes!?.find(x => x.id === issueDto.violationTypeId!)
													?.isDeleted
													? !x.isDeleted
													: x
										)
										.map(v => ({
											key: v.id,
											value: v.id,
											label: `${v.name}  ${v.isDeleted ? '(Удалено)' : ''}`
										}))}
								/>
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>
								{issueViolationTypes!?.find(type => type.id === issueDto.violationTypeId!)?.name!}
							</p>
						)}
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<>
								Нарушение<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
						labelStyle={{
							fontWeight: 'bold'
						}}
					>
						{canEdit ? (
							<Form.Item
								style={{
									width: '100%'
								}}
								name="title"
								rules={[
									{
										required: true,
										message: 'Укажите нарушение'
									}
								]}
							>
								<Input
									onChange={el =>
										dispatch(
											setDtoValue({
												prop: 'title',
												value: el.target.value
											})
										)
									}
								/>
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>{issueDto!?.title!}</p>
						)}
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<>
								Номер пункта нормативного акта:
								{canEdit && !isAcceptance && <span style={{ color: 'red' }}>*</span>}
							</>
						}
						span={12}
						labelStyle={{
							fontWeight: 'bold'
						}}
					>
						{canEdit ? (
							<Form.Item
								style={{
									width: '100%'
								}}
								name={!issueDto.isTypical ? 'actPoints' : 'actPointId'}
								rules={[
									{
										required: !isAcceptance,
										message: 'Выберите номер пункта нормативного акта'
									}
								]}
							>
								{issueDto.isTypical || issueDto.actPointId !== null ? (
									<Select
										allowClear
										showSearch
										disabled={issueDto.violationTypeId?.length === 0}
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										options={filteredActs?.map(v => ({
											key: v.id,
											value: v.id,
											label: v.name
										}))}
										onChange={value => {
											onIssueChange(value, 'actPointView')
										}}
										dropdownRender={menu => (
											<>
												{menu}
												<Divider style={{ margin: '8px 0' }} />
												<Button
													block
													onClick={() => {
														dispatch(
															setDtoValue({
																prop: 'isTypical',
																value: false
															})
														)
														dispatch(
															setDtoValue({
																prop: 'actPointId',
																value: null
															})
														)
														issueForm.setFieldsValue({
															actPointId: null,
															actPoints: '',
															isTypical: false
														});
													}}
												>
													Ручной ввод
												</Button>
											</>
										)}
									/>
								) : (
									<Input
										value={issueDto.actPoints}
										onChange={el =>
											dispatch(
												setDtoValue({
													prop: 'actPoints',
													value: el.target.value
												})
											)
										}
									/>
								)}
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>{issueDto!?.actPoints!}</p>
						)}
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<>
								Описание<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
					>
						{canEdit ? (
							<Form.Item
								name={issueDto.workPackageId && isViolationGuide ? 'violationId' : 'description'}
								rules={[
									{
										required: true,
										message: 'Описание не должно быть пустым'
									}
								]}
							>
								{issueDto.workPackageId &&
									isViolationGuide &&
									(issueDto.violationId !== null || issueDto.isTypical) ? (
									<Select
										allowClear
										value={issueDto.violationId}
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										filterSort={(a, b) =>
											(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
										}
										disabled={!issueDto.workPackageId || !violations?.length}
										options={violations?.map(v => ({
											key: v.id,
											value: v.id,
											label: v.name
										}))}
										onChange={value => {
											onIssueChange(value, 'violation')
										}}
										dropdownRender={menu => (
											<>
												{menu}
												<Divider style={{ margin: '8px 0' }} />
												<Button
													block
													onClick={() => {
														onChangeGuideType(false)
													}}
												>
													Ручной ввод
												</Button>
											</>
										)}
									/>
								) : (
									<Input.TextArea
										autoSize={{
											minRows: 4
										}}
										value={issueDto.description?.toString()}
										onChange={el =>
											dispatch(
												setDtoValue({
													prop: 'description',
													value: el.target.value
												})
											)
										}
									/>
								)}
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>{issueDto.description}</p>
						)}
					</Descriptions.Item>

					<Descriptions.Item label="Система" span={12}>
						{canEdit &&
							(checkPermissions([PERMISSIONS.ChecklistsProcessingUkKc]) ||
								checkPermissions([PERMISSIONS.ChecklistsTemplatesUkKc])) ? (
							<Form.Item name="systemId">
								<Select
									allowClear
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									onChange={el => onIssueChange(el, 'system')}
									value={issueDto.systemId}
									style={{
										width: '100%',
										textOverflow: 'ellipsis',
										border: 'none'
									}}
									options={checkListTemplatesActiveUkKS?.map(cla => ({
										key: cla.id,
										value: cla.id,
										label: cla.name
									}))}
								/>
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>{issueDto!?.system!?.name}</p>
						)}
					</Descriptions.Item>
					<Descriptions.Item
						label={
							<>
								Описание локации<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
						labelStyle={{
							fontWeight: 'bold'
						}}
					>
						{canEdit ? (
							<Form.Item
								style={{
									width: '100%'
								}}
								name="locationDescription"
								rules={[
									{
										required: true,
										message: 'Описание локации не должно быть пустым'
									}
								]}
							>
								<Input.TextArea
									autoSize={{
										minRows: 3
									}}
									onChange={el =>
										dispatch(
											setDtoValue({
												prop: 'locationDescription',
												value: el.target.value
											})
										)
									}
								/>
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>{issueDto.locationDescription!}</p>
						)}
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<>
								Дата создания
								<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
					>
						<p style={{ padding: '4px', margin: 0 }}>
							{issueDto.createdAt !== null && dayjs(issueDto.createdAt).isValid()
								? dayjs(issueDto.createdAt).format('DD.MM.YYYY')
								: dayjs().format('DD.MM.YYYY')}
						</p>
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<>
								Дата устранения
								{!(
									issueDto.issueTypeId === 'e371fab6-3910-4862-92f6-e01229a27d52' &&
									(!user?.isAdmin || checkPermissions([PERMISSIONS.ChecklistsProcessingUkKc]))
								) && <span style={{ color: 'red' }}>*</span>}
							</>
						}
						span={12}
					>
						{canEdit ? (
							<Form.Item
								name="dueDate"
								rules={[
									{
										required: !(
											issueDto.issueTypeId === 'e371fab6-3910-4862-92f6-e01229a27d52' &&
											(!user?.isAdmin || checkPermissions([PERMISSIONS.ChecklistsProcessingUkKc]))
										),
										message: 'Укажите дату устранения'
									}
								]}
							>
								<DatePicker
									locale={locale}
									disabled={
										issueDto.issueTypeId === 'e371fab6-3910-4862-92f6-e01229a27d52' &&
										!(user?.isAdmin || checkPermissions([PERMISSIONS.IssueEdit]))
									}
									style={{
										width: '100%'
									}}
									format={'DD.MM.YYYY'}
									onChange={onDueDateChange}
									disabledDate={current => {
										return current && current < dayjs().startOf('day')
									}}
								/>
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>
								{issueDto.dueDate !== null && dayjs(issueDto.dueDate).isValid()
									? dayjs(issueDto.dueDate).format('DD.MM.YYYY')
									: '-'}
							</p>
						)}
					</Descriptions.Item>
				</Descriptions>
			</Card>

			<Card className="at-workcard" title="Подрядчик">
				<Descriptions
					layout="horizontal"
					column={12}
					bordered
					className="at-descriptions"
					labelStyle={{
						fontWeight: 'bold',
						width: '20%',
						alignItems: 'center',
						padding: '2px 8px'
					}}
					contentStyle={{
						width: '80%',
						padding: '0px 0px'
					}}
				>
					<Descriptions.Item
						label={
							<>
								Генподрядчик<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
					>
						{canEdit ? (
							<Form.Item
								name="contractorId"
								rules={[
									{
										required: true,
										message: 'Укажите ответственного за устранение'
									}
								]}
							>
								<Select
									allowClear
									showSearch
									optionFilterProp="children"
									value={issueDto.contractorId}
									onChange={el => {
										onIssueChange(el, 'contractor')
									}}
								>
									{contractors.map(contractor => (
										<Option key={contractor.id!} value={contractor.id!}>
											{contractor.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>
								{contractors.find(c => c.id === issueDto.contractorId)?.name! ??
									issue!?.contractor!?.name!}
							</p>
						)}
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<>
								Субподрядчик<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
					>
						{canEdit ? (
							<Form.Item
								name="subcontractorId"
								rules={[
									{
										required: true,
										message: 'Укажите субподрядчика'
									}
								]}
							>
								<Select
									allowClear
									showSearch
									optionFilterProp="children"
									disabled={!issueDto.contractorId?.length}
									value={issueDto.subcontractorId}
									onChange={el => {
										onIssueChange(el, 'subcontractor')
									}}
								>
									{subContractors!?.length > 0 &&
										subContractors!?.map(sc => (
											<Option key={sc?.id!} value={sc?.id!}>
												{sc?.name!}
											</Option>
										))}
								</Select>
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>
								{subContractors!?.find(sub => sub?.id === issueDto.subcontractorId)?.name}
							</p>
						)}
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<>Договор:{canEdit && !isAcceptance && <span style={{ color: 'red' }}>*</span>}</>
						}
						span={12}
					>
						{canEdit ? (
							<Form.Item
								name="contractId"
								rules={[
									{
										required: !isAcceptance,
										message: 'Выберите договор'
									}
								]}
							>
								<Select
									allowClear
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									filterSort={(a, b) =>
										(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
									}
									value={issueDto.contractId}
									onChange={el => onIssueChange(el, 'contract')}
									options={issueContract.map(item => ({
										key: item.id,
										value: item.id,
										label: item.name
									}))}
									disabled={!issueDto.contractorId}
								/>
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>
								{issueContract.find(c => c.id === issueDto.contractId)?.name ?? 'не указан'}
							</p>
						)}
					</Descriptions.Item>
					<Descriptions.Item
						label={
							<>
								Контактное лицо:
								{canEdit && !isAcceptance && <span style={{ color: 'red' }}>*</span>}
							</>
						}
						span={12}
					>
						{canEdit ? (
							<Form.Item
								name="contactPersonId"
								rules={[{ required: !isAcceptance, message: 'Выберите контактное лицо' }]}
							>
								<Select
									allowClear
									showSearch
									value={issueDto.contactPersonId}
									onChange={el => onIssueChange(el, 'contactPerson')}
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									filterSort={(a, b) =>
										(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
									}
									options={contactPersons.map(item => ({
										key: item.id,
										value: item.id,
										label: item.name
									}))}
								/>
							</Form.Item>
						) : (
							<p style={{ padding: '4px', margin: 0 }}>
								{contactPersons.find(c => c.id === issueDto.contactPersonId)?.name ?? 'не указан'}
							</p>
						)}
					</Descriptions.Item>

					{issueDto.id !== null && (
						<>
							<Descriptions.Item
								label="Ответ"
								span={12}
								labelStyle={{
									fontWeight: 'bold'
								}}
							>
								{permissions.canSetToReady &&
									currentStatus!?.includes('ready') &&
									issueInit.issueStatusId !== issueDto.issueStatusId ? (
									<Form.Item
										style={{
											width: '100%'
										}}
										name="response"
										rules={[
											{
												required: currentStatus!?.includes('ready'),
												message: 'Укажите ответ'
											}
										]}
									>
										<Input.TextArea
											autoSize={{
												minRows: 4
											}}
											value={issueDto!?.response!}
											onChange={el =>
												dispatch(
													setDtoValue({
														prop: 'response',
														value: el.target.value
													})
												)
											}
										/>
									</Form.Item>
								) : issueDto.response!?.length > 0 ? (
									<p style={{ paddingLeft: '8px', margin: 0 }}>{issueDto.response!}</p>
								) : (
									<i style={{ paddingLeft: '8px', margin: 0 }}>
										Ответственный за устранение пока не ответил на замечание
									</i>
								)}
							</Descriptions.Item>
						</>
					)}
					{issueDto.checklist?.number !== undefined && (
						<Descriptions.Item
							label="Чек-лист"
							span={12}
							labelStyle={{
								fontWeight: 'bold'
							}}
						>
							<p style={{ paddingLeft: '8px', margin: 0 }}>
								{`${issueDto.checklist?.number} от ${dayjs(issueDto.checklist?.createdAt).format(
									'DD.MM.YYYY'
								)}`}
							</p>
						</Descriptions.Item>
					)}

					{issue !== null && issue!?.cancellationReason.reasonBy !== null && (
						<Descriptions.Item
							label="Отменено"
							span={12}
							labelStyle={{
								fontWeight: 'bold'
							}}
						>
							<p style={{ paddingLeft: '8px', margin: 0 }}>
								{dayjs(issue!?.cancellationReason!?.reasonAt).format('DD.MM.YYYY в HH:mm')}
								{' | '}
								{issue!?.cancellationReason!?.reasonBy!?.name!}
							</p>
						</Descriptions.Item>
					)}
					{(issueDto.issueStatusId ===
						statusList.find(status => status.identityName.includes('cancel'))?.id ||
						issueInit.reasonDescription !== null) && (
							<Descriptions.Item
								label="Причина отмены"
								span={12}
								labelStyle={{
									fontWeight: 'bold'
								}}
							>
								{!disabled &&
									((issueDto.id !== null && permissions.canEdit) ||
										(issueDto.id === null && permissions.canCreate)) &&
									issueInit.issueStatusId !== cancelId ? (
									<Form.Item
										style={{
											width: '100%'
										}}
										name="reasonDescription"
										rules={[
											{
												required: currentStatus!?.includes('cancel'),
												message: 'Укажите причину отмены'
											}
										]}
									>
										<Input.TextArea
											autoSize={{
												minRows: 2
											}}
											onChange={el =>
												dispatch(
													setDtoValue({
														prop: 'reasonDescription',
														value: el.target.value
													})
												)
											}
										/>
									</Form.Item>
								) : (
									<p style={{ padding: '4px', margin: 0 }}>{issueDto!?.reasonDescription!}</p>
								)}
							</Descriptions.Item>
						)}
				</Descriptions>
			</Card>
			{issueDto.id && (
				<>
					<Card className="at-workcard" title="Дополнительная информация">
						<Descriptions
							layout="horizontal"
							column={12}
							bordered
							labelStyle={{
								fontWeight: 'bold',
								width: '20%',
								alignItems: 'center',
								padding: '2px 8px'
							}}
							contentStyle={{
								width: '80%',
								padding: '0px 0px'
							}}
						>
							<Descriptions.Item label="Предписание" span={12}>
								<p style={{ paddingLeft: '8px', margin: 0 }}>
									{issueDto.ordinance?.name ? issueDto.ordinance?.name : issueDto.ordinance?.number}
								</p>
							</Descriptions.Item>
							<Descriptions.Item label="Чек-лист" span={12}>
								<p style={{ paddingLeft: '8px', margin: 0 }}>
									{issueDto.checklist ? issueDto.checklist.number : ''}
								</p>
							</Descriptions.Item>
						</Descriptions>
					</Card>
				</>
			)}
			{!!issueDto!?.comments!?.length && <CommentsList issue={issueDto} />}
		</>
	)
}
