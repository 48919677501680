import { Input, Modal } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAdjustmentState } from 'widgets/adjustment/model/adjustment-state'
import { useEstimatePositionState } from '../../model/estimate-position-state'

interface IProps {}

export const MaterialCommentModal: FC<IProps> = () => {
	const { commentRecord, commentCanEdit, setWorkDataValue } = useEstimatePositionState()
	const { container: containerAdjustment } = useAdjustmentState()
	const [comment, setComment] = useState('')
	const { pathname } = useLocation()
	const materialName = commentRecord?.typeMaterial?.materials.find(
		material => material.id === commentRecord.materialId!
	)?.name
	const onSetComment = () => {
		setWorkDataValue(commentRecord?.id!, 'comment', comment, commentRecord?.workId!)
		onClose()
	}
	const onClose = () => {
		useEstimatePositionState.setState({ commentRecord: undefined, commentCanEdit: false })
	}

	useEffect(() => {
		commentRecord && setComment(commentRecord?.comment!)
	}, [commentRecord])

	return (
		<Modal
			width={768}
			destroyOnClose
			onOk={commentCanEdit ? onSetComment : onClose}
			okButtonProps={{ disabled: !commentRecord?.availableComment }}
			open={!!commentRecord}
			onCancel={onClose}
			centered
			title={
				materialName
					? `П.п.${commentRecord.number} - "${materialName}"`
					: `П.п.${commentRecord?.number} - материал не выбран `
			}
		>
			<Input.TextArea
				disabled={
					!commentCanEdit ||
					!commentRecord?.availableComment ||
					(pathname.includes('adjustment') &&
						commentRecord?.parentId !== undefined &&
						containerAdjustment?.adjustmentId !== null &&
						commentRecord?.parentId !== null)
				}
				value={comment ?? undefined}
				allowClear
				onChange={e => setComment(e.currentTarget.value)}
				autoSize
				showCount
				maxLength={5000}
				placeholder="Комментарий"
				style={{ marginBottom: '1rem', backgroundColor: '#fff !important' }}
				styles={{ textarea: { minHeight: 256 } }}
			/>
		</Modal>
	)
}
