import { DeleteOutlined } from '@ant-design/icons'
import { Button, Popconfirm } from 'antd'
import { memo } from 'react'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	id: string
	sectionId: string
	firstVersion?: boolean
}

export const AdjustmentPositionDelete = memo(function (params: IProps) {
	const { id, sectionId, firstVersion } = params
	const { deleteEstimatePosition } = useAdjustmentState()
	return (
		<Popconfirm
			title="Удалить?"
			description="Вы уверены, что хотите удалить эту позицию сметы?"
			onConfirm={() => deleteEstimatePosition([id], sectionId, id, firstVersion)}
		>
			<Button size="small" type="text" danger icon={<DeleteOutlined />} />
		</Popconfirm>
	)
})
