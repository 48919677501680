import { PlusSquareOutlined } from '@ant-design/icons'
import { useIsFetching, useMutation } from '@tanstack/react-query'
import { Button, Form, Input, Modal, Select } from 'antd'
import { type IWbsCreate } from 'entities/wbs'

import { AxiosError } from 'axios'
import { useAppSelector } from 'hooks/appReduxHook'
import { useAsmTypeSections } from 'hooks/useAsm'
import useDebounce from 'hooks/useDebounce'
import { IErrorDetail } from 'interfaces/IBase'
import { FC, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { WbsApi, useWbsPermits } from '..'
import { useConstructionObjects } from '../model/wbs-queries'

interface IProps {
	show: boolean
	onClose: React.Dispatch<React.SetStateAction<boolean>>
}

export const WbsCreate: FC = () => {
	const [showModal, setShowModal] = useState(false)
	const canEdit = useWbsPermits().canEdit
	return (
		<>
			{canEdit && (
				<Button
					icon={<PlusSquareOutlined />}
					type="primary"
					onClick={() => setShowModal(true)}
					disabled={!canEdit}
				>
					Создать ВОР
				</Button>
			)}
			<WbsCreateModal show={showModal} onClose={setShowModal} />
		</>
	)
}

const WbsCreateModal: FC<IProps> = ({ show, onClose }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const loading = useIsFetching()
	const { data: wbsSectionTypes } = useAsmTypeSections()
	const { data: constructionObjects } = useConstructionObjects()
	const { project } = useAppSelector(state => state.environment)
	const permits = useWbsPermits()
	const [wbsCreateForm] = Form.useForm<IWbsCreate>()
	const wbsCreate = useMutation(async (newWbs: IWbsCreate) => {
		return await WbsApi.addContainer(newWbs)
			.then(data => {
				onModalClose()
				navigate(data.id + location.search)
			})
			.catch((error: AxiosError<IErrorDetail>) =>
				Modal.error({
					zIndex: 999999999999999,
					title: 'Ошибка при создании ВОР',
					content: error.response?.data.detail.split(',')[0],
					width: window.innerWidth - (window.innerWidth * 70) / 100,
					onOk: () => {
						navigate(
							error.response?.data.detail.split(',')[1]?.slice(0, 2) === 'id'
								? `/unmodel/${error.response?.data.detail.split(',')[1]?.slice(3)}`
								: '/unmodel'
						)
					}
				})
			)
	})

	const onModalClose = () => {
		wbsCreateForm.resetFields()
		onClose(false)
	}

	const onCreateContainer = async (data: IWbsCreate) => {
		wbsCreate.mutate({ ...data, projectId: project.id })
	}

	const debounce = useDebounce((deb: string) => console.log('debounce', deb), 1000)

	const handlerTrim = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.trim() === '') {
			wbsCreateForm.setFieldsValue({ name: '' })
		} else {
			wbsCreateForm.setFieldsValue({ name: e.target.value })
		}
	}

	return (
		<Modal
			destroyOnClose={true}
			confirmLoading={Boolean(loading)}
			title="Создать контейнер"
			open={show}
			onCancel={onModalClose}
			okText="Создать"
			onOk={() => wbsCreateForm.submit()}
			okButtonProps={{ htmlType: 'submit', icon: <PlusSquareOutlined /> }}
		>
			<Form layout="vertical" form={wbsCreateForm} name="addContainer" onFinish={onCreateContainer}>
				<Form.Item
					label="Наименование ВОР"
					name="name"
					validateDebounce={1000}
					rules={[
						{
							required: true,
							message: 'Укажите наименование ВОР'
						},
						{
							max: 255,
							message: 'Наименование не может содержать больше 255 символов'
						},
						{ min: 3, message: 'Наименование должно содержать не менее 3 символов' }
					]}
				>
					<Input
						style={{ width: '100%' }}
						placeholder="Укажите наименование ВОР"
						allowClear
						onChange={handlerTrim}
					/>
				</Form.Item>
				<Form.Item
					label="Вид раздела"
					name="typeSectionId"
					rules={[
						{
							required: true,
							message: 'Выберите вид раздела'
						}
					]}
				>
					<Select
						allowClear
						placeholder="Выберите вид раздела"
						showSearch
						style={{ width: '100%' }}
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						filterSort={(a, b) =>
							(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
						}
						options={wbsSectionTypes
							?.filter(c => !c.isDeleted)
							.map(c => ({
								key: c.id,
								label: c.name,
								value: c.id
							}))}
					/>
				</Form.Item>
				<Form.Item
					label="Объект строительства"
					name="constructionObjectId"
					rules={[
						{
							required: true,
							message: 'Выберите объект строительства'
						}
					]}
				>
					<Select
						disabled={!permits.canEdit}
						onSearch={debounce}
						allowClear
						placeholder="Выберите объект строительства"
						showSearch
						style={{ width: '100%' }}
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						filterSort={(a, b) =>
							(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
						}
						options={
							constructionObjects?.map(c => ({
								key: c.id,
								label: c.name,
								value: c.id
							})) ?? []
						}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}
