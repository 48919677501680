import { FilterOutlined, SyncOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, Form, Row, Table, Tooltip } from 'antd'
import Search from 'antd/es/input/Search'
import { ColumnType } from 'antd/es/table'
import ColumnConfig, { IColumns } from 'components/columnConfig'
import { useAppDispatch } from 'hooks/appReduxHook'
import OrdinanceDrawer from 'pages/ordinancePage/ordinanceDrawer'
import { FC, useEffect, useState } from 'react'
import { ResizeCallbackData } from 'react-resizable'
import { useNavigate } from 'react-router-dom'
import { setPageTitle } from 'store/rootSlice'
import { FineCard } from 'widgets/fine-card'
import { useFineState } from 'widgets/fine-card/model/fine-card-state'
import { IFine } from '../model/fine-interface'
import { useOrdinanceFine } from '../model/fine-queries'
import { useOrdinanceFineState } from '../model/fine-state'
import { FineFilters } from './filters/fine-filters'
import { FinePage } from './fine-columns'

export const FineRegistry: FC = () => {
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const conf = localStorage.getItem('fineOrdinancePageColumns')
	const parsedConf: IColumns[] = conf && JSON.parse(conf!)
	const dispatch = useAppDispatch()
	const [showColumns, setShowColumns] = useState<IColumns[]>([])
	const [fineOrdinanceColumns, setFineOrdinanceColumns] = useState(FinePage())
	const [fineForm] = Form.useForm()
	const { data, isFetching } = useOrdinanceFine()
	const {
		setShow,
		filters,
		setOrdinanceFineFilters,
		onPagination,
		openOrdinance,
		showOrdinance,
		selectedOrdinance,
		currentPage,
		totalItems,
		setShowDrawer
	} = useOrdinanceFineState()
	const { setId } = useFineState()
	const initColumns = fineOrdinanceColumns.map((item, index) => ({
		key: index.toString()!,
		title: item.title?.toString()!,
		fixed: typeof item.fixed !== 'undefined'
	}))

	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Штрафы' }))
		if (parsedConf === null || parsedConf!?.length === 0) {
			setShowColumns(initColumns)
			localStorage.setItem('fineOrdinancePageColumns', JSON.stringify(initColumns))
		} else {
			setShowColumns(JSON.parse(conf!))
		}
	}, [])

	const handleResize =
		(index: number, col: ColumnType<IFine>) =>
		(_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
			if (!col.fixed) {
				const minWidth = 200
				const maxWidth = 1000
				let colWidth: number = size.width
				if (size.width <= minWidth) colWidth = minWidth
				else if (size.width >= maxWidth) colWidth = maxWidth
				else colWidth = size.width
				const newColumns = [...fineOrdinanceColumns]
				newColumns[index] = {
					...newColumns[index],
					width: colWidth,
					ellipsis: colWidth < 300 ? true : false
				}
				setFineOrdinanceColumns(newColumns)
			}
		}

	const mergeColumns: any = fineOrdinanceColumns
		.filter(col => showColumns.find(i => i.title === col.title) !== undefined)
		.map((col, index) => ({
			...col,
			onHeaderCell: (column: ColumnType<IFine>) => ({
				width: (column as ColumnType<IFine>).width,
				onResize: handleResize(index, col)
			})
		}))

	const onColumnsConfig = (cols: IColumns[]) => {
		setShowColumns(cols)
	}

	const onSearch = (searchValue: string | undefined) => {
		const filtersFine = { ...filters, search: searchValue! }
		setOrdinanceFineFilters(filtersFine)
		onPagination(1)
	}

	return (
		<>
			<FineCard type="edit" />
			{showOrdinance && selectedOrdinance !== null && (
				<OrdinanceDrawer
					ordinanceId={selectedOrdinance}
					open={showOrdinance}
					onClose={() => {
						openOrdinance(false, null)
					}}
				/>
			)}
			<FineFilters />
			<div id="fine" className="dashboard__block">
				<Row id="fine-controls" justify="space-between" style={{ marginBottom: '1rem' }}>
					<Col>
						<Row gutter={8} justify="start">
							<Col>
								<ColumnConfig
									onChange={onColumnsConfig}
									columns={initColumns}
									localstore={'fineOrdinancePageColumns'}
								/>
							</Col>
							<Col>
								<Tooltip title="Обновить">
									<Button
										size="middle"
										onClick={() => queryClient.invalidateQueries(['ordinanceFine'])}
										icon={<SyncOutlined spin={isFetching} />}
									/>
								</Tooltip>
							</Col>
							<Col>
								<Tooltip title="Фильтры">
									<Button
										onClick={() => setShow(true)}
										icon={<FilterOutlined />}
										type={
											filters === undefined || filters === null
												? 'default'
												: Object.entries(filters!).some(
														x => x[0] !== 'search' && typeof x[1] !== 'undefined'
												  )
												? 'primary'
												: 'default'
										}
									/>
								</Tooltip>
							</Col>
							<Col>
								<Search
									allowClear
									placeholder="Поиск..."
									defaultValue={filters?.search}
									onSearch={value => onSearch(value)}
									style={{ width: 300 }}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<div id="resizable-table">
					<Form form={fineForm} component={false}>
						<Table
							loading={isFetching}
							size="small"
							rowKey={record => record.id}
							pagination={{
								current: currentPage,
								pageSize: 50,
								hideOnSinglePage: false,
								showSizeChanger: false,
								total: totalItems,
								onChange: onPagination
							}}
							onRow={record => ({
								onDoubleClick: () => {
									navigate(`/control/fines/${record.id}`)
									setShowDrawer(true)
									// setId('edit', record.id)
								}
							})}
							bordered
							dataSource={data as any}
							columns={mergeColumns}
							scroll={{ x: '100%', y: '60vh' }}
						/>
					</Form>
				</div>
			</div>
		</>
	)
}
