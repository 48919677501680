import { useQueryClient } from '@tanstack/react-query'
import { Checkbox, Col, Collapse, Form, Input, Modal, Select, Typography, message } from 'antd'
import { AxiosError } from 'axios'
import { useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { IProject } from 'interfaces/IEnvironment'
import { FC, useEffect, useState } from 'react'
import { getProjectList } from 'services/EnvironmentService'
import { IWbsCopyForm, useWbsRegistryState } from '..'
import { useConstructionObjects } from '../model/wbs-queries'

const { Title, Text } = Typography

interface IProps {}

export const WbsCopy: FC<IProps> = props => {
	const [hasError, setHasError] = useState(false)
	const [projects, setProjects] = useState<IProject[]>([])
	const [valueNoteDp, setValueNoteDp] = useState<boolean>(false)
	const [wbsCopyContainerForm] = Form.useForm<IWbsCopyForm>()
	const { containerToCopy, setContainerToCopy, onCopy } = useWbsRegistryState()
	const { project } = useAppSelector(state => state.environment)
	const queryClient = useQueryClient()
	const [showModal, setShowModal] = useState(false)
	const { data: constructionObjects } = useConstructionObjects()

	useEffect(() => {
		if (containerToCopy) {
			wbsCopyContainerForm.setFieldValue('name', `${containerToCopy.name}_копия`)
			wbsCopyContainerForm.setFieldValue('copyNoteDP', valueNoteDp)
			wbsCopyContainerForm.setFieldValue(
				'constructionObjectId',
				containerToCopy.constructionObject?.id
			)
			getProjectList(project.id, '49').then(data => {
				setProjects(data)
				if (data.some(d => d.id === project.id)) {
					wbsCopyContainerForm.setFieldValue('destinationProjectId', project.id)
				}
			})
		}
	}, [containerToCopy])
	const handleFormChange = () => {
		setHasError(
			wbsCopyContainerForm.getFieldsError().filter(({ errors }) => errors.length).length > 0
		)
	}
	const onCopyHandler = async (formData: IWbsCopyForm) => {
		if (showModal) return
		if (containerToCopy) {
			const newFormData = {
				...formData,
				copyNoteDP: wbsCopyContainerForm.getFieldValue('copyNoteDP')
			}
			setShowModal(true)
			await onCopy(containerToCopy.id, newFormData)
				.then(() => {
					queryClient.invalidateQueries(['wbsRegistry'])
					setContainerToCopy(undefined)
					message.success('ВОР успешно скопирован')
					setShowModal(false)
				})
				.catch((err: AxiosError<IErrorDetail>) => {
					Modal.error({
						zIndex: 999999,
						title: 'Ошибка копирования',
						content: err.response?.data.detail,
						centered: true,
						onOk: () => {
							setShowModal(false)
							setContainerToCopy(undefined)
							queryClient.invalidateQueries(['wbsRegistry'])
						}
					})
				})
		}
	}
	return (
		<Modal
			destroyOnClose={true}
			title={
				<Title type="success" level={5}>
					Копирование ВОР
				</Title>
			}
			centered
			open={!!containerToCopy}
			zIndex={1001}
			okText={'Копировать'}
			cancelText="Отмена"
			okButtonProps={{
				disabled: hasError
			}}
			onOk={async () => wbsCopyContainerForm.submit()}
			onCancel={() => setContainerToCopy(undefined)}
		>
			<Form
				form={wbsCopyContainerForm}
				name="wbsCopyContainerForm"
				layout="vertical"
				onFinish={onCopyHandler}
				onFieldsChange={handleFormChange}
			>
				<Form.Item
					shouldUpdate
					name="name"
					label="Введите наименование ВОР"
					rules={[
						{
							required: true,
							message: 'Введите наименование ВОР'
						},
						{ min: 3, message: 'Не менее 3х символов' }
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item name="destinationProjectId" label="Выберите проект:">
					<Select
						showSearch
						placeholder="Не выбран"
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						filterSort={(optionA, optionB) =>
							(optionA?.label ?? '')
								.toLowerCase()
								.localeCompare((optionB?.label ?? '').toLowerCase())
						}
						style={{ width: '100%' }}
						options={projects.map((item, index) => ({
							key: index,
							value: item.id,
							label: item.name
						}))}
					/>
				</Form.Item>
				<Form.Item name="constructionObjectId" label="Выберите объект строительства:">
					<Select
						showSearch
						placeholder="Не выбран"
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						filterSort={(optionA, optionB) =>
							(optionA?.label ?? '')
								.toLowerCase()
								.localeCompare((optionB?.label ?? '').toLowerCase())
						}
						style={{ width: '100%' }}
						options={constructionObjects?.map((item, index) => ({
							key: index,
							value: item.id,
							label: item.name
						}))}
					/>
				</Form.Item>
				<Collapse
					items={[
						{
							key: 'false',
							label: 'Дополнительные параметры',
							children: (
								<>
									<Col>
										<Text type="secondary">Параметры позиции сметы</Text>
									</Col>
									<Form.Item
										name="copyNoteDP"
										valuePropName="checked"
										wrapperCol={{ offset: 1 }}
										style={{ margin: 0 }}
									>
										<Col style={{ marginTop: 5 }}>
											<Checkbox
												// defaultChecked={false}
												value={false}
												onChange={value => {
													wbsCopyContainerForm.setFieldValue('copyNoteDP', value.target.checked)
													setValueNoteDp(value.target.checked)
												}}
											>
												Примечания ДП
											</Checkbox>
										</Col>
									</Form.Item>
								</>
							)
						}
					]}
					bordered={false}
				/>
			</Form>
		</Modal>
	)
}
