import { Button } from 'antd';
import { FC, useState } from 'react';
import { AddNormativeActModal } from './add-normative-act-modal';
import { PlusSquareOutlined } from '@ant-design/icons';

interface IProps { }

export const AddNormativeAct: FC<IProps> = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <AddNormativeActModal show={showModal} onCloseModal={() => setShowModal(false)} />
      <Button icon={<PlusSquareOutlined />} type="primary" onClick={() => setShowModal(true)}>
        Добавить
      </Button>
    </>
  );
};
