import { LoadingOutlined } from '@ant-design/icons'
import { IErrorData } from 'UI/dashboard/EnvironmentConfig'
import History from 'UI/history'
import AttentionModal from 'UI/modals/AttentionModal'
import { Drawer, Form, Modal, Spin, Tabs, UploadFile, message } from 'antd'
import { RcFile } from 'antd/es/upload'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { IssueSource } from 'enums'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import {
	IIssue,
	IIssueComment,
	IIssueCommentDto,
	IIssueDrawerPermissions,
	IIssueOrdinance,
	IIssueStatus,
	IStatusesIds
} from 'interfaces/IIssue'
import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getAppUserPermissions } from 'services/AuthService'
import {
	addComment,
	addIssueAttachments,
	createOrUpdateIssue,
	getContractors,
	getContractsByProject1CId,
	getIssueById,
	getIssueSubTypes,
	getIssueTypes,
	setReadyToInsepct as setReadyToInspect
} from 'services/IssuesService'
import { getBuildingPermitsList } from 'services/OrdinanceService'
import { PERMISSIONS } from 'shared/constants'
import { checkPermissionByProject } from 'shared/helpers'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import {
	setEnvironment,
	setEnvironmentPermissions,
	setEnvironmentProject
} from 'store/environmentSlice'
import { resetDto, setDto, setDtoValue } from 'store/issueSlice'
// import { useIssue } from 'widgets/issue/model/issue-queries'
// import { useIssueState } from 'widgets/issue/model/issue-state'
import { CommentModal } from './IssueComments'
import IssueDrawerExtra from './IssueDrawerExtra'
import { IssueAttachments, IssueDetails } from './IssueTabs'

interface IIssueDrawerProps {
	drawerOpen: boolean
	currentIssue: string | IIssue | null
	onDrawerClose: (show: boolean, mustUpdate: boolean, issueId?: string) => void
	updateList?: () => void
	opacity?: number
	issueSource?: IssueSource
	checklistData?: IReception
	checklistType?: number
	setDrawerVisibleClose?: () => void
	setDrawerVisibleOpen?: () => void
}

interface IReception {
	contractorId?: string
	subContractorId?: string
	templateTitle?: string
	workPackageId?: string
	locationDescription?: string
}

const IssueDrawer: FC<IIssueDrawerProps> = ({
	drawerOpen,
	currentIssue,
	onDrawerClose,
	opacity,
	updateList,
	issueSource,
	checklistData,
	checklistType,
	setDrawerVisibleClose,
	setDrawerVisibleOpen
}) => {
	const checkPermissions = useCheckPermissions()
	const { id: issueId } = useParams()
	const dispatch = useAppDispatch()
	const [isLoading, setIsLoading] = useState(true)
	const [issueForm] = Form.useForm()
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const issueInit = useAppSelector(state => state.issues.init)
	const issueDto = useAppSelector(state => state.issues.dto)
	// const { data } = useIssue(issueId!)
	// const { issueInit, issueDto, setDto } = useIssueState()
	const { user, permissions, project, issueTypes, issueSubTypes, violationTypes } = useAppSelector(
		state => state.environment
	)
	const allCompanies = checkPermissions([PERMISSIONS.AllCompanies])
	const pagePermissions: IIssueDrawerPermissions = {
		canSetToReady: checkPermissions([PERMISSIONS.IssueToReadyForControl]),
		canEdit: checkPermissions([PERMISSIONS.IssueEdit]),
		canCreate: checkPermissions([PERMISSIONS.IssueCreate]),
		attachFiles: checkPermissions([PERMISSIONS.AttachFilesForIssue]),
		allCompanies: checkPermissions([PERMISSIONS.AllCompanies])
	}
	const statusList = useAppSelector(state =>
		state.environment.issueStatuses?.filter(
			status =>
				status.identityName.includes('cancel') ||
				status.identityName.includes('open') ||
				status.identityName.includes('closed') ||
				status.identityName.includes('ready')
		)
	)
	const [activeKey, setActiveKey] = useState('details')
	const [attention, setAttention] = useState(false)
	const [attentionStatus, setAttentionStatus] = useState(false)
	const [filesToUpload, setFilesToUpload] = useState<UploadFile[]>([])
	const [initIssueId, setInitIssueId] = useState<string | null>(null)
	const [initIssue, setInitIssue] = useState<IIssue | null>(null)
	const [initStatus, setInitStatus] = useState<IIssueStatus | null>()
	const [currentStatus, setCurrentStatus] = useState<IIssueStatus | null>()
	const [issueOrdinance, setIssueOrdinance] = useState<IIssueOrdinance | null>(null)

	const statuses: IStatusesIds = {
		ready: statusList!?.find(status => status!?.identityName!.includes('ready'))?.id ?? null,
		closed: statusList!?.find(status => status!?.identityName!.includes('closed'))?.id ?? null,
		cancel: statusList!?.find(status => status!?.identityName!.includes('cancel'))?.id ?? null,
		open: statusList!?.find(status => status!?.identityName!.includes('open'))?.id ?? null
	}

	const disabled =
		(user!?.isAdmin === false && issueInit?.issueStatusId === statuses.closed) || isLoading

	const [issueComments, setIssueComments] = useState<IIssueComment[]>([])

	const [readyModalShow, setReadyModalShow] = useState(false)

	useEffect(() => {
		if (drawerOpen) {
			const initializeFields = async () => {
				dispatch(resetDto({ statusId: statuses.open!, projectId: project?.id }))
				issueForm.resetFields()

				if ((currentIssue as IIssue)!?.id) {
					const dto = await getIssueById((currentIssue as IIssue)!?.id!)
					if (dto) {
						// setDto(dto)
						dispatch(setDto(dto))
					}
				}

				if (!(currentIssue as IIssue)?.id && issueSource === 1 && checklistType === 1) {
					const receptionType = {
						type: issueTypes?.find(t => t.name.includes('Приемка УК'))?.id,
						subType: issueSubTypes?.find(t => t.name.includes('Приемка УК'))?.id,
						vioType: violationTypes?.find(t =>
							violationTypes.find(
								v =>
									v.name.toLowerCase().trim() === checklistData?.templateTitle?.toLowerCase().trim()
							)
								? t.name
										.toLowerCase()
										.trim()
										.includes(checklistData!?.templateTitle!?.toLowerCase().trim())
								: t.name.includes('Приемка УК')
						)?.id
					}

					dispatch(setDtoValue({ prop: 'issueTypeId', value: receptionType.type ?? '' }))
					dispatch(setDtoValue({ prop: 'issueSubTypeId', value: receptionType.subType ?? '' }))
					dispatch(setDtoValue({ prop: 'violationTypeId', value: receptionType.vioType ?? '' }))
					dispatch(setDtoValue({ prop: 'contractorId', value: checklistData?.contractorId ?? '' }))
					dispatch(
						setDtoValue({ prop: 'subcontractorId', value: checklistData?.subContractorId ?? null })
					)
					issueForm.setFieldValue('issueTypeId', receptionType.type)
					issueForm.setFieldValue('issueSubTypeId', receptionType.subType)
					issueForm.setFieldValue('violationTypeId', receptionType.vioType)
					issueForm.setFieldValue('contractorId', checklistData?.contractorId)
					issueForm.setFieldValue('subcontractorId', checklistData?.subContractorId)
				} else if (!(currentIssue as IIssue)?.id && issueSource === 1 && checklistType === 0) {
					dispatch(setDtoValue({ prop: 'contractorId', value: checklistData?.contractorId ?? '' }))
					dispatch(
						setDtoValue({ prop: 'subcontractorId', value: checklistData?.subContractorId ?? null })
					)
					dispatch(
						setDtoValue({ prop: 'workPackageId', value: checklistData?.workPackageId ?? null })
					)
					dispatch(
						setDtoValue({
							prop: 'locationDescription',
							value: checklistData?.locationDescription ?? null
						})
					)

					issueForm.setFieldValue('contractorId', checklistData?.contractorId)
					issueForm.setFieldValue('subcontractorId', checklistData?.subContractorId)
					issueForm.setFieldValue('workPackageId', checklistData?.workPackageId)
					issueForm.setFieldValue('locationDescription', checklistData?.locationDescription)
				}
			}

			initializeFields()
		}
	}, [drawerOpen])

	useEffect(() => {
		setInitStatus(statusList!?.find(status => status!?.id! === issueInit?.issueStatusId))
		setCurrentStatus(statusList!?.find(status => status!?.id! === issueDto?.issueStatusId))
	}, [issueDto?.issueStatusId])

	useEffect(() => {
		if (drawerOpen) {
			if (typeof currentIssue! === 'string') {
				setInitIssueId(currentIssue)
			} else {
				setInitIssue(currentIssue!)
				setIsLoading(false)
			}
		}
	}, [drawerOpen, permissions])

	useEffect(() => {
		const initIssue = async () => {
			if (issueId && pathname.includes('issues/')) {
				await getIssueById(issueId)
					.then(dto => {
						if (
							(!allCompanies &&
								(dto ? dto.contractor.id : issueDto?.contractorId) !== user?.contractorId &&
								(dto ? dto.subcontractor.id : issueDto?.subcontractorId) !== user?.contractorId) ||
							!user?.projects?.find(p => p.id === dto.projectId)
						) {
							setDrawerVisibleClose!()
							Modal.warning({
								title: 'Внимание',
								content:
									'Замечание не доступно по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
								onOk: () => {
									navigate('/')
									setDrawerVisibleClose!()
								}
							})
						} else {
							setDrawerVisibleOpen!()
						}
					})
					.catch(() => {
						setDrawerVisibleClose!()
						Modal.warning({
							title: 'Внимание',
							content:
								'Замечание не доступно по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
							onOk: () => navigate('/')
						})
					})
			}
		}
		initIssue()
	}, [issueId])

	useEffect(() => {
		const initIssue = async (id?: string) => {
			if (id) {
				const dto = await getIssueById(id)
				const userProject = user?.projects?.find(p => p.id === dto.projectId)
				if (project.id !== dto.projectId && userProject) {
					await getAppUserPermissions(userProject.id).then(data => {
						dispatch(setEnvironmentPermissions(data))
					})
					dispatch(
						setEnvironmentProject({
							option: 'project',
							data: userProject!
						})
					)
					await getContractors().then(data => {
						dispatch(setEnvironment({ state: 'contractors', data }))
					})
					await getIssueTypes()
						.then(data => {
							dispatch(setEnvironment({ state: 'issueTypes', data }))
						})
						.catch((error: AxiosError) => {
							const { detail } = error!?.response!?.data! as IErrorData
							const { url } = error!?.config!
							message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
							return Promise.reject
						})
					await getIssueSubTypes()
						.then(data => {
							dispatch(setEnvironment({ state: 'issueSubTypes', data }))
						})
						.catch((error: AxiosError) => {
							const { detail } = error!?.response!?.data! as IErrorData
							const { url } = error!?.config!
							message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
							return Promise.reject
						})
					await getContractsByProject1CId(userProject!?.project1C?.id!).then(data => {
						dispatch(setEnvironment({ state: 'contracts', data }))
					})
					await getBuildingPermitsList(userProject!?.project1C?.id!).then(data => {
						dispatch(setEnvironment({ state: 'buildingPermits', data }))
					})
				}
				if (dto && user?.projects?.find(p => p.id === dto.projectId)) {
					setIssueOrdinance(dto.ordinance ?? null)
					// setDto(dto)
					dispatch(setDto(dto))
				}
			}
		}
		if (((initIssueId || issueId) && pathname.includes('issues/')) || initIssueId) {
			const currentId = initIssueId ?? issueId
			initIssue(currentId)
		} else {
			dispatch(resetDto({ statusId: statuses!?.open!, projectId: project!?.id! }))
			issueForm.resetFields()
			setIsLoading(false)
		}
	}, [initIssueId, issueId])

	useEffect(() => {
		if (issueDto?.id !== null) {
			issueForm.setFieldsValue({
				...issueDto,
				dueDate:
					issueDto?.dueDate !== null && dayjs(issueDto?.dueDate).isValid()
						? dayjs(issueDto?.dueDate)
						: null
			})
			setTimeout(() => setIsLoading(false), 200)
		}
	}, [issueDto?.id])

	const onTabChange = (key: string) => {
		setActiveKey(key)
	}

	const checkEqual = () => {
		if (!_.isEqual(issueInit, issueDto)) {
			setAttention(true)
		} else {
			onCancel()
			// updateList!()
		}
	}

	const onCancel = (mustUpdate: boolean = false, issueId?: string) => {
		if (!isLoading) {
			if (initIssue !== null) setInitIssue(null)
			setIssueOrdinance(null)
			setInitIssueId(null)
			setFilesToUpload([])
			setActiveKey('details')
			onDrawerClose(false, mustUpdate, issueId)
			setAttention(false)
			setTimeout(() => {
				issueForm.resetFields()
				dispatch(resetDto({ statusId: statuses!?.open!, projectId: project!?.id! }))
				setIsLoading(true)
			}, 200)
		}
	}

	const switchStatusClass = (status: string) => {
		const statusName = statusList!?.find(s => s.id === status)?.identityName!

		switch (true) {
			case statusName!?.includes('open'):
				return 'at-issue-drawer--open'
			case statusName!?.includes('ready'):
				return 'at-issue-drawer--inspect'
			case statusName!?.includes('approved'):
			case statusName!?.includes('cancel'):
				return 'at-issue-drawer--danger'
			case statusName!?.includes('completed'):
				return 'at-issue-drawer--completed'
			default:
				return 'at-issue-drawer--void'
		}
	}

	const [modalProps, setModalProps] = useState({
		title: 'Внимание!',
		description: 'Не сохранённые данные будут утеряны',
		onOk: () => onCancel(),
		onCancel: () => setAttentionStatus(false)
	})

	const onBeforeSave = () => {
		if (issueDto?.ordinance !== null && !_.isEqual(issueInit, issueDto)) {
			setModalProps({
				title: 'Внимание!',
				description:
					'По данному замечанию уже создано предписание! Внесённые изменения не попадут в печатную форму предписания. Сохранить?',
				onOk: () => onSave(),
				onCancel: () => setAttentionStatus(false)
			})
			setAttentionStatus(true)
		} else {
			onSave()
		}
	}

	const updateDto = async (id: string) => {
		await getIssueById(id)
			.then(data => {
				setIssueComments(data!?.comments!)
				// setDto(data!)
				dispatch(setDto(data))
			})
			.finally(() => setIsLoading(false))
	}

	const onSave = async (withComment: boolean = false, commentDto?: Partial<IIssueCommentDto>) => {

		setIsLoading(true)
		setAttentionStatus(false)
		if (issueDto?.autodeskId === null) {
			let issueId: string | null = null
			if (withComment) {
				if (
					initStatus?.identityName.includes('open') &&
					currentStatus!?.identityName.includes('ready')
				) {
					await setReadyToInspect(issueDto?.id!, commentDto!?.comment!)
						.then(() => {
							updateDto(issueDto?.id!)
							updateList!()
							message.success('Замечание отправлено на проверку')
						})
						.catch(error => message.error(error.message))
						.finally(() => setIsLoading(false))
				} else {
					await createOrUpdateIssue(issueDto!, 'patch')
						.then((response) => {
							updateDto(issueDto?.id!)
							updateList!()
							const { ordinanceId, ordinanceFineId } = response.data

							if (ordinanceId && !ordinanceFineId) {
								message.success('По замечанию сформировано предписание')
							} else if (!ordinanceId && ordinanceFineId) {
								message.success('По связанному предписанию создан штраф')
							}
						})
						.catch(error => message.error(error.message))
						.finally(() => setIsLoading(false))
				}
			} else {
				await createOrUpdateIssue(issueDto!, issueDto?.id === null ? 'post' : 'patch', issueSource)
					.then(response => {
						if (response.status === 200) {
							issueId = response.data.id
							onCancel(true, response.data.id)
							updateList!()
							message.success('Замечание успешно сохранено')
						}
					})
					.catch((error: AxiosError<IErrorDetail>) =>
						Modal.error({
							content: error.response?.data.detail ?? 'Произошла ошибка при сохранении'
						})
					)
					.finally(() => setIsLoading(false))

				if (filesToUpload.length > 0 && pagePermissions.attachFiles) {
					const currentIssueId = issueDto?.id === null ? issueId! : issueDto?.id
					filesToUpload.forEach(file => {
						const config = {
							headers: { 'content-type': 'multipart/form-data' }
						}
						const fmData = new FormData()
						fmData.append('file', file.originFileObj!)
						addIssueAttachments(currentIssueId!, fmData, config).then(status =>
							status === 200 ? console.log('Ok') : false
						)
					})
				}
			}
		} else {
			onCancel()
		}
	}

	const onFailedIssue = () => {
		setActiveKey('details')
		const errors = issueForm.getFieldsError().filter(err => err.errors.length !== 0)
		errors.flatMap(err => message.error(err.errors))
		issueForm.scrollToField([errors[0].name.toString()])
	}

	useEffect(() => {
		const initStatus = statusList!?.find(status => status.id === issueInit?.issueStatusId)
		if (
			(initStatus?.identityName.includes('open') &&
				currentStatus!?.identityName.includes('ready')) ||
			(initStatus?.identityName.includes('ready') && currentStatus!?.identityName.includes('open'))
		) {
			setReadyModalShow(true)
		}
	}, [currentStatus])

	const onReadyModalConfirm = async (confirmDto: Partial<IIssueCommentDto>) => {
		const formData = new FormData()
		formData.append('issueId', issueDto?.id!)
		formData.append('comment', confirmDto.comment!)
		formData.append('status', currentStatus!?.identityName!)
		confirmDto!?.files!?.map(file => formData.append('files', file.originFileObj as RcFile))

		await addComment(formData)
			.then(res => {
				onSave(true, confirmDto)
			})
			.catch(e => console.log(e))

		setReadyModalShow(false)
	}

	const onReadyModalCancel = () => {
		dispatch(setDtoValue({ prop: 'issueStatusId', value: issueInit?.issueStatusId }))
		setReadyModalShow(false)
	}

	useEffect(() => {
		if (issueDto && issueDto?.id && pathname.includes('issues/')) {
			checkPermissionByProject(
				Object.keys(PERMISSIONS).findIndex(p => p === 'IssueView') + 1,
				issueDto?.projectId
			).then(validate => {
				if (!validate) {
					setDrawerVisibleClose!()
					Modal.warning({
						title: 'Внимание',
						content:
							'Замечание не доступно по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
						onOk: () => {
							navigate('/')
							setDrawerVisibleClose!()
						}
					})
				} else {
					setDrawerVisibleOpen!()
				}
			})
		}
	}, [issueDto])

	return (
		<>
			<CommentModal
				statuses={{ init: initStatus!?.identityName!, current: currentStatus!?.identityName! }}
				open={readyModalShow}
				form={issueForm}
				onConfirm={onReadyModalConfirm}
				onCancel={onReadyModalCancel}
				attachments={issueDto?.attachments!}
				filesToUpload={filesToUpload}
			/>
			<AttentionModal
				title={'Внимание!'}
				description={'Не сохранённые данные будут утеряны'}
				opened={attention}
				onOk={onCancel}
				onCancel={() => setAttention(false)}
			/>
			<AttentionModal {...modalProps} opened={attentionStatus} />
			<Drawer
				open={drawerOpen}
				destroyOnClose={true}
				onClose={() => checkEqual()}
				width={window.outerWidth < 1300 ? '75vw' : '50vw'}
				className={'at-issue-drawer ' + switchStatusClass(issueDto?.issueStatusId!)}
				bodyStyle={{ background: '#fafafa', padding: 0 }}
				maskStyle={{ opacity: opacity }}
				extra={
					<IssueDrawerExtra
						isLoading={isLoading}
						user={user}
						statusList={statusList!}
						permissions={pagePermissions}
						statuses={statuses}
						disabled={disabled}
						issueForm={issueForm}
						filesToUpload={filesToUpload!}
						attachments={issueDto?.attachments!}
					/>
				}
				title={
					!isLoading
						? issueDto?.id !== null
							? `Замечание №${issueDto?.number} от ${dayjs(issueDto?.createdAt).format(
									'DD.MM.YYYY'
							  )}`
							: 'Новое замечание'
						: 'Загрузка'
				}
			>
				{isLoading && (
					<div
						style={{
							zIndex: 9999,
							top: 0,
							right: 0,
							position: 'absolute',
							display: 'flex',
							width: '100%',
							height: '100%',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: 'rgba(44,44,44,0.2)',
							backdropFilter: 'blur(4px)'
						}}
					>
						<Spin
							indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
							style={{ color: 'gray' }}
						/>
					</div>
				)}
				<Form
					labelAlign="left"
					labelCol={{ span: 4 }}
					name="issueForm"
					form={issueForm}
					onFinish={() => onBeforeSave()}
					onFinishFailed={onFailedIssue}
				>
					<Tabs
						className="at-tabs-sticky"
						activeKey={activeKey}
						size="large"
						tabBarGutter={16}
						onChange={onTabChange}
						items={[
							{
								label: 'Детали',
								key: 'details',
								children: (
									<IssueDetails
										drawerOpen={drawerOpen}
										issue={initIssue}
										issueForm={issueForm}
										statusList={statusList!}
										permissions={pagePermissions}
										disabled={disabled}
										filesToUpload={filesToUpload}
										setFilesToUpload={setFilesToUpload}
										issueComments={issueComments}
										issueSource={issueSource}
									/>
								)
							},
							{
								label: 'Файлы',
								key: 'attachments',
								children: (
									<IssueAttachments
										issueForm={issueForm}
										issueId={issueDto?.id!}
										filesToUpload={filesToUpload}
										setFilesToUpload={setFilesToUpload}
										permissions={pagePermissions}
										disabled={disabled}
										attachments={issueDto?.attachments!}
									/>
								)
							},
							{
								label: 'История',
								key: 'activity',
								disabled: !checkPermissions([PERMISSIONS.IssueView]) || issueDto?.id === null,
								children: (
									<History
										id={
											typeof currentIssue === 'object'
												? issueId
												: // ? (currentIssue as IIssue)!?.id!
												  currentIssue!
										}
										target={'issue'}
									/>
								)
							}
						]}
					/>
				</Form>
			</Drawer>
		</>
	)
}
export default IssueDrawer
