export enum AdjustmentCol {
	SduPriceService,
	SduPriceMaterial,
	EstimatePositionAdjustmentComment,
	EstimatePositionAdjustmentJustification,
	EstimatePositionAdjustmentMaterialComment,
	EstimatePositionAdjustmentMaterialJustification,
	PriceOuterService,
	PriceOuterMaterial,
}
