import { arrayMove } from '@dnd-kit/sortable'
import { CellContext, ExpandedState, Row, RowSelectionState } from '@tanstack/react-table'
import { Modal, message } from 'antd'
import modal from 'antd/es/modal'
import { AxiosError } from 'axios'
import { IColumns } from 'components/columnConfig'
import { IErrorDetail } from 'interfaces/IBase'
import { IEstimatePositionDto } from 'interfaces/IEstimatePosition'
import lodash from 'lodash'
import { all, create as mathCreate } from 'mathjs'
import { INonActualPosition, IWbsContainer } from 'pages/unmodelPage/wbsContainer'
import { ROUND_VALUE } from 'shared/constants'
import { findNestedObj } from 'shared/helpers'
import { EstimateApi } from 'widgets/estimate/api/estimate-api'
import { create } from 'zustand'
import {
	IAdjustmentPosition,
	IAdjustmentPositionMaterial,
	IAdjustmentPositionOrder,
	IAdjustmentPositionSduUpdate,
	IAdjustmentSection,
	TCellType
} from '..'
import { adjustmentApi } from '../api/adjustment-api'
import { AdjustmentCol } from './adjustment-helper'
const config = {}
const math = mathCreate(all, config)

interface INote {
	type: 'commentSdu' | 'justification'
	isWork: boolean
	value: string
	name: string
	disabled?: boolean
}

interface IState {
	showHistory: boolean
	showContainerCommentsDrawer: boolean
	containerType: 'wbs' | 'local'
	container?: IWbsContainer
	nonActualPositions: INonActualPosition[]
	expandedRows: ExpandedState
	isLoading: boolean
	sections: IAdjustmentSection[]
	sectionPositions: {
		sectionId: string
		positions: IAdjustmentPosition[]
	}[]
	selectedRows: { parentId?: string; rows: RowSelectionState }
	note?: {
		row: IAdjustmentPosition | IAdjustmentPositionMaterial
		main: INote
	}
	update: boolean
	columns: IColumns[]
	showColumns: IColumns[]
	scrollToNewElement?: string
	showRemoveModal: boolean
	toRemove?: RowSelectionState
	removeSectionId: string | null
	canDelete: boolean
	isImportSdu: boolean
}

interface IStateActions {
	setShowHistory: (state: boolean) => void
	setShowColumns: (columns: IColumns[]) => void
	setColumns: (columns: IColumns[]) => void
	changePosition: (sectionId: string, itemIds: string[], direction: 'up' | 'down') => void
	copyEstimatePosition: (ids: string[], sectionId: string) => void
	deleteEstimatePosition: (
		ids: string[],
		sectionId: string,
		id?: string,
		firstVersion?: boolean
	) => void
	setContainer: (container: IWbsContainer | undefined) => void
	setEstimatePositions: (
		sectionId: string,
		parents?: Row<IAdjustmentSection | IAdjustmentPosition | IAdjustmentPositionMaterial>[] | null,
		changeExpandedRows?: boolean
	) => void
	setExpandedRows: (rows: ExpandedState) => void
	expandAllChildren: (row: Row<IAdjustmentSection>) => void
	setSelectedRows: (row: Row<IAdjustmentPosition>) => void
	selectAllChildren: (row: Row<IAdjustmentSection>) => void
	setSections: (sections: IAdjustmentSection[]) => void
	setNote: (
		data: IAdjustmentPosition | IAdjustmentPositionMaterial,
		type: 'commentSdu' | 'justification',
		disabled?: boolean
	) => void
	unSetNote: () => void
	onSavePosition: (
		value: number | boolean,
		cellType: TCellType,
		record:
			| CellContext<IAdjustmentPosition | IAdjustmentPositionMaterial, any>
			| CellContext<IAdjustmentPosition, any>
			| CellContext<IAdjustmentPositionMaterial, any>,
		parents: Row<IAdjustmentSection | IAdjustmentPosition | IAdjustmentPositionMaterial>[],
		permission?: 'AdjustmentChangePrice' | 'AdjustmentChangePriceNominated'
	) => void
	updateSections: (sectionId: string, newPosition?: string) => void
	setContainerType: (containerType: 'wbs' | 'local') => void
	closedEstimatePositionAdjustment: (id: string, sectionId: string, isClosed: boolean) => void
	// changeReady: () => Promise<number | undefined>
	// setNonActualPositions: (positions: INonActualPosition[]) => void
}

const initialState: IState = {
	showHistory: false,
	showContainerCommentsDrawer: false,
	containerType: 'wbs' as const,
	expandedRows: {},
	nonActualPositions: [],
	isLoading: false,
	sections: [],
	sectionPositions: [],
	selectedRows: { rows: {} },
	update: false,
	columns: [],
	showColumns: [],
	showRemoveModal: false,
	toRemove: undefined,
	removeSectionId: null,
	canDelete: true,
	isImportSdu: false
}

export const useAdjustmentState = create<IState & IStateActions>((set, get) => ({
	...initialState,
	setShowHistory: state => set(() => ({ showHistory: state })),

	setContainerType: containerType => set(() => ({ containerType })),

	setExpandedRows: rows => {
		set(() => ({ expandedRows: rows }))
	},

	expandAllChildren: row => {
		if (row.subRows.some(r => r.getIsExpanded())) {
			row.subRows.filter(r => r.getCanExpand()).map(r => r.toggleExpanded(false))
		} else {
			row.subRows.filter(r => r.getCanExpand()).map(r => r.toggleExpanded(true))
		}
	},

	setSelectedRows: row => {
		const currentSelected = get().selectedRows
		if (currentSelected.parentId !== row.parentId) {
			set(() => ({ selectedRows: { parentId: row.parentId, rows: { [row.id]: true } } }))
		} else {
			if (Object.keys(currentSelected.rows).includes(row.id)) {
				const { [row.id]: _, ...filtered } = currentSelected.rows
				set(() => ({
					selectedRows: {
						parentId: row.parentId,
						rows: filtered
					}
				}))
			} else {
				set(() => ({
					selectedRows: {
						parentId: row.parentId,
						rows: Object.assign(currentSelected.rows, { [row.id]: true })
					}
				}))
			}
		}
	},

	selectAllChildren: row => {
		if (row.getCanSelect() && row.subRows.length) {
			if (row.getIsSomeSelected()) {
				set(() => ({ selectedRows: { parentId: undefined, rows: {} } }))
			} else {
				for (const childRow of row.subRows) {
					if ('workName' in childRow.original) get().setSelectedRows(childRow as any)
				}
			}
		}
	},

	setContainer: container => {
		if (get().container?.id !== container?.id) {
			set(() => ({ ...initialState }))
		}
		set(() => ({ container }))
	},

	setSections: sections => {
		set(() => ({ sections }))
	},

	setEstimatePositions: async (sectionId, parents, changeExpandedRows = true) => {
		const containerId = get().container?.id
		const containerType = get().containerType
		const rowParents = parents?.flatMap(p => p.original.id) ?? []
		if (containerId) {
			set(() => ({ isLoading: true }))
			const estimatePositions = await adjustmentApi
				.getSectionPositions(containerId, sectionId, containerType)
				.then(data => data)
				.catch((error: AxiosError<IErrorDetail>) => {
					modal.error({ title: 'Ошибка получения данных', content: error.response?.data.detail })
					set(() => ({ isLoading: false }))
				})

			if (!(estimatePositions instanceof AxiosError)) {
				if (Array.isArray(estimatePositions)) {
					if (changeExpandedRows) {
						let prep: Record<string, boolean> = {}
						estimatePositions.filter(ep => ep.children.length).map(p => (prep[p.id] = true))
						get().setExpandedRows(Object.assign(get().expandedRows, prep))
					}
				}
				let sections: IAdjustmentSection[] = []

				const cloneDeep = (section: IAdjustmentSection, parents: string[]): IAdjustmentSection => {
					if (section.id === sectionId) {
						return { ...section, children: estimatePositions as IAdjustmentPosition[] }
					}

					if (
						parents.some(p => p.includes(section.id)) &&
						'codifier' in section &&
						section.children
					) {
						return {
							...section,
							children: section?.children.map(s => cloneDeep(s as IAdjustmentSection, parents))
						}
					}

					return section
				}

				if (parents?.length) {
					sections = get().sections.map(s => cloneDeep(s, rowParents))
				} else
					sections = lodash.cloneDeepWith(get().sections, (value: IAdjustmentSection) => {
						return value && value.id === sectionId
							? {
									...value,
									children: (estimatePositions as IAdjustmentPosition[])
										.sort((a, b) => a.order - b.order)
										.map(item => ({
											...item
										}))
							  }
							: lodash.noop()
					})

				set(() => ({ sections, isLoading: false }))
				return estimatePositions
			}
		}
		return []
	},

	updateSections: async (sectionId, newPosition) => {
		set(() => ({ isLoading: true }))
		const container = get().container
		const containerType = get().containerType
		const expandedRows = get().expandedRows
		if (container) {
			// if (container.status.name === 'New')
			await adjustmentApi.get(container.id, containerType).then(data => get().setContainer(data))

			const newSections = await adjustmentApi
				.getSections(container.id, containerType)
				.then(data => data)

			const estimatePositions = await adjustmentApi
				.getSectionPositions(container.id, sectionId, containerType)
				.then(data => data)

			if (newSections && estimatePositions) {
				const currentSections = get().sections

				const getNestedPositions = (
					sectionId: string,
					sections: IAdjustmentSection[]
				): IAdjustmentPosition[] | undefined => {
					let foundObj = {}
					JSON.stringify(sections, (_, nestedValue: IAdjustmentSection) => {
						if (nestedValue && nestedValue['id'] === sectionId) {
							foundObj = nestedValue
						}
						return nestedValue
					})
					return (
						((foundObj as never as IAdjustmentSection)?.children as IAdjustmentPosition[]) ??
						undefined
					)
				}

				const dataMap = (section: IAdjustmentSection): IAdjustmentSection => {
					return {
						...section,
						children:
							'codifier' in section && section.children
								? section.children.map(s => dataMap(s as IAdjustmentSection))
								: section.id === sectionId
								? estimatePositions
								: getNestedPositions(section.id, currentSections)
					}
				}

				const data =
					currentSections && !!currentSections.length
						? newSections.map(section => dataMap(section))
						: newSections

				if (!Object.keys(expandedRows).includes(sectionId)) {
					get().setExpandedRows(Object.assign(get().expandedRows, { [sectionId]: true }))
				}
				set(() => ({ sections: data, isLoading: false }))
				if (newPosition) {
					set(() => ({ scrollToNewElement: newPosition }))
				}
			}
			// set(() => ({ isLoading: false }))
		}
	},

	changePosition: async (sectionId, itemIds, direction) => {
		const estimatePositions: IAdjustmentPosition[] =
			findNestedObj<any>(get().sections!, 'id', sectionId)?.children ?? []
		if (estimatePositions) {
			let ordersSet: IAdjustmentPositionOrder[] = []
			let moved = estimatePositions

			const sortedIds = [...itemIds].sort((a, b) => {
				const indexA = estimatePositions.findIndex(i => i.id === a)
				const indexB = estimatePositions.findIndex(i => i.id === b)
				return direction === 'down' ? indexB - indexA : indexA - indexB
			})

			sortedIds.forEach(id => {
				const item = estimatePositions.find(i => i.id === id)
				const currentIndex = estimatePositions.findIndex(i => i.id === id)
				const nearbyIndex = estimatePositions.findIndex(
					el =>
						'workName' in el &&
						el.order === (direction === 'down' ? item!?.order + 1 : item!?.order - 1)
				)

				if (nearbyIndex !== -1) {
					moved = arrayMove(moved, currentIndex, nearbyIndex)
					moved.map((item, index) =>
						ordersSet.push({
							estimatePostionId: item.id,
							orderPosition: index + 1
						})
					)
				}
			})

			const lastOrderIndex = lodash.findLastIndex(ordersSet, { orderPosition: 1 })
			const orderSlice = ordersSet.slice(lastOrderIndex)
			if (orderSlice.length > 0) {
				set(() => ({ isLoading: true }))
				await EstimateApi.changeOrder(orderSlice, true)
					.then(data => {
						if (data.status === 200) {
							get().setEstimatePositions(sectionId, null, false)
							message.success({ content: 'Порядковый(е) номер(а) успешно изменен(ы)' })
						}
					})
					.catch((err: AxiosError<IErrorDetail>) => {
						Modal.error({
							title: 'Ошибка',
							content:
								err.response?.data?.detail ??
								`Обратитесь к администратору. Код ошибки: ${err.status}`
						})
						set(() => ({ isLoading: false }))
					})
			}
		}
	},

	copyEstimatePosition: async (ids, sectionId) => {
		set(() => ({ isLoading: true }))
		await adjustmentApi
			.copyEstimatePosition(ids)
			.then(res => {
				const x = res?.reduce((acc: any, key: any) => {
					acc[key] = true
					return acc
				}, {})
				get().updateSections(sectionId)
				get().setExpandedRows(Object.assign(get().expandedRows, x))
				message.success({ content: 'Позиция(и) сметы успешно скопирована(ы)' })
			})
			.catch(() => message.error({ content: 'Во время копирования произошла ошибка' }))
	},

	deleteEstimatePosition: async (ids, sectionId, id, firstVersion) => {
		set(() => ({ isLoading: true }))
		await (firstVersion
			? adjustmentApi.deleteForClosedEstimatePosition(id!)
			: adjustmentApi.deleteEstimatePosition(ids)
		)
			.then(() => {
				get().updateSections(sectionId)
				message.success({ content: 'Позиция(и) сметы успешно удалена(ы)' })
			})
			.catch(() => message.error({ content: 'Во время удаления произошла ошибка' }))
	},

	setNote: (data, type, disabled) =>
		set(() => ({
			note: {
				row: data,
				main: {
					type: type,
					isWork: 'workName' in data,
					value: data[type] ?? '',
					name: 'workName' in data ? data.workName : data.materialName,
					disabled
				}
			}
		})),

	unSetNote: () => set(() => ({ note: undefined })),

	setColumns: columns => set(() => ({ columns })),

	setShowColumns: cols => {
		set(() => ({ showColumns: [...get().columns.filter(col => col.fixed === true), ...cols] }))
	},

	onSavePosition: async (value, cellType, record, parents, permission) => {
		set(() => ({ isLoading: true }))
		const container = get().container
		const sections = get().sections
		const row: IAdjustmentPosition | IAdjustmentPositionMaterial = record.row.original
		const parent = 'materialName' in row ? record.row.getParentRow() : undefined
		if (container && sections) {
			const numberValidate = /^[0-9.]*$/
			const isExpandable = row?.isExpandable ?? false
			const wbsPosition: IAdjustmentPosition =
				(parent?.original as IAdjustmentPosition) ?? (row as IAdjustmentPosition)

			const dataRow = {
				estimatePositionId: 'workName' in row ? row.id : row.estimatePositionId,
				currentId: row.id,
				[cellType]: value
			}

			if (container && wbsPosition && 'technologyId' in wbsPosition) {
				if (cellType.includes('Sdu')) {
					const dto: IAdjustmentPositionSduUpdate = {
						estimatePositionId: wbsPosition.id,
						estimatePositionMaterialId:
							cellType === 'priceMaterialSdu' && wbsPosition.id !== row.id ? row.id : undefined,
						estimatePositionAdjustmentProps:
							cellType === 'priceServiceSdu'
								? AdjustmentCol.SduPriceService
								: AdjustmentCol.SduPriceMaterial,
						value: value as number
					}
					await (permission === 'AdjustmentChangePrice'
						? adjustmentApi.saveStatementSdu
						: adjustmentApi.saveStatementSduNominated)(dto)
						.then(() => get().updateSections(wbsPosition.containerSectionId))
						.then(() => message.success('Данные обновлены'))
						.catch((err: AxiosError<IErrorDetail>) => {
							Modal.error({
								title: 'Ошибка при сохранении данных',
								content: err.response?.data.detail,
								okText: 'Обновить',
								onOk: () => get().setEstimatePositions(row.containerSectionId, parents)
							})
						})
						.finally(() => set(() => ({ isLoading: false })))
				} else if (
					cellType.includes('priceMaterialSecond') ||
					cellType.includes('priceServiceSecond')
				) {
					const dto: IAdjustmentPositionSduUpdate = {
						estimatePositionId: wbsPosition.id,
						estimatePositionMaterialId:
							cellType === 'priceMaterialSecond' && wbsPosition.id !== row.id ? row.id : undefined,
						estimatePositionAdjustmentProps:
							cellType === 'priceServiceSecond'
								? AdjustmentCol.PriceOuterService
								: AdjustmentCol.PriceOuterMaterial,
						value: value as number
					}
					await adjustmentApi
						.savePriceSecond(dto)
						.then(() => get().updateSections(wbsPosition.containerSectionId))
						.then(() => message.success('Данные обновлены'))
						.catch((err: AxiosError<IErrorDetail>) => {
							Modal.error({
								title: 'Ошибка при сохранении данных',
								content: err.response?.data.detail,
								okText: 'Обновить',
								onOk: () => get().setEstimatePositions(row.containerSectionId, parents)
							})
						})
						.finally(() => set(() => ({ isLoading: false })))
				} else {
					const dto: IEstimatePositionDto = {
						id: wbsPosition.id,
						containerId: container.id,
						containerSectionId: wbsPosition.containerSectionId,
						technologyId: wbsPosition.technologyId,
						version: wbsPosition.versionId,
						properties: wbsPosition.properties?.map(property => ({
							id: property.objectPropertyId,
							identityName: property.identityName,
							name: property.name,
							propertyId: property.additionalPropertyId,
							value:
								cellType === 'amountSecond' &&
								!isExpandable &&
								wbsPosition.formula.match(/^\[[^^[\]]+\]$/gim)![0].slice(1, -1) === property.name &&
								+dataRow[cellType] !== 0 &&
								dataRow[cellType] !== '' &&
								dataRow[cellType] !== null &&
								numberValidate.test(String(dataRow[cellType]!))
									? math.round(+dataRow[cellType], ROUND_VALUE).toString()
									: property.value,
							valueType: property.valueType
						})),
						works: [
							{
								id: wbsPosition.estimatePositionTechnologyWorkId,
								order: wbsPosition.order,
								workId: wbsPosition.workId,
								norma: wbsPosition.rate!,
								measureUnitId: wbsPosition.measureUnit.id,
								formula: wbsPosition.formula,
								amount:
									cellType === 'amountSecond' && isExpandable && 'workName' in row
										? math.round(+dataRow[cellType], ROUND_VALUE)
										: wbsPosition.amountSecond!,
								noteSDU: wbsPosition.noteSDU,
								noteDZ: wbsPosition.noteDZ,
								noteDP: wbsPosition.noteDP,
								priceMaterial:
									cellType === 'priceMaterialSecond' && !!wbsPosition.children?.length === false
										? math.round(+dataRow[cellType], ROUND_VALUE)
										: wbsPosition.priceMaterialSecond ?? 0,
								priceService:
									cellType === 'priceServiceSecond'
										? math.round(+dataRow[cellType], ROUND_VALUE)
										: wbsPosition.priceServiceSecond ?? 0,
								totalPrice: wbsPosition.totalPriceSecond,
								totalSumMaterials: wbsPosition.totalSumMaterialsSecond,
								totalSumService: wbsPosition.totalSumServiceSecond,
								totalSum: wbsPosition.totalSumSecond,
								materials: wbsPosition!?.children!?.map(material => ({
									id: material.id,
									norma: material.rate!,
									materialComment: material.materialComment!,
									isNominated: material.isNominated,
									formula: material.formula,
									amount:
										cellType === 'amountSecond' &&
										isExpandable &&
										material.id === dataRow.currentId &&
										+dataRow[cellType] !== 0 &&
										dataRow[cellType] !== '' &&
										dataRow[cellType] !== null &&
										/^[0-9.]*$/.test(String(dataRow[cellType]!))
											? math.round(+dataRow[cellType], ROUND_VALUE)
											: material.amountSecond!,
									key: material.id,
									materialId: material.materialId,
									measureUnitId: material.measureUnit.id,
									order: material.order,
									typeMaterialId: material.typeMaterialId,
									noteSDU: material.noteSDU,
									noteDZ: material.noteDZ,
									noteDP: material.noteDP,
									priceMaterial:
										cellType === 'priceMaterialSecond' && material.id === dataRow.currentId
											? math.round(+dataRow[cellType], ROUND_VALUE)
											: material.priceMaterialSecond ?? 0,
									totalSumMaterials: wbsPosition.totalSumMaterialsSecond ?? 0
								}))
							}
						]
					}

					await adjustmentApi
						.saveStatement(dto, 'patch')
						.then(() => get().updateSections(wbsPosition.containerSectionId))
						.then(() => message.success('Данные обновлены'))
						.catch((err: AxiosError<IErrorDetail>) => {
							Modal.error({
								title: 'Ошибка при сохранении данных',
								content: err.response?.data.detail,
								okText: 'Обновить',
								onOk: () => get().setEstimatePositions(row.containerSectionId, parents)
							})
						})
						.finally(() => set(() => ({ isLoading: false })))
				}
			}
		}
	},
	closedEstimatePositionAdjustment: async (id, sectionId, isClosed) => {
		set(() => ({ isLoading: true }))
		await EstimateApi.closedEstimatePosition(id, 'adjustment')
			.then(() => {
				get().updateSections(sectionId)
				message.success({ content: `Позиция сметы успешно ${isClosed ? 'открыта' : 'закрыта'}` })
			})
			.catch(() =>
				message.error({
					content: `Во время ${isClosed ? 'открытия' : 'закрытия'} произошла ошибка`
				})
			)
	}

	// changeReady: async () => {
	// 	const container = get().container
	// 	if (container) {
	// 		set(() => ({ isLoading: true }))
	// 		return await EstimateApi.toggleReady(
	// 			container.id,
	// 			get().containerType,
	// 			!container.ready
	// 		).finally(() => set(() => ({ isLoading: false })))
	// 	}
	// },

	// setNonActualPositions: nonActualPositions => set(() => ({ nonActualPositions }))
}))
