import { CommentOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Row } from 'antd'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { useAdjustmentState } from 'widgets/adjustment/model/adjustment-state'
import { useEstimateState } from 'widgets/estimate'
import { useShallow } from 'zustand/react/shallow'
import { IDataType, useEstimatePositionState } from '../../model/estimate-position-state'

interface IProps {
	record: IDataType
	disabled: boolean
}

export const MaterialComment: FC<IProps> = ({ record, disabled }) => {
	const { setWorkDataValue } = useEstimatePositionState()
	const { container: containerAdjustment } = useAdjustmentState()
	const container = useEstimateState(useShallow(state => state.container))
	const { pathname } = useLocation()
	const openModal = () => {
		useEstimatePositionState.setState({
			commentRecord: record,
			commentCanEdit: !disabled
		})
	}
	return (
		<Row align="middle" gutter={8} style={{ width: '100%' }}>
			<Col flex={1}>
				<Form.Item name={[record.workId, record.key, 'comment']}>
					<Input
						disabled={
							disabled ||
							!record.availableComment ||
							!record.materialId ||
							(pathname.includes('adjustment') &&
								record?.parentId !== undefined &&
								containerAdjustment?.adjustmentId !== null &&
								record?.parentId !== null) ||
							(typeof record?.parentId === 'string' &&
								container?.isLocalEstimate === false &&
								(container?.adjustmentId === null || container?.adjustmentId === undefined))
						}
						allowClear
						styles={{ input: { color: '#a2a2a2' } }}
						placeholder="Комментарий"
						onClick={() => record.comment?.length! >= 100 && openModal()}
						onBlur={el =>
							setWorkDataValue(record.id, 'comment', el.currentTarget.value, record.workId)
						}
					/>
				</Form.Item>
			</Col>
			<Col>
				<Button
					disabled={
						(!record.availableComment && record.comment?.length !== 0) || !record.materialId
					}
					icon={<CommentOutlined />}
					type={!!record.comment?.length ? 'link' : 'text'}
					onClick={openModal}
				/>
			</Col>
		</Row>
	)
}
