import { IWbsCreate } from 'entities/wbs'
import { IBase } from 'interfaces/IBase'
import { IStatusRouteItem } from 'pages/unmodelPage/wbsContainer'
import { API } from 'services/ApiService'

export const WbsApi = {
	async addContainer(newContainer: IWbsCreate) {
		return await API<IBase>({
			url: '/v1/containers',
			method: 'post',
			data: newContainer
		}).then(async response => response.data)
	},
	async getConstructionObjects() {
		return await API<IBase[]>({
			url: '/v1/constructionObjects',
			method: 'get'
		}).then(async response => response.data)
	},
	async getContainerVisas(containerId: string) {
		return await API<IStatusRouteItem[]>({
			url: `v1/containers/${containerId}/visas`,
			method: 'get'
		}).then(response => response.data)
	},
	async getContainerStatus() {
		return await API({
			url: `v1/containers/containerStatusForFilter`,
			method: 'get'
		}).then(response => response.data)
	}
}
