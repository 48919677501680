import { useQuery } from '@tanstack/react-query';
import { normativeActsService } from 'services/NormativeActsService';

export const useNormativeActs = (drawerOpen?: boolean, workPackageId?: string, violationId?: string) => {
  return useQuery({
    queryKey: ['normativeActs', workPackageId, violationId, drawerOpen],
    queryFn: async () => {
      return await normativeActsService.getNormativeActs(workPackageId, violationId); 
    },
    refetchOnWindowFocus: false,
  });
};
