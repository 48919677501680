import { CheckOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Button, ButtonProps } from 'antd'
import dayjs from 'dayjs'
import { IOrdinance } from 'interfaces/IOrdinance'
import { FC } from 'react'
interface ISignBlockProps {
	ordinance: IOrdinance
	typeId: number
	actId?: string
	onClick: (type: number, fileId?: string) => void
}

export const SignBlock: FC<ISignBlockProps> = ({ actId, ordinance, typeId, onClick }) => {
	const statusId =
		[...ordinance!?.attachments!]!
			?.sort((a, b) => dayjs(b.uploadDate!).unix() - dayjs(a.uploadDate!).unix())
			.find(file => (actId ? file!?.id! === actId! : file!?.typeId! === typeId!))?.statusId! || 0

	const typeSign = [...ordinance!?.attachments!]!
		?.sort((a, b) => dayjs(b.uploadDate!).unix() - dayjs(a.uploadDate!).unix())
		.find(file => (actId ? file!?.id! === actId! : file!?.typeId! === typeId!))?.typeSign!

	const signProps: ButtonProps = {
		type: 'default',
		style: { cursor: 'pointer', width: 120 },
		icon: <EditOutlined />,
		onClick: () => onClick(typeId, actId),
		disabled: false,
		children: 'Подписать',
		ghost: false
	}

	const checkTypeSign = () => {
		switch (typeSign) {
			case 1:
				return 'ЭЦП'
			case 2:
				return 'Ручная подпись'

			default:
				return 'Подписано'
		}
	}

	switch (statusId) {
		case 3:
			signProps.ghost = true
			signProps.style = { cursor: 'default', width: 170 }
			signProps.icon = <ClockCircleOutlined style={{ color: 'brown' }} />
			signProps.children = 'Проверка подписи'
			signProps.disabled = true
			break
		case 4:
			signProps.ghost = true
			signProps.style = {
				cursor: 'default',
				width: checkTypeSign() === 'Ручная подпись' ? 170 : 120
			}
			signProps.icon = <CheckOutlined style={{ color: 'green' }} />
			signProps.children = checkTypeSign()
			signProps.disabled = true
			break
		default:
			break
	}

	return <Button {...signProps} />
}
