import { API } from "services/ApiService"
import { IEstimateCreation } from "../model/estimate-related-documents-schema"

export const EstimateCreationFromVorApi = {
	async get(containerId: string, type: 'wbs' | 'local') {
		return await API<IEstimateCreation[]>({
			url: `/v1/${type === 'wbs' ? 'containers' : 'containerEstimates'}/${containerId}/relatedDocuments`,
			method: 'get',
		}).then(async response => response.data)
	}
}