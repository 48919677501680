import { useQueryClient } from '@tanstack/react-query'
import { message, Modal, Space } from 'antd'
import { AxiosError } from 'axios'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useWbsContainerState } from 'pages/unmodelPage/wbsContainer'
import { memo, useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { getAppUserPermissions } from 'services/AuthService'
import {
	getContractors,
	getContractsByProject1CId,
	getIssueSubTypes,
	getIssueTypes
} from 'services/IssuesService'
import { getBuildingPermitsList } from 'services/OrdinanceService'
import { CONTAINER_STATUS } from 'shared/constants'
import { resetAsm } from 'store/asmSlice'
import { resetCheckListsState } from 'store/checklistsSlice'
import {
	setEnvironment,
	setEnvironmentPermissions,
	setEnvironmentProject
} from 'store/environmentSlice'
import { resetIssuesState } from 'store/issueSlice'
import { resetOrdinancesState } from 'store/ordinanceSlice'
import { resetTemplatesState } from 'store/templatesSlice'
import { resetTrafficLight } from 'store/trafficLightSlice'
import { IErrorData } from 'UI/dashboard/EnvironmentConfig'
import { EstimateComments } from 'widgets/estimate-comments'
import { EstimateRelatedDocuments } from 'widgets/estimate-creation-from-vor/ui/EstimateRelatedDocuments'
import { EstimatePosition } from 'widgets/estimate-position-card'
import { EstimatePositionCommentsView } from 'widgets/estimate-position-comments'
import { RegulatoryRequest } from 'widgets/regulatory-request'
import { useWbsPermits, WbsStatusModal } from 'widgets/wbs'
import {
	EstimateContainerHeader,
	EstimateHistory,
	EstimateNonActualModal,
	EstimateNote,
	useEstimateContainerQuery,
	useEstimateState
} from '..'
import { EstimateApi } from '../api/estimate-api'
import { Estimate } from './Estimate'
import { EstimateRemoveModal } from './EstimateRemoveModal'

interface IProps {
	type: 'wbs' | 'local'
}

export const EstimateContainer = memo(function (params: IProps) {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { project, user } = useAppSelector(state => state.environment)
	const permits = useWbsPermits()
	const queryClient = useQueryClient()
	const { data } = useEstimateContainerQuery(params.type)
	const { id: containerId } = useParams()
	const { setContainerType } = useEstimateState()
	const { setCurrentContainerId } = useWbsContainerState()

	const changeCurrentProject = async (projectId: string) => {
		const userProject = user?.projects?.find(p => p.id === projectId)
		dispatch(resetIssuesState())
		dispatch(resetOrdinancesState())
		dispatch(resetCheckListsState())
		dispatch(resetTemplatesState())
		dispatch(resetAsm())
		dispatch(resetTrafficLight())

		await getAppUserPermissions(userProject?.id ?? '').then(data => {
			dispatch(setEnvironmentPermissions(data))
		})

		dispatch(
			setEnvironmentProject({
				option: 'project',
				data: userProject!
			})
		)
		await getContractors().then(data => {
			dispatch(setEnvironment({ state: 'contractors', data }))
		})
		await getIssueTypes()
			.then(data => {
				dispatch(setEnvironment({ state: 'issueTypes', data }))
			})
			.catch((error: AxiosError) => {
				const { detail } = error!?.response!?.data! as IErrorData
				const { url } = error!?.config!
				message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
				return Promise.reject
			})
		await getIssueSubTypes()
			.then(data => {
				dispatch(setEnvironment({ state: 'issueSubTypes', data }))
			})
			.catch((error: AxiosError) => {
				const { detail } = error!?.response!?.data! as IErrorData
				const { url } = error!?.config!
				message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
				return Promise.reject
			})
		await getContractsByProject1CId(userProject?.project1C?.id!).then(data => {
			dispatch(setEnvironment({ state: 'contracts', data }))
		})
		await getBuildingPermitsList(userProject!?.project1C?.id!).then(data => {
			dispatch(setEnvironment({ state: 'buildingPermits', data }))
		})
		queryClient.invalidateQueries({ queryKey: ['issueAnalytics'] })
		queryClient.invalidateQueries({ queryKey: ['ordinanceAnalytics'] })
		queryClient.invalidateQueries({ queryKey: ['violationTypesAnalytics'] })
		queryClient.invalidateQueries({ queryKey: ['useMyFilesAnalytics'] })
	}

	useEffect(() => {
		setContainerType(params.type)
		setCurrentContainerId(containerId)
	}, [params.type])

	useEffect(() => {
		if (data instanceof AxiosError) {
			Modal.error({
				title: 'Ошибка',
				content: data.response?.data.detail,
				okText: `Ок`,
				onOk: () => navigate('/')
			})
		}
		if (
			!(data instanceof AxiosError) &&
			data?.isActual === false &&
			params.type === 'wbs' &&
			!data.ready &&
			(data.status.name === CONTAINER_STATUS.new ||
				data.status.name === CONTAINER_STATUS.preparation)
		) {
			Modal.confirm({
				title: 'Структура ВОР не актуальна',
				content: permits.canEdit
					? 'Для продолжения работы с ВОР необходимо актуализировать данные'
					: 'Для обновления структуры обратитесь к администратору или автору/редактору ВОР',

				okText: permits.canEdit ? 'Обновить' : 'Закрыть',
				onOk: async () =>
					permits.canEdit
						? await EstimateApi.actualize(data.id)
								.then(() => {
									message.success('Данные актуализированы')
									queryClient.invalidateQueries({ queryKey: ['estimateContainer'] })
									queryClient.invalidateQueries({ queryKey: ['estimate'] })
								})
								.catch(err => {
									message.error('Произошла ошибка, обратитесь к администратору')
									navigate('/unmodel')
								})
						: navigate('/unmodel'),
				cancelButtonProps: { style: { display: permits.canEdit ? 'inline' : 'none' } },
				onCancel: () => navigate('/unmodel')
			})
		}
		if (!(data instanceof AxiosError) && data && data.projectId !== project.id) {
			Modal.confirm({
				title: 'Внимание',
				content:
					'Проект ВОР отличается от текущего. Для продолжения работы, требуется сменить текущий проект.',
				okText: 'Сменить',
				onOk: async () => changeCurrentProject(data.projectId),
				onCancel: () => navigate('/unmodel')
			})
		}
	}, [data])

	return (
		<>
			<EstimateHistory type={params.type} />
			<WbsStatusModal type={params.type} />
			<EstimatePosition disabled={false} type={params.type} />
			<EstimatePositionCommentsView type={params.type} />
			<EstimateComments type={params.type} />
			<EstimateNote />
			<EstimateRemoveModal type={params.type} isAdjustment={false} />
			<RegulatoryRequest />
			<EstimateRelatedDocuments type={params.type} />
			<EstimateNonActualModal />
			<Space direction="vertical">
				<EstimateContainerHeader type={params.type} />
				<Estimate type={params.type} />
			</Space>
		</>
	)
})
