import { UploadOutlined } from '@ant-design/icons'
import { FormInstance, Input, Modal, Upload, message } from 'antd'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { Divider } from 'antd/lib'
import { useAppSelector } from 'hooks/appReduxHook'
import { IIssueAttachments, IIssueCommentDto } from 'interfaces/IIssue'
import _ from 'lodash'
import { FC, useEffect, useState } from 'react'

interface ICheckStatus {
	init: string
	current: string
}
interface ICommentModalProps {
	open: boolean
	form: FormInstance
	statuses: ICheckStatus
	onConfirm: (confirmDto: Partial<IIssueCommentDto>) => void
	onCancel: () => void
	attachments: IIssueAttachments[]
	filesToUpload: UploadFile[]
}

const getBase64 = (file: RcFile): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = error => reject(error)
	})

export const CommentModal: FC<ICommentModalProps> = ({
	attachments,
	filesToUpload,
	open,
	form,
	statuses,
	onConfirm,
	onCancel
}) => {
	const [comment, setComment] = useState<string>('')
	const [files, setFiles] = useState<UploadFile[]>([])
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewTitle, setPreviewTitle] = useState('')
	const [isDisabled, setIsDisabled] = useState(true)
	const issueDto = useAppSelector(store => store.issues.dto)
	const totalFiles = attachments?.length + files?.length + filesToUpload?.length
	const showError = _.debounce(() => {
		message.error('Общее количество файлов не может превышать 50')
	})
	const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		if (attachments?.length + newFileList?.length > 50) {
			showError()
		} else {
			setFiles(newFileList)
		}
	}

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile)
		}

		setPreviewImage(file.url || (file.preview as string))
		setPreviewOpen(true)
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
	}

	const handleCancel = () => {
		setFiles([])
		setIsDisabled(true)
		onCancel()
	}

	const props: UploadProps = {
		multiple: true,
		onChange: handleChange,
		onPreview: handlePreview,
		openFileDialogOnClick: !(totalFiles >= 50),
		fileList: files,
		listType: 'picture-card',
		beforeUpload: file => {
			const canUpload =
				!file.name.includes('jfif') &&
				(file.type === 'image/jpeg' ||
					file.type === 'image/jpg' ||
					file.type === 'image/png' ||
					file.type === 'application/pdf')
			if (!canUpload) {
				message.error({
					content: `${file.name} - тип файла не поддерживается`,
					style: { zIndex: 9999 }
				})
			}
			return canUpload ? false : Upload.LIST_IGNORE
		}
	}

	const uploadButton = (
		<div style={{ cursor: !(totalFiles >= 50) ? 'pointer' : 'not-allowed' }}>
			<UploadOutlined rev={undefined} />
			<div style={{ marginTop: 8 }}>Добавить</div>
		</div>
	)

	const onCommitAdd = () => {
		const data: Partial<IIssueCommentDto> = {
			comment: comment,
			files: files
		}
		!isDisabled && onConfirm(data)
	}

	useEffect(() => {
		if (!open) {
			setComment('')
			setFiles([])
		}
	}, [open])

	useEffect(() => {
		setIsDisabled(
			comment?.length < 3 || issueDto.issueTypeId !== 'e371fab6-3910-4862-92f6-e01229a27d52'
				? (statuses!?.init!?.includes('open') &&
						statuses!?.current!?.includes('ready') &&
						(files?.length === 0 || comment?.length < 3)) ||
						(statuses!?.init!?.includes('ready') &&
							statuses!?.current!?.includes('open') &&
							comment?.length < 3)
				: false
		)
	}, [open, comment, files])

	return (
		<Modal
			centered
			open={open}
			onOk={onCommitAdd}
			onCancel={handleCancel}
			title="Добавить комментарий"
			okText="Подтвердить"
			cancelText="Отмена"
			okButtonProps={{ disabled: isDisabled }}
			width="30vw"
			zIndex={1201}
		>
			<Input.TextArea
				value={comment}
				placeholder="Ваш комментарий"
				showCount
				maxLength={250}
				style={{ height: '120px', resize: 'none' }}
				onChange={area => setComment(area.currentTarget.value)}
			/>
			<Divider />
			<div style={{ marginTop: '-2vh' }}>{`Доступно: ${50 - totalFiles}/50`}</div>
			<div style={{ maxHeight: '50vh', overflowY: 'auto', borderRadius: '8px !important' }}>
				<Upload {...props}>{uploadButton}</Upload>
			</div>
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewOpen(false)}
			>
				<img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
			</Modal>
		</Modal>
	)
}
