import { FC, useState } from 'react';
import { Table, Tag, Button, Row, Col, Tooltip, Form, Select, Input, Popconfirm, message } from 'antd';
import { useWorkPackages } from 'hooks/useWorkPackage';
import { DeleteOutlined, EditOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import { IWorkPackage } from 'services/NormativeActsService';
import { AddNormativeAct } from '../features/add-normativeAct/add-normative-act';
import { IViolation } from 'services/violationsService';
import { useViolations } from 'hooks/useViolations';
import { useQueryClient } from '@tanstack/react-query';
import { INormativeAct, normativeActsService } from 'services/NormativeActsService';
import { useNormativeActs } from 'hooks/useNormativeActs';
import { FormItemProps } from 'antd/lib';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  children: React.ReactNode
  dataIndex: string
  violationsData: IViolation[]
  workPackagesData: IWorkPackage[]
  rules: FormItemProps['rules']
}

const EditableCell: FC<EditableCellProps> = ({ editing, dataIndex, violationsData, workPackagesData, children, rules, ...restProps }) => {

  const inputNode =
    dataIndex === 'workPackages' ? (
      <Select
        mode="multiple"
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={workPackagesData?.sort((a, b) => a.code! - b.code!)
          ?.map(wp => ({
            key: wp.id,
            value: wp.id,
            label: `${wp.code}  ${wp.name}`,
          }))}
      />
    ) : dataIndex === 'violation' ? (
      <Select
        mode="multiple"
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(a, b) =>
          (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
        }
        options={violationsData?.map(v => ({
          key: v.id,
          value: v.id,
          label: v.name,
        }))}
      />
    ) : (
      <Input />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={rules}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

export const NormativeActsGuide: FC = () => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<string>('');
  const [popOpenId, setPopOpenId] = useState<string | null>(null);
  const { data: normativeActs, isFetching } = useNormativeActs();
  const { data: workPackagesData } = useWorkPackages();
  const { data: violationsData } = useViolations();
  const isEditing = (record: IWorkPackage) => record.id === editingKey;

  const edit = (record: Partial<INormativeAct>) => {
    form.setFieldsValue({
      name: record.name,
      workPackages: record.workPackages?.map(wp => wp.id),
      violation: record.violations?.map(v => v.id),
    });
    setEditingKey(record.id!);
  };


  const save = async (record: INormativeAct) => {
    try {
      await form.validateFields()
      const dto = {
        id: record.id,
        name: form.getFieldValue('name'),
        violationIds: form.getFieldValue('violation'),
        workPackageIds: form.getFieldValue('workPackages'),
      };
      await normativeActsService.updateNormativeAct(dto);

      queryClient.invalidateQueries({ queryKey: ['normativeActs'] });

      cancel();
    } catch (error) {
      console.error('Ошибка при сохранении:', error);
    }
  };

  const handleDelete = (id: string) => {
    normativeActsService
      .deleteNormativeAct(id)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['normativeActs'] });
        cancel();
        message.success('Нормативный акт успешно удалён');
      })
      .catch(() => {
        message.error('Во время удаления произошла ошибка');
        cancel();
      });
  };

  const cancel = () => {
    setEditingKey('');
  };

  const columns = [
    {
      dataIndex: 'workPackages',
      width: 150,
      rules: [{ required: true, message: 'Выберите пакет СМР' }],
      title: 'Пакеты СМР',
      render: (_: any, record: INormativeAct) => {
        return record.workPackages?.map((wp: IWorkPackage) => (
          <Tag
            key={wp.id}
            color="#1677ff"
            style={{
              fontSize: 14,
              padding: 4,
              margin: 3,
              width: 'fit-content',
              maxWidth: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >{wp.code} {wp.name}
          </Tag>
        ));
      },
      editable: true,
    },
    {
      dataIndex: 'name',
      title: 'Нормативный акт',
      width: 150,
      rules: [{ required: true, message: 'Введите название нормативного акта' }],
      editable: true
    },
    {
      dataIndex: 'violation',
      width: 500,
      rules: [{ required: true, message: 'Выберите нарушения' }],
      title: 'Нарушение',
      render: (_: any, record: INormativeAct) => {
        return record.violations?.map((el: IViolation) => (
          <Tag
            color="#1677ff"
            style={{
              fontSize: 14,
              padding: 4,
              margin: 3,
              width: 'fit-content',
              maxWidth: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {el.name}
          </Tag>
        ));
      },
      editable: true,
    },
    {
      width: 64,
      fixed: 'right',
      render: (_: any, record: INormativeAct) => {
        const editable = isEditing(record);
        return editable ? (
          <Row gutter={8} wrap={false}>
            <Col>
              <Tooltip title="Сохранить">
                <Button icon={<SaveOutlined />} type="primary" onClick={() => save(record)} />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title="Отмена">
                <Button icon={<StopOutlined />} onClick={() => cancel()} />
              </Tooltip>
            </Col>
          </Row>
        ) : (
          <Row gutter={8} wrap={false}>
            <Col>
              <Tooltip title="Редактировать">
                <Button
                  icon={<EditOutlined />}
                  disabled={editingKey !== ''}
                  onClick={() => edit(record)}
                />
              </Tooltip>
            </Col>
            <Col>
              <Popconfirm
                open={popOpenId === record.id}
                title="Удалить?"
                placement="topRight"
                onConfirm={() => handleDelete(record.id)}
                onCancel={() => {
                  setPopOpenId(null);
                  cancel();
                }}
                okButtonProps={{ danger: true }}
                okText="Да"
                cancelText="Отмена"
              >
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  style={{ marginRight: 8 }}
                  onClick={() => setPopOpenId(record.id)}
                />
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: INormativeAct) => ({
        dataIndex: col.dataIndex,
        editing: isEditing(record),
        workPackagesData,
        rules: col.rules,
        violationsData
      })
    }
  })


  return (
    <div className='dashboard__block' style={{ borderRadius: '0 0 .8rem .8rem' }}>
      <Col>
        <AddNormativeAct />
      </Col>
      <Form form={form} component={false}>
        <Table
          style={{ marginTop: '1rem' }}
          scroll={{ x: '100%' }}
          dataSource={normativeActs}
          columns={mergedColumns as any}
          rowKey="id"
          loading={isFetching}
          bordered
          pagination={false}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
    </div>
  );
};
