import { Card, Col, Row } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect } from 'react'
import { MobileReportApi } from '../api/mobile-report-api'
import { useMobileReportState } from '../model/mobile-report-state'
import { MobileReport } from './MobileReport'
import { MobileReportCreate } from './MobileReportCreate'
import { MobileReportCreateModal } from './MobileReportCreateModal'
import { MobileReportDownload } from './MobileReportDownload'

interface IProps {}

export const MobileReportRegistry: FC<IProps> = () => {
	const {
		showMobileReportModal,
		currentPage,
		filters,
		setMobileReport,
		setShowMobileReportModal,
		setTotalItems,
		setTotalPages
	} = useMobileReportState()
	const { project } = useAppSelector(state => state.environment)
	const dto = {
		year: filters?.year ?? new Date().getFullYear(),
		projectIds: filters?.projectIds ?? [project.id],
		months: filters?.months ?? []
	}

	useEffect(() => {
		MobileReportApi.get({
			dto,
			limit: 50,
			offset: currentPage === 1 ? 0 : currentPage * 50 - 50
		}).then(response => {
			setMobileReport(response.data)
			setTotalItems(response!.totalItems)
			setTotalPages(response!.totalPages)
		})
	}, [currentPage])

	return (
		<>
			<MobileReportCreateModal
				show={showMobileReportModal}
				onClose={() => setShowMobileReportModal(false)}
			/>
			<Card>
				<Row justify="space-between">
					<Col>
						<Row gutter={8}>
							<Col>
								<MobileReportCreate />
							</Col>
							<Col>
								<MobileReportDownload />
							</Col>
						</Row>
					</Col>
				</Row>
				<MobileReport />
			</Card>
		</>
	)
}
