import { useAppSelector } from 'hooks/appReduxHook'
import { FC, ReactElement } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

interface IProp {
	icon?: ReactElement<any, any>
	text?: string
}

export const YandexTrackerButton: FC<IProp> = ({ text, icon }) => {
	const { project, user } = useAppSelector(state => state.environment)
	const { pathname } = useLocation()
	const id = '66cca7a3505690982aa17878'
	const yandexTrackerData = {
		name: !(user?.lastName === undefined || user?.firstName === undefined)
			? user?.lastName + ' ' + user?.firstName
			: '',
		email: user?.email ?? '',
		myabsProject: project?.name.replace('#', '%23') ?? '',
		myabsUrl: window.location.protocol + '//' + window.location.host + pathname
	}
	const yandexTrackerLink = Object.entries(yandexTrackerData)
		.map(yt => `${yt[0]}=${yt[1]}`)
		.join('&')
	return (
		<NavLink to={`https://forms.yandex.ru/cloud/${id}?${yandexTrackerLink}`} target="_blank">
			{icon} {text}
		</NavLink>
	)
}
