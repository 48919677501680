import { QuestionCircleOutlined } from '@ant-design/icons'
import {
	Button,
	Card,
	Col,
	ColorPicker,
	Descriptions,
	Drawer,
	Form,
	Input,
	Row,
	Switch,
	Tooltip,
	Typography,
	message
} from 'antd'
import { AxiosError } from 'axios'
import { IPermission, IRoleDto } from 'interfaces/IPermissions'
import { FC, useEffect, useState } from 'react'
import { addRole, getPermissions, getRoleById, updateRoleById } from 'services/AdminService'
const { Text } = Typography

interface IRoleDrawerProps {
	open: boolean
	roleId: string | null
	onClose: (mustUpdate: boolean) => void
}

interface IGroupedPermission {
	group: string
	groupRu?: string
	permissions: Omit<IPermission, 'group'>[]
}

const RoleDrawer: FC<IRoleDrawerProps> = ({ open, roleId, onClose }) => {
	const [roleDto, setRoleDto] = useState<IRoleDto>({
		id: roleId ?? null,
		name: null,
		description: null,
		permissions: [],
		prefix: null,
		color: null
	})
	const [roleForm] = Form.useForm<IRoleDto>()
	const [currentRole, setCurrentRole] = useState<string | null>(null)
	const [permissions, setPermissions] = useState<IGroupedPermission[]>([])

	useEffect(() => {
		getPermissions().then(data => {
			const items = groupPermissions(data)
			setPermissions(items)
		})
	}, [])

	useEffect(() => {
		if (open) {
			roleForm.resetFields()
			if (roleId !== null) {
				setCurrentRole(roleId)
			}
		}
	}, [open])

	useEffect(() => {
		if (currentRole !== null) {
			getRoleById(currentRole).then((data: IRoleDto) => {
				roleForm.setFieldsValue(data)
				setRoleDto({ ...data, id: currentRole })
			})
		}
	}, [currentRole])

	const onRoleSave = async () => {
		if (roleDto.id !== null) {
			await updateRoleById(roleDto)
				.then(response => {
					message.success('Настройки роли сохранены')
					onCancel(true)
				})
				.catch((error: AxiosError) =>
					message.error(`При сохранении возникла ошибка.\n${error.message}`)
				)
		} else {
			await addRole(roleDto)
				.then(() => {
					message.success(`Роль ${roleDto.name!} успешно создана`)
					onCancel(true)
				})
				.catch((error: AxiosError) =>
					message.error(`При сохранении возникла ошибка.\n${error.message}`)
				)
		}
	}

	const onCancel = (mustUpdate: boolean = false) => {
		onClose(mustUpdate)
		setCurrentRole(null)
		setRoleDto({
			id: null,
			name: null,
			description: null,
			permissions: [],
			prefix: null,
			color: null
		})
	}

	const onPermissionChange = (permission: Omit<IPermission, 'group'>) => {
		const rolePermissions: number[] = roleDto.permissions
		if (rolePermissions.some(rpm => rpm === permission.id)) {
			setRoleDto(prev => ({
				...prev,
				permissions: rolePermissions.filter(rpm => rpm !== permission.id)
			}))
		} else {
			setRoleDto(prev => ({ ...prev, permissions: [...rolePermissions, permission.id] }))
		}
	}

	const groupTranslate = (groupName: string) => {
		switch (groupName) {
			case 'Adjustment':
				return 'Было/Стало'
			case 'ASM':
				return 'Список ВОР'
			case 'Checklists':
				return 'Чек-листы'
			case 'ChecklistsTepmplates':
				return 'Шаблоны чек-листов'
			case 'Estimate':
				return 'Локальные сметы'
			case 'Issue':
				return 'Замечания'
			case 'Ordinance':
				return 'Предписания'
			case 'OrdinanceFine':
				return 'Штрафы'
			case 'ReleaseNote':
				return 'История обновлений'
			case 'Reports':
				return 'Отчёты'
			case 'Test seeding':
				return 'Системные'
			default:
				return 'Прочее'
		}
	}

	const groupPermissions = (data: IPermission[]) => {
		const groupedPermissions: IGroupedPermission[] = []
		data.forEach(item => {
			if (groupedPermissions.find(ar => ar.group === item.group)) {
				groupedPermissions
					.find(ar => ar.group === item.group)
					?.permissions.push({
						id: item.id,
						name: item.name,
						synonym: item.synonym,
						groupRu: item.groupRu,
						description: item.description
					})
			} else {
				groupedPermissions.push({
					group: item.group,
					groupRu: item.groupRu,
					permissions: [
						{
							id: item.id,
							name: item.name,
							synonym: item.synonym,
							description: item.description
						}
					]
				})
			}
		})
		return groupedPermissions
	}

	return (
		<Drawer
			forceRender={true}
			open={open}
			onClose={() => onCancel()}
			width={window.outerWidth < 1300 ? '75vw' : '50vw'}
			destroyOnClose={true}
			title={roleDto.name !== null ? `Настройка роли: ${roleDto.name!}` : 'Новая роль'}
			extra={
				<Button
					htmlType="submit"
					onClick={() => roleForm.submit()}
					ghost
					style={{ borderColor: '#fff' }}
				>
					Сохранить
				</Button>
			}
			className="at-drawer-status at-drawer-status--noneliminated"
			bodyStyle={{ backgroundColor: '#f2f2f2', paddingTop: 0 }}
		>
			<Form
				name="roleForm"
				form={roleForm}
				onFinish={onRoleSave}
				style={{ marginTop: '1rem' }}
				initialValues={roleDto}
			>
				<Card className="at-workcard">
					<Descriptions
						title="Основные параметры:"
						layout="horizontal"
						column={12}
						bordered
						className="at-descriptions"
						labelStyle={{
							fontWeight: 'bold',
							width: '20%',
							alignItems: 'center',
							padding: '2px 8px'
						}}
						contentStyle={{
							width: '80%',
							padding: '0px 0px',
							alignItems: 'center'
						}}
					>
						<Descriptions.Item
							label={
								<>
									Название роли:<span style={{ color: 'red' }}>*</span>
								</>
							}
							span={12}
						>
							<Form.Item
								name="name"
								rules={[
									{
										required: true,
										message: 'Укажите название роли'
									}
								]}
							>
								<Input
									allowClear
									value={roleDto.name!}
									onChange={el => setRoleDto(prev => ({ ...prev, name: el.target.value }))}
								/>
							</Form.Item>
						</Descriptions.Item>
						<Descriptions.Item label="Описание:" span={12}>
							<Form.Item name="description">
								<Input.TextArea
									autoSize={{
										minRows: 4
									}}
									onChange={el => setRoleDto(prev => ({ ...prev, description: el.target.value }))}
								/>
							</Form.Item>
						</Descriptions.Item>
						<Descriptions.Item label="Префикс:" span={12}>
							<Form.Item name="prefix">
								<Input
									allowClear
									value={roleDto.prefix!}
									onChange={el => setRoleDto(prev => ({ ...prev, prefix: el.target.value }))}
								/>
							</Form.Item>
						</Descriptions.Item>
						<Descriptions.Item label="Цвет:" span={12}>
							<Form.Item name="color">
								<ColorPicker
									format="hex"
									defaultValue={roleDto.color ?? 'fff'}
									onChange={value => setRoleDto(prev => ({ ...prev, color: value.toHexString() }))}
								/>
							</Form.Item>
						</Descriptions.Item>
					</Descriptions>
				</Card>
				{permissions
					.sort((a, b) => (b.group !== null ? 1 : 0) - (a.group !== null ? 1 : 0))
					.map(permission => {
						return (
							<Card className="at-workcard">
								<Descriptions
									title={permission.groupRu}
									layout="horizontal"
									size="small"
									column={2}
									bordered
									className="at-descriptions"
									labelStyle={{
										width: '40%',
										fontWeight: 'bold',
										alignItems: 'center',
										padding: '2px 8px'
									}}
									contentStyle={{
										alignItems: 'center'
									}}
								>
									{permission.permissions.map(item => {
										const label = (
											<Row
												justify="space-between"
												align="middle"
												style={{ flexFlow: 'nowrap', width: '100%' }}
											>
												<Col style={{ width: 200 }}>
													<Text> {item.synonym}:</Text>
												</Col>
												<Col>
													{item.description && (
														<Tooltip title={item.description}>
															<QuestionCircleOutlined
																style={{
																	marginLeft: 8
																}}
															/>
														</Tooltip>
													)}
												</Col>
											</Row>
										)
										return (
											<Descriptions.Item label={label} span={1}>
												<Form.Item name={item.id}>
													<Switch
														size="small"
														checked={roleDto.permissions.some(p => p === item.id)}
														onChange={() => onPermissionChange(item)}
													/>
												</Form.Item>
											</Descriptions.Item>
										)
									})}
								</Descriptions>
							</Card>
						)
					})}
			</Form>
		</Drawer>
	)
}
export default RoleDrawer
