import { SettingOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Dropdown, MenuProps, Row, Typography } from 'antd'
import { memo, useState } from 'react'
import { TEstimateType } from 'shared/schema'
import { useShallow } from 'zustand/react/shallow'
import { useAdjustmentColumnConfig } from '../model/adjustment-column-config-state'
const { Text } = Typography

const colNames = (item: string) => {
	switch (item) {
		case 'origin':
			return 'Первая версия'
		case 'done':
			return 'Выполнено'
		case 'second':
			return 'Версия подрядчика'
		case 'sdu':
			return 'Версия СДУ'
		case 'comments':
			return 'Примечания'
		default:
			return item
	}
}
export const AdjustmentColumnsConfig = memo(function ({ type }: { type: TEstimateType }) {
	const [visible, setVisible] = useState(false)
	const { columns, setVisibleColumns } = useAdjustmentColumnConfig(
		useShallow(state => ({
			columns: state[type],
			setVisibleColumns: state.setVisibleColumns
		}))
	)
	// const filteredColumns = Object.entries(columns).filter(([key]) => {
	// 	if (type === 'local' && (key === 'noteSDU' || key === 'noteDZ' || key === 'noteDP')) {
	// 		return false
	// 	}
	// 	return true
	// })

	const items: MenuProps['items'] = Object.entries(columns).map((item, index) => ({
		key: index,
		label: (
			<Row
				onClick={() => setVisibleColumns(item[0], !item[1], type)}
				style={{ cursor: 'pointer', flexWrap: 'inherit' }}
			>
				<Col span={3}>
					<Checkbox checked={item[1]} />
				</Col>
				<Col flex={1}>
					<Text>{colNames(item[0])}</Text>
				</Col>
			</Row>
		)
	}))
	return (
		<Dropdown
			menu={{ items }}
			trigger={['click']}
			open={visible}
			overlayStyle={{ width: 300 }}
			onOpenChange={() => setVisible(!visible)}
		>
			<Button
				type={Object.values(columns).some(c => c === false) ? 'primary' : 'default'}
				icon={<SettingOutlined />}
			/>
		</Dropdown>
	)
})
