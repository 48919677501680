import { Modal, Table } from 'antd'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { useEstimateRelatedDocuments } from '../model/estimate-related-documents-query'
import { useEstimateRelatedDocumentsState } from '../model/estimate-related-documents-state'
import { useEstimateRelatedDocumentsColumns } from './EstimateRelatedDocumentsColumns'

interface IProp {
	type: 'wbs' | 'local'
}

export const EstimateRelatedDocuments: FC<IProp> = ({ type }) => {
	const { showModal, toggleShowModal } = useEstimateRelatedDocumentsState(
		useShallow(state => ({
			showModal: state.showModal,
			toggleShowModal: state.toggleShowModal
		}))
	)
	const columns = useEstimateRelatedDocumentsColumns()
	const { id } = useParams()
	const { data } = useEstimateRelatedDocuments(id!, type)
	return (
		<Modal
			open={showModal}
			onCancel={() => toggleShowModal(false)}
			destroyOnClose={true}
			width={window.innerWidth - (window.innerWidth * 60) / 100}
			title="Связанные документы"
			footer=""
		>
			<Table
				sticky={{ offsetHeader: 0 }}
				tableLayout="fixed"
				rowKey={row => row?.id}
				className="related-documents-table"
				size="small"
				bordered
				dataSource={data!}
				columns={columns}
				scroll={{ y: (window.outerHeight * 60) / 100 }}
				// expandable={{
				// 	expandedRowKeys: idExpand
				// 	// rowExpandable: row => !!row?.children?.length,
				// 	// expandIcon: ({ expanded, onExpand, record }) =>
				// 	// 	record?.children?.length ? (
				// 	// 		expanded ? (
				// 	// 			<FolderOpenFilled
				// 	// 				style={{ color: '#feb300', marginRight: '5px' }}
				// 	// 				onClick={e => {
				// 	// 					onExpand(record, e)
				// 	// 					useEstimateRelatedDocumentsState.setState({
				// 	// 						idExpand: idExpand?.filter(elem => elem !== record.id)
				// 	// 					})
				// 	// 				}}
				// 	// 			/>
				// 	// 		) : (
				// 	// 			<FolderFilled
				// 	// 				style={{ color: '#feb300', marginRight: '5px' }}
				// 	// 				onClick={e => {
				// 	// 					onExpand(record, e)
				// 	// 					useEstimateRelatedDocumentsState.setState({
				// 	// 						idExpand: [...idExpand!, record.id]
				// 	// 					})
				// 	// 				}}
				// 	// 			/>
				// 	// 		)
				// 	// 	) : (
				// 	// 		<FolderFilled style={{ color: '#ddd', marginRight: '5px' }} />
				// 	// 	)
				// }}
				pagination={false}
			/>
		</Modal>
	)
}
