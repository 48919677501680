import {
	DeleteOutlined,
	FileExclamationOutlined,
	FileTextOutlined,
	FilterOutlined,
	FormOutlined,
	PushpinFilled,
	SyncOutlined
} from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { ATIconCheckList } from 'UI/icons'
import StatusIndicator from 'UI/statusIndicator'
import { Button, Col, Input, Popconfirm, Row, Table, Tag, Tooltip, message } from 'antd'
import type { ColumnType, ColumnsType } from 'antd/es/table'
import { AxiosError } from 'axios'
import ColumnConfig, { IColumns } from 'components/columnConfig'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useIssues } from 'hooks/useIssues'
import { IErrorDetail } from 'interfaces/IBase'
import { IIssue, IIssuePermissions } from 'interfaces/IIssue'
import ChecklistsDrawer from 'pages/checklistsPage/checklists/ChecklistDrawer'
import OrdinanceDrawer from 'pages/ordinancePage/ordinanceDrawer'
import { FC, useEffect, useState } from 'react'
import { Resizable, ResizeCallbackData } from 'react-resizable'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteIssue, issuesElementTranslate } from 'services/IssuesService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setCurrentChecklistId } from 'store/checklistsSlice'
import { setCurrentPage, setIssueFilters, setIssueSort } from 'store/issueSlice'
import { resetDto } from 'store/ordinanceSlice'
import { setPageTitle } from 'store/rootSlice'
import IssueDrawer from './issueDrawer'
import IssueOrdinance from './issueOrdinance'
import { IssueReport } from './issueReport'
import IssuesFilters from './issuesFilters'
const IssuesPage: FC = () => {
	const checkPermissions = useCheckPermissions()
	const { id: issueId } = useParams()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const queryClient = useQueryClient()
	const conf = localStorage.getItem('issuesPageColumns')
	const parsedConf: IColumns[] = conf && JSON.parse(conf!)
	const { project, user: currentUser, permissions } = useAppSelector(state => state.environment)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
	const [showIssuesFilter, setShowIssuesFilter] = useState<boolean>(false)
	const [issueOrdinanceDrawer, setIssueOrdinanceDrawer] = useState<boolean>(false)
	const issuesFilters = useAppSelector(state => state.issues.filters)
	const issuesListState = useAppSelector(state => state.issues.listState)
	const [selectedIssue, setSelectedIssue] = useState<IIssue | null>(null)
	const [selectedIssuesKeys, setSelectedIssuesKeys] = useState<React.Key[]>([])
	const [selectedIssuesRows, setSelectedIssuesRows] = useState<IIssue[]>([])
	const [compareIssue, setCompareIssue] = useState<IIssue | null>(null)
	const [showColumns, setShowColumns] = useState<IColumns[]>([])
	const [showOrdinanceDetail, setShowOrdinanceDetail] = useState(false)
	const [selectedOrdinance, setSelectedOrdinance] = useState<string | null>(null)
	const [showChecklistDrawer, setShowChecklistDrawer] = useState(false)
	const [checklistItemId, setChecklistItemId] = useState<string | null>(null)

	const pagePermissions: IIssuePermissions = {
		canView: checkPermissions([PERMISSIONS.IssueView]),
		canEdit: checkPermissions([PERMISSIONS.IssueEdit]),
		canCreate: checkPermissions([PERMISSIONS.IssueCreate]),
		setStatus: checkPermissions([PERMISSIONS.IssueToReadyForControl]),
		attachFiles: checkPermissions([PERMISSIONS.AttachFilesForIssue]),
		allCompanies: checkPermissions([PERMISSIONS.AllCompanies]),
		ordinanceView: checkPermissions([PERMISSIONS.OrdinanceView]),
		ordinanceCreate: checkPermissions([PERMISSIONS.OrdinanceCreate])
	}
	const { data: issuesList, isFetching } = useIssues()

	const [issuesColumns, setIssuesColumns] = useState<ColumnsType<IIssue>>([
		{
			dataIndex: 'issueStatus',
			title: 'Статус',
			fixed: 'left',
			width: 150,
			render: (_, record) => (
				<Row gutter={8} wrap={false} align="middle">
					<Col>
						<StatusIndicator status={record.issueStatus!?.identityName!} />
					</Col>
					<Col>{record.issueStatus!?.name}</Col>
				</Row>
			)
		},
		{
			title: '№',
			dataIndex: 'number',
			fixed: 'left',
			width: 60
		},
		{
			dataIndex: 'dueDate',
			fixed: 'left',
			title: 'Дата устранения',
			width: 120,
			render: (_, record) => (
				<div
					style={
						record.issueStatus.identityName === 'closed'
							? dayjs(dayjs(record!?.dueDate!).format('YYYY-MM-DD')).isBefore(
									dayjs(record.closedAt!).format('YYYY-MM-DD'),
									'day'
							  )
								? { color: '#ff0000', fontWeight: 'bold' }
								: { color: '#444' }
							: dayjs(record!?.dueDate!).isBefore(dayjs(), 'day')
							? { color: '#ff0000', fontWeight: 'bold' }
							: { color: '#444' }
					}
				>
					{record?.dueDate !== null && dayjs(record?.dueDate).isValid()
						? dayjs(record?.dueDate).format('DD.MM.YYYY')
						: '-'}
				</div>
			)
		},
		{
			dataIndex: 'contractor',
			fixed: 'left',
			title: 'Генподрядчик',
			ellipsis: {
				showTitle: true
			},
			width: 150,
			render: (_, record) => record!?.contractor! && record!?.contractor!?.name!
		},
		{
			title: 'Предписание',
			align: 'center',
			width: 90,
			render: (_, record) =>
				record.ordinance !== null && (
					<Tooltip
						title={`Предписание №${
							record.ordinance!?.name !== null ? record.ordinance!?.name : record.ordinance!?.number
						} от ${dayjs(record.ordinance.createdAt).format('DD.MM.YYYY')}г.`}
					>
						<Button
							icon={<FileTextOutlined rev={undefined} />}
							type="text"
							block
							onClick={() => openOrdinance(record.ordinance!?.id!)}
							disabled={!pagePermissions.ordinanceView}
						/>
					</Tooltip>
				)
		},
		{
			dataIndex: 'checkListItem',
			title: 'Чек-лист',
			align: 'center',
			width: 90,
			render: (_, record) =>
				record.checkListItem !== null && (
					<Tooltip title={`Чек-лист №${record.checkListItem.checkListNumber}`}>
						<Button
							icon={<ATIconCheckList style={{ fontSize: 18 }} />}
							type="text"
							block
							onClick={() => {
								setChecklistItemId(record.checkListItem?.id!)
								dispatch(setCurrentChecklistId(record.checkListItem?.checkListId!))
								setShowChecklistDrawer(true)
							}}
						/>
					</Tooltip>
				)
		},
		{
			dataIndex: 'createdAt',
			title: 'Создано',
			width: 120,
			sorter: false,
			render: (_, record) => dayjs(record!?.createdAt!).format('DD.MM.YYYY HH:mm')
		},
		{
			dataIndex: ['workPackage', 'name'],
			title: 'Пакет СМР',
			width: 90
		},
		{
			dataIndex: 'issueType',
			title: 'Тип',
			width: 90,
			render: (_, record) => issuesElementTranslate(record!?.issueType!?.name!)
		},
		{
			dataIndex: 'issueSubType',
			title: 'Подтип',
			ellipsis: {
				showTitle: true
			},
			width: 120,
			render: (_, record) => record!?.issueSubType! && record!?.issueSubType!?.name!
		},
		{
			dataIndex: 'issueViolationType',
			title: 'Тип нарушения',
			ellipsis: {
				showTitle: true
			},
			width: 120,
			render: (_, record) => record!?.violationType! && record!?.violationType!?.name!
		},
		{
			key: 'title',
			dataIndex: 'title',
			title: 'Наименование',
			width: 200,
			sorter: true
		},
		{
			dataIndex: 'subContractor',
			title: 'Субподрядчик',
			ellipsis: {
				showTitle: true
			},
			width: 100,
			render: (_, record) => record?.subcontractor && record?.subcontractor?.name
		},
		{
			key: 'owner',
			dataIndex: 'owner',
			title: 'Автор',
			ellipsis: {
				showTitle: true
			},
			width: 150,
			sorter: true,
			render: (_, record) =>
				record!?.owner! && record!?.owner!?.name!?.replace(/([a-zа-я])([A-ZА-Я])/g, '$1 $2')
		},
		{
			title: (
				<Tooltip title="Вложения">
					<PushpinFilled rev={undefined} />
				</Tooltip>
			),
			width: 50,
			align: 'center',
			render: (_, record) => <div className="at-graybox">{record!?.attachmentsCount!}</div>
		},
		// {
		// 	title: 'Bim360',
		// 	fixed: 'right',
		// 	align: 'center',
		// 	width: 60,
		// 	render: (_, record) => record!?.prefix !== null && <LockOutlined rev={undefined} />
		// },
		{
			title: 'М',
			fixed: 'right',
			align: 'center',
			width: 70,
			render: (_, record) =>
				record!?.isMobile && (
					<Tooltip title="Создано в мобильном устройстве">
						<Tag>mobile</Tag>
					</Tooltip>
				)
		},
		{
			width: currentUser?.isAdmin ? 80 : 1,
			align: 'center',
			render: (_, record) =>
				currentUser?.isAdmin && (
					<Tooltip title="Удалить" placement="left">
						<Popconfirm
							title="Удалить?"
							placement="topRight"
							onConfirm={async () =>
								await deleteIssue(record!?.id!)
									.then(async () => {
										message.success(`Замечание №${record!?.number!} успешно удалено`)
										queryClient.invalidateQueries(['issuesList'])
									})
									.catch((error: AxiosError) => {
										const data = error!?.response!?.data! as IErrorDetail
										message.error(data.detail)
									})
							}
							okButtonProps={{ danger: true }}
							okText="Да"
							cancelText="Отмена"
						>
							<Button icon={<DeleteOutlined />} danger />
						</Popconfirm>
					</Tooltip>
				),
			fixed: 'right'
		}
	])

	const initColumns: IColumns[] = issuesColumns.map((item, index) => ({
		key: index.toString()!,
		title: item.title?.toString()!,
		fixed: typeof item.fixed !== 'undefined'
	}))

	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Замечания' }))
		if (parsedConf === null || parsedConf!?.length === 0) {
			setShowColumns(initColumns)
			localStorage.setItem(
				'issuesPageColumns',
				JSON.stringify(
					initColumns.filter(col => !col.fixed && !col.title.toString().includes('object'))
				)
			)
		} else {
			const cols = [...initColumns.filter(col => col.fixed === true), ...JSON.parse(conf!)]
			setShowColumns(cols)
		}
	}, [])

	// useEffect(() => {
	// 	if (issueId) setDrawerVisible(true)
	// }, [issueId])

	useEffect(() => {
		const rows = issuesList?.filter(issue => selectedIssuesKeys.includes(issue.id!))
		setSelectedIssuesRows(rows!)
	}, [selectedIssuesKeys])

	useEffect(() => {
		setSelectedIssuesRows([])
		setSelectedIssuesKeys([])
		setSelectedIssue(null)
		setCompareIssue(null)
		// setHasFilters(false)
	}, [project])

	const handleResize =
		(index: number, col: ColumnType<IIssue>) =>
		(_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
			if (!col.fixed) {
				const minWidth = 200
				const maxWidth = 1000
				let colWidth: number = size.width
				if (size.width <= minWidth) colWidth = minWidth
				else if (size.width >= maxWidth) colWidth = maxWidth
				else colWidth = size.width
				const newColumns = [...issuesColumns]
				newColumns[index] = {
					...newColumns[index],
					width: colWidth,
					ellipsis: colWidth < 300 ? true : false
				}
				setIssuesColumns(newColumns)
			}
		}

	const onIssueOpen = (issue: IIssue) => {
		navigate(`/control/issues/${issue.id}`)
		// setSelectedIssue(issue)
		// setDrawerVisible(true)
	}

	const onDrawerClose = (show: boolean, mustUpdate: boolean) => {
		if (issueId) {
			navigate('/control/issues')
		}
		setIsLoading(true)
		setDrawerVisible(show)
		setSelectedIssue(null)
		setIsLoading(false)
		if (mustUpdate) {
			queryClient.invalidateQueries(['issuesList'])
		}
	}

	const onIssueOrdinanceClose = (mustUpdate: boolean) => {
		setSelectedIssuesKeys([])
		setIssueOrdinanceDrawer(false)
		setCompareIssue(null)
		dispatch(resetDto())
		if (mustUpdate) {
			queryClient.invalidateQueries(['issuesList'])
		}
	}

	const onColumnsConfig = (cols: IColumns[]) => {
		setShowColumns([...initColumns.filter(col => col.fixed === true), ...cols])
	}

	const onPagination = (page: number) => {
		dispatch(setCurrentPage(page))
	}

	const openOrdinance = (ordinaceId: string) => {
		setSelectedOrdinance(ordinaceId)
		setShowOrdinanceDetail(true)
	}

	const closeOrdinance = () => {
		setSelectedOrdinance(null)
		setShowOrdinanceDetail(false)
	}

	const onSearch = (searchValue: string | undefined) => {
		const filters = { ...issuesFilters, search: searchValue! }
		dispatch(setIssueFilters({ filters }))
		dispatch(setCurrentPage(1))
	}

	const mergeColumns: any = issuesColumns!
		?.filter(col => showColumns!?.find(i => i.title === col.title) !== undefined)
		.map((col, index) => ({
			...col,
			onHeaderCell: (column: ColumnType<IIssue>) => ({
				width: (column as ColumnType<IIssue>).width,
				onResize: handleResize(index, col)
			})
		}))

	// const checkPermissions = (currentPermits: string[]) => {
	// 	for (let i = 0; i < currentPermits.length; i++) {
	// 		if (permissions?.some((el: IPermission) => el.name === currentPermits[i])) {
	// 			return true
	// 		}
	// 	}
	// 	return false
	// }

	const compareRow = (record: IIssue): boolean => {
		return (
			record.contract === null ||
			record.buildingPermit === null ||
			record.contactPerson === null ||
			(compareIssue !== null &&
				(record.issueType?.id !== compareIssue.issueType?.id ||
					record.issueSubType?.id !== compareIssue.issueSubType?.id ||
					record.workPackage?.id !== compareIssue.workPackage?.id ||
					record.contract?.id !== compareIssue.contract?.id ||
					record.contractor!?.id !== compareIssue.contractor.id ||
					record.subcontractor?.id !== compareIssue.subcontractor?.id ||
					record.buildingPermit?.id !== compareIssue.buildingPermit?.id ||
					record.contactPerson?.id !== compareIssue.contactPerson?.id ||
					record.issueStatus?.identityName === compareIssue.issueStatus?.id ||
					record.violationType?.id !== compareIssue.violationType?.id)) ||
			record.ordinance! !== null ||
			record.issueStatus?.identityName !== 'open' ||
			!pagePermissions.ordinanceCreate
		)
	}

	return (
		<>
			<IssueDrawer
				drawerOpen={drawerVisible}
				currentIssue={selectedIssue}
				onDrawerClose={onDrawerClose}
				updateList={() => queryClient.invalidateQueries(['issuesList'])}
				setDrawerVisibleClose={() => setDrawerVisible(false)}
				setDrawerVisibleOpen={() => setDrawerVisible(true)}
			/>

			{showOrdinanceDetail && selectedOrdinance !== null && (
				<OrdinanceDrawer
					ordinanceId={selectedOrdinance}
					open={showOrdinanceDetail}
					onClose={closeOrdinance}
				/>
			)}

			{showChecklistDrawer && (
				<ChecklistsDrawer
					open={showChecklistDrawer}
					onClose={() => {
						setShowChecklistDrawer(false)
					}}
					// onRefreshData={() => {}}
					// checkPermissions={checkPermissions}
					checklistItemId={checklistItemId!}
				/>
			)}

			{selectedIssuesRows?.length > 0 && (
				<IssueOrdinance
					open={issueOrdinanceDrawer}
					issueRows={selectedIssuesRows!}
					onClose={onIssueOrdinanceClose}
				/>
			)}

			<IssuesFilters open={showIssuesFilter} onClose={() => setShowIssuesFilter(false)} />

			<div id="issues" className="dashboard__block">
				<Row id="issues-controls" justify="space-between" style={{ marginBottom: '1rem' }}>
					<Col>
						<Row gutter={8} justify="start">
							<Col>
								<ColumnConfig
									onChange={onColumnsConfig}
									columns={initColumns}
									localstore={'issuesPageColumns'}
								/>
							</Col>
							<Col>
								<Tooltip title="Обновить">
									<Button
										size="middle"
										onClick={() => queryClient.invalidateQueries(['issuesList'])}
										icon={<SyncOutlined spin={isLoading} rev={undefined} />}
									/>
								</Tooltip>
							</Col>
							<Col>
								<Tooltip title="Фильтры">
									<Button
										type={
											Object.entries(issuesFilters).some(
												x => x[0] !== 'search' && typeof x[1] !== 'undefined'
											)
												? 'primary'
												: 'default'
										}
										size="middle"
										onClick={() => {
											setShowIssuesFilter(true)
										}}
										icon={<FilterOutlined />}
									/>
								</Tooltip>
							</Col>
							<Col>
								<Input.Search
									allowClear
									addonAfter={false}
									placeholder="Поиск..."
									defaultValue={issuesFilters.search}
									onSearch={value => onSearch(value)}
									style={{ width: 300 }}
								/>
							</Col>
						</Row>
					</Col>
					<Col>
						<Row gutter={8}>
							{currentUser?.contractorId !== null && (
								<Col>
									<IssueReport />
								</Col>
							)}
							{pagePermissions.ordinanceCreate && (
								<Col>
									<Tooltip
										title={
											!pagePermissions.ordinanceCreate
												? 'У вас не достаточно прав для формирования предписаний'
												: selectedIssuesKeys.length === 0 &&
												  'Необходимо выбрать минимум одно замечание'
										}
									>
										<Button
											type="primary"
											size="middle"
											disabled={selectedIssuesKeys.length === 0 || !pagePermissions.ordinanceCreate}
											onClick={() => setIssueOrdinanceDrawer(true)}
											icon={<FormOutlined />}
										>
											Сформировать предписание
										</Button>
									</Tooltip>
								</Col>
							)}
							{pagePermissions.canCreate && (
								<Col>
									<Tooltip
										title={
											!pagePermissions.canCreate
												? 'У вас не достаточно прав для создания замечаний'
												: (project === null || project?.project1C?.id === undefined) &&
												  'Не заполнен проект 1С'
										}
									>
										<Button
											disabled={
												project === null ||
												project?.project1C?.id === undefined ||
												!pagePermissions.canCreate ||
												selectedIssuesKeys!?.length > 0
											}
											size="middle"
											onClick={() => setDrawerVisible(true)}
											icon={<FileExclamationOutlined />}
										>
											Создать замечание
										</Button>
									</Tooltip>
								</Col>
							)}
						</Row>
					</Col>
				</Row>
				<div id="resizable-table">
					<Table
						dataSource={issuesList!}
						columns={mergeColumns}
						loading={{ indicator: <SyncOutlined spin />, spinning: isFetching }}
						size="small"
						onChange={(pagination, filters, FilterValue, sorter) => {
							const titleDirection = sortVariation(
								(FilterValue as any).columnKey !== 'title' ? undefined : (FilterValue as any).order
							)
							const ownerDirection = sortVariation(
								(FilterValue as any).columnKey !== 'owner' ? undefined : (FilterValue as any).order
							)
							dispatch(
								setIssueSort({
									titleSort: titleDirection,
									ownerSort: ownerDirection
								})
							)
						}}
						pagination={{
							current: issuesListState.currentPage,
							pageSize: 50,
							hideOnSinglePage: false,
							showSizeChanger: false,
							total: issuesListState.totalItems,
							onChange: onPagination
						}}
						rowKey={record => record.id!}
						rowSelection={{
							hideSelectAll: true,
							columnWidth: !pagePermissions.ordinanceCreate ? 0 : 32,
							selectedRowKeys: selectedIssuesKeys,
							onChange: (selectedRowKeys: React.Key[], selectedRows) => {
								setSelectedIssuesKeys(selectedRowKeys)
								if (compareIssue === null) {
									setCompareIssue(selectedRows[0])
								}
								if (compareIssue !== null && selectedRowKeys.length === 0) {
									setCompareIssue(null)
								}
							},
							renderCell: (checked, record, index, originNode) => (
								<Tooltip
									title={
										record.issueStatus?.identityName === 'open' &&
										record.ordinance === null &&
										compareRow(record)
											? record.issueType.name.includes('Приемка УК')
												? 'По замечанию с типом Приемка УК создание предписания не предусмотрено'
												: 'Для создания Предписания необходимо заполнить все данные в Замечании'
											: ''
									}
									placement="right"
								>
									{originNode}
								</Tooltip>
							),
							getCheckboxProps: record => ({
								disabled: compareRow(record) || record.issueType.name.includes('Приемка УК')
							})
						}}
						components={{
							header: {
								cell: ResizableTitle
							}
						}}
						tableLayout="fixed"
						scroll={{ y: '60vh' }}
						rowClassName={record =>
							'at-issue-row ' +
							' ' +
							(compareIssue !== null &&
							(record.issueType?.id !== compareIssue.issueType?.id ||
								record.issueSubType?.id !== compareIssue.issueSubType?.id ||
								record.workPackage?.id !== compareIssue.workPackage?.id ||
								record.contract?.id !== compareIssue.contract?.id ||
								record.contractor!?.id !== compareIssue.contractor.id ||
								record.subcontractor?.id !== compareIssue.subcontractor?.id ||
								record.buildingPermit?.id !== compareIssue.buildingPermit?.id ||
								record.contactPerson?.id !== compareIssue.contactPerson?.id ||
								record.violationType?.id !== compareIssue.violationType?.id ||
								record.ordinance !== null)
								? 'at-issue-row--disabled'
								: '')
						}
						onRow={record => ({
							onDoubleClick: e => {
								e.preventDefault()
								if (record.id !== null) {
									onIssueOpen(record!)
									setDrawerVisible(true)
								}
							}
						})}
						locale={{
							triggerAsc: 'Сортировать по возрастанию',
							triggerDesc: 'Сортировать по убыванию',
							cancelSort: 'Отменить сортировку'
						}}
					/>
				</div>
			</div>
		</>
	)
}

const sortVariation = (order: string) => {
	switch (order) {
		case 'ascend':
			return 'ASC'
		case 'descend':
			return 'DESC'
		default:
			return undefined
	}
}

const ResizableTitle = (
	props: React.HTMLAttributes<any> & {
		onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void
		width: number
	}
) => {
	const { onResize, width, ...restProps } = props

	if (!width) {
		return <th {...restProps} />
	}

	return props.title !== 'undefined' ? (
		<Resizable
			width={width}
			height={0}
			handle={
				<span
					className="react-resizable-handle"
					onClick={e => {
						e.stopPropagation()
					}}
				/>
			}
			onResize={onResize}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	) : null
}

export default IssuesPage
