import { Badge, Card, Descriptions, Empty, Tag, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import { IOrdinanceFineInfo } from 'interfaces/IOrdinance'
import { FC } from 'react'
import { FineCard } from 'widgets/fine-card'

const { Text } = Typography

interface IProps {
	ordinanceFineInfo: IOrdinanceFineInfo[]
	onUpdate: () => void
}

export const Fines: FC<IProps> = ({ ordinanceFineInfo, onUpdate }) => {
	return (
		<>
			<FineCard type="create" onUpdate={onUpdate} />
			{/* <OrdinanceFineCard ordinanceFine={{}} ordinance={ordinance} /> */}
			{ordinanceFineInfo! && ordinanceFineInfo!?.length > 0 ? (
				ordinanceFineInfo?.map((ordinanceFine, index) => (
					<Card className="at-workcard">
						{ordinanceFine.isRepeated && (
							<Tag style={{ fontSize: '14px', marginBottom: '1rem' }} color="red">
								Повторный
							</Tag>
						)}
						<Badge.Ribbon
							text={
								<Tooltip title="Статус штрафа">{ordinanceFine.ordinanceFineStatus?.name!}</Tooltip>
							}
							// color={badgeStatusColor(issue!?.issueStatus!?.identityName!)}
						>
							<Descriptions
								layout="horizontal"
								column={12}
								bordered
								className="at-descriptions"
								labelStyle={{
									fontWeight: 'bold',
									width: '20%',
									alignItems: 'center',
									padding: '4px 8px'
								}}
								contentStyle={{
									width: '80%',
									padding: '4px 8px'
								}}
							>
								<Descriptions.Item label="Номер:" span={12}>
									<Text>{ordinanceFine.name ? ordinanceFine.name : ordinanceFine.number}</Text>
								</Descriptions.Item>

								<Descriptions.Item label="Повторный:" span={12}>
									<Text>{ordinanceFine.isRepeated ? 'Да' : 'Нет'}</Text>
								</Descriptions.Item>

								<Descriptions.Item label="Владелец:" span={12}>
									<Text>{ordinanceFine.createdBy?.name}</Text>
								</Descriptions.Item>

								<Descriptions.Item label="Генподрядчик" span={12}>
									<Text>{ordinanceFine.contractor?.name}</Text>
								</Descriptions.Item>

								<Descriptions.Item label="Договор:" span={12}>
									<Text>{`${ordinanceFine.contract?.name}`}</Text>
								</Descriptions.Item>

								<Descriptions.Item label="Дата создания:" span={12}>
									<Text>
										{dayjs(ordinanceFine.createdAt).isValid()
											? dayjs(ordinanceFine.createdAt).format('DD.MM.YYYY')
											: '-'}
									</Text>
								</Descriptions.Item>

								<Descriptions.Item label="Сумма штрафа:" span={12}>
									<Text>{ordinanceFine.sum}</Text>
								</Descriptions.Item>
							</Descriptions>
						</Badge.Ribbon>
					</Card>
				))
			) : (
				<Empty />
			)}
		</>
	)
}
