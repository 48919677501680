import {
	Button,
	Col,
	Form,
	Input,
	Popconfirm,
	Row,
	Select,
	Table,
	Tag,
	Tooltip,
	message
} from 'antd'
import { FC, useState } from 'react'
import {
	EditOutlined,
	SaveOutlined,
	StopOutlined,
	DeleteOutlined,
	SyncOutlined
} from '@ant-design/icons'
import { useAppSelector } from 'hooks/appReduxHook'
import { useQueryClient } from '@tanstack/react-query'
import { useViolations } from 'hooks/useViolations'
import { AddViolation } from '../features'
import { IViolation, IViolationDto, violationsService } from 'services/violationsService'

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	dataIndex: string
	editing: boolean
	inputType: string
	children: React.ReactNode
}

const EditableCell: FC<EditableCellProps> = ({
	dataIndex,
	editing,
	inputType,
	children,
	...restProps
}) => {
	const { violationTypes } = useAppSelector(state => state.environment)

	const inputNode =
		inputType === 'select' ? (
			<Select
				mode="multiple"
				optionFilterProp="children"
				filterOption={(input, option) =>
					(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
				}
				filterSort={(a, b) =>
					(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
				}
				options={violationTypes
					?.filter(o => !o.isDeleted)
					.map(o => ({
						key: o.id,
						value: o.id,
						label: o.name
					}))}
			/>
		) : (
			<Input />
		)
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item name={dataIndex} style={{ margin: 0 }}>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	)
}

export const ViolationsGuide: FC = () => {
	const queryClient = useQueryClient()
	const [popOpen, setPopOpen] = useState(false)
	const { data, isFetching } = useViolations()
	const [violationForm] = Form.useForm()
	const [editingKey, setEditingKey] = useState('')
	const isEditing = (record: IViolation) => record.id === editingKey
	const columns = [
		{
			dataIndex: 'name',
			title: 'Наименование',
			editable: true
		},
		{
			dataIndex: 'violationTypeId',
			width: '60%',
			title: 'Типы нарушений',
			render: (_: any, record: IViolation) =>
				record.violationTypes.map(item => (
					<Tag
						color={'#1677ff'}
						style={{
							fontSize: 14,
							padding: 4,
							margin: 3,
							color: '#f0f0f0'
						}}
					>
						{item.name}
					</Tag>
				)),
			editable: true
		},
		{
			width: 64,
			align: 'center',
			render: (_: any, record: IViolation) => {
				const editable = isEditing(record)
				return editable ? (
					<Row gutter={8} wrap={false}>
						<Col>
							<Tooltip title="Сохранить">
								<Button icon={<SaveOutlined />} type="primary" onClick={() => save(record)} />
							</Tooltip>
						</Col>
						<Col>
							<Tooltip title="Отмена">
								<Button icon={<StopOutlined />} onClick={() => cancel()} />
							</Tooltip>
						</Col>
						<Col>
							<Popconfirm
								open={popOpen}
								title="Удалить?"
								placement="topRight"
								onConfirm={() => markIsDeleted(record.id)}
								onCancel={() => {
									setPopOpen(false)
									cancel()
								}}
								okButtonProps={{ danger: true }}
								okText="Да"
								cancelText="Отмена"
							>
								<Button
									icon={<DeleteOutlined />}
									danger
									style={{ marginRight: 8 }}
									onClick={() => setPopOpen(true)}
								/>
							</Popconfirm>
						</Col>
					</Row>
				) : (
					<Row gutter={8} wrap={false}>
						<Col>
							<Tooltip title="Редактировать">
								<Button
									icon={<EditOutlined />}
									disabled={editingKey !== ''}
									onClick={() => edit(record)}
								/>
							</Tooltip>
						</Col>
					</Row>
				)
			}
		}
	]

	const save = async (data: IViolation) => {
		const dto: IViolationDto = {
			id: data.id,
			name: violationForm.getFieldValue('name'),
			violationTypeId: violationForm.getFieldValue('violationTypeId')
		}
		await violationsService
			.update(dto)
			.then(() => {
				queryClient.invalidateQueries({ queryKey: ['violations'] })
				cancel()
			})
			.catch(() => message.error('Произошла ошибка.'))
	}

	const edit = (record: Partial<IViolation>) => {
		violationForm.setFieldsValue({
			name: record.name,
			violationTypeId: record.violationTypes?.flatMap(item => item.id)
		})
		setEditingKey(record.id!)
	}

	const markIsDeleted = async (id: string) => {
		await violationsService
			.delete(id)
			.then(() => {
				queryClient.invalidateQueries({ queryKey: ['violations'] })
				cancel()
				setPopOpen(false)
				message.success('Нарушение успешно удалено')
			})
			.catch(() => {
				message.error('Во время удаления произошла ошибка')
				cancel()
				setPopOpen(false)
			})
	}

	const cancel = () => {
		setEditingKey('')
	}

	const mergedColumns = columns.map(col => {
		if (!col.editable) {
			return col
		}
		return {
			...col,
			onCell: (record: IViolation) => ({
				dataIndex: col.dataIndex,
				inputType: col.dataIndex === 'violationTypeId' ? 'select' : 'text',
				editing: isEditing(record)
			})
		}
	})

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Row gutter={8}>
				<Col>
					<AddViolation />
				</Col>
				<Col>
					<Tooltip title="Обновить">
						<Button
							icon={<SyncOutlined spin={isFetching} />}
							onClick={() => queryClient.invalidateQueries({ queryKey: ['violations'] })}
						/>
					</Tooltip>
				</Col>
			</Row>
			<Form form={violationForm} component={false}>
				<Table
					style={{ marginTop: '1rem' }}
					loading={isFetching}
					components={{
						body: {
							cell: EditableCell
						}
					}}
					className="app-table-types"
					rowKey={record => record.id}
					size="small"
					pagination={false}
					bordered
					dataSource={data}
					columns={mergedColumns as any}
				/>
			</Form>
		</div>
	)
}
