import {
	Card,
	Col,
	DatePicker,
	Descriptions,
	Divider,
	Form,
	FormInstance,
	Input,
	message,
	Row,
	Select,
	Tag
} from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import dayjs from 'dayjs'
import { IssueSource } from 'enums'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IContract } from 'interfaces/IContract'
import { IContractor } from 'interfaces/IEnvironment'
import IssueDrawer from 'pages/issuesPage/issueDrawer'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import { FC, useEffect, useState } from 'react'
import { addIssueInItem } from 'services/ChecklistsService'
import { getContractors, getContractsByProject1CId } from 'services/IssuesService'
import { DATE_FORMAT, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setCurrentIssueId, setCurrentItem } from 'store/checklistsSlice'
import { YandexTrackerButton } from 'widgets/yandex-tracker/ui/yandex-tracker-button'
import AssignedTo from '../components/AssignedTo'
import AddFileModal from '../modals/AddFileModal'
import Sections from '../sections/Sections'
const { Option } = Select

const range = (start: number, end: number) => {
	const result = []
	for (let i = start; i < end; i++) {
		result.push(i)
	}
	return result
}

const disabledDateTime = () => ({
	disabledHours: () => range(0, 24).filter(r => r < 8 || r > 18)
})

interface IProps {
	// currentTemplate: ITemplate | null
	onUpdateChecklist: (updateData: any, keepOpen?: boolean) => void
	onReloadChecklist: () => void
	form: FormInstance<any>
	// checkPermissions: (currentPermits: string[]) => boolean
}

const ChecklistForm: FC<IProps> = ({
	// currentTemplate,
	onUpdateChecklist,
	onReloadChecklist,
	form
	// checkPermissions
}) => {
	const checkPermissions = useCheckPermissions()
	const dispatch = useAppDispatch()
	const [projectTypes, setProjectTypes] = useState(['УКС НДК', 'УК КС'])
	const [currentProjectType, setCurrentProjectType] = useState(projectTypes[0])
	const {
		user: currentUser,
		permissions,
		contractors: currentContractors, //гена
		contracts, // договор
		project,
		workPackages
	} = useAppSelector(state => state.environment)
	const [contractors, setContractors] = useState(currentContractors)
	const [contractsCurrent, setContractsCurrent] = useState(contracts)
	const [fileModalVisible, setFileModalVisible] = useState<boolean>(false)
	const [issueModalVisible, setIssueModalVisible] = useState<boolean>(false)
	const [currentItemId, setCurrentItemId] = useState<string>('')
	const status = useAppSelector(state => state.checklists.init.currentChecklist?.status)
	const [allDisabled, setAllDisabled] = useState<boolean>(status === 2 || status === 3)
	const [isValidCreator, setIsValidCreator] = useState<boolean>(false)
	const { currentChecklist, currentItem, currentIssueId } = useAppSelector(
		state => state.checklists.init
	)
	const { isCreatedChecklist } = useAppSelector(state => state.checklists.init)
	const [selectedContractorId, setSelectedContractorId] = useState<string | null>(null)
	const [subContractors, setSubContractors] = useState<IContractor[]>([])
	const subContractor = Form.useWatch('contractorId', form)
	const canSetAssignedToUkKC = checkPermissions([PERMISSIONS.ChecklistsEditAssignedToUkKc])
	const canSetAssignedToUKNDK = checkPermissions([PERMISSIONS.ChecklistsEditAssignedToUkNdk])
	const ChecklistPlanedDate = checkPermissions([PERMISSIONS.ChecklistPlanedDate])
	const blocked = [2, 3, 4]
	const isCreated = currentChecklist?.id === '00000000-0000-0000-0000-000000000000'
	const canEditPlannedDate =
		currentUser?.isAdmin || currentUser?.id === currentChecklist?.owner?.id || ChecklistPlanedDate

	useEffect(() => {
		if (!isCreated && currentChecklist?.contractor?.id !== null) {
			setSelectedContractorId(currentChecklist?.contractor?.id!)
		} else {
			setSelectedContractorId(null)
			setSubContractors([])
		}
	}, [currentChecklist])

	const onUploadClickHandler = (
		e: React.MouseEvent<HTMLElement>,
		id: string | null | undefined
	) => {
		e.stopPropagation()
		setFileModalVisible(true)
		id && setCurrentItemId(id)
	}
	const onClose = (x: boolean, mustUpdate: boolean, issueId: string | undefined) => {
		setIssueModalVisible(x)
		dispatch(setCurrentItem(null))
		dispatch(setCurrentIssueId(''))

		if (issueId && currentItem?.id) {
			const isExist = currentItem.issues.some(issue => issue.id === issueId)
			if (!isExist) {
				addIssueInItem(currentItem?.id, [issueId])
					.then(() => message.success('Замечание прикреплено.'))
					.then(() => onReloadChecklist())
					.catch(err => message.error('Ошибка прикрепления замечания ' + err))
			} else {
				onReloadChecklist()
			}
		}
	}

	useEffect(() => {
		if (currentChecklist) setCurrentProjectType(projectTypes[currentChecklist?.templateType])
	}, [currentChecklist])

	useEffect(() => {
		// Право редактирования
		if (!allDisabled) {
			if (!isCreatedChecklist) {
				if (currentChecklist?.templateType === 0) {
					!checkPermissions([PERMISSIONS.ChecklistsEditUksNdc]) && setAllDisabled(true)
				} else if (currentChecklist?.templateType === 1) {
					!checkPermissions([PERMISSIONS.ChecklistsEditUkKc]) && setAllDisabled(true)
				}
			}
		}
		if (currentChecklist?.project?.id !== project.id) {
			getContractors(currentChecklist?.project?.id).then(data => setContractors(data))
		}
	}, [currentChecklist])

	useEffect(() => {
		if (!currentUser!?.isAdmin! && blocked.some(x => x === status)) {
			setAllDisabled(true)
		} else {
			setAllDisabled(false)
		}
	}, [status, allDisabled])

	useEffect(() => {
		if (!isCreated && currentChecklist) {
			if (
				currentChecklist?.templateType === 0 &&
				!checkPermissions([PERMISSIONS.ChecklistsCreateUksNdc])
			) {
				setIsValidCreator(false)
			}
			if (
				currentChecklist?.templateType === 1 &&
				!checkPermissions([PERMISSIONS.ChecklistsCreateUkKc])
			) {
				setIsValidCreator(false)
			}

			if (currentUser?.isAdmin) setIsValidCreator(false)

			!isValidCreator && setIsValidCreator(true)
		}
	}, [currentChecklist])

	const checkLocalDoorSections = (item: number) => {
		const check = !!currentChecklist!?.doorSections?.length
			? currentChecklist.selectedDoorSections
					.filter(s => !currentChecklist!?.doorSections!?.some(c => c === s))
					.some(i => i === item + 1)
			: currentChecklist!?.selectedDoorSections!?.some(i => i === item + 1)
		return check
	}

	const getSubContractors = (contracts: IContract[]) => {
		setSubContractors([])
		let subs: IContractor[] = []
		if (contracts.length > 0) {
			const objects: IContractor[] = contracts
				.filter((contract: IContract) => contract!?.contractor!?.id! === selectedContractorId!)
				.flatMap((contract: IContract) => contract!?.subContractor!)
			objects.forEach(obj => {
				if (obj !== null)
					if (!subs.some(item => item?.id! === obj?.id!)) {
						subs.push(obj)
					}
			})

			// if (typeof subContractors.find(sub => sub.id === selectedContractorId!) === 'undefined') {
			const selectedContractor = contractors?.find(ctr => ctr.id === selectedContractorId!) ?? null
			if (selectedContractor !== null) {
				subs.unshift(selectedContractor)
			}
			// }
		}
		return subs
	}

	const onContractorChange = (value: string) => {
		setSelectedContractorId(value)
		form.setFieldValue('subContractorId', null)
	}

	useEffect(() => {
		getContractsByProject1CId(currentChecklist?.project!?.project1C!?.id!).then(data =>
			setContractsCurrent(data)
		)
	}, [subContractor])
	useEffect(() => {
		const getContracts = async () => {
			if (contractsCurrent!) {
				const subs = getSubContractors(contractsCurrent!)
				if (subs) {
					setSubContractors(subs!)
				}
			}
		}
		getContracts()
	}, [selectedContractorId, contracts, contractors])
	useEffect(() => {
		console.log(currentProjectType)
	}, [currentProjectType])

	useEffect(() => {
		if (currentChecklist?.contractor?.id !== null) {
			setSelectedContractorId(currentChecklist?.contractor?.id!)
			if (contractsCurrent!) {
				const subs = getSubContractors(contractsCurrent!)
				if (subs) {
					setSubContractors(subs!)
				}
			}
		}
	}, [currentChecklist])

	return (
		<>
			<IssueDrawer
				updateList={onReloadChecklist}
				drawerOpen={issueModalVisible}
				currentIssue={currentIssueId || null}
				onDrawerClose={onClose}
				issueSource={IssueSource.fromChecklistItem}
				checklistData={{
					contractorId: form.getFieldValue('contractorId') || currentChecklist?.contractor?.id,
					subContractorId:
						form.getFieldValue('subContractorId') || currentChecklist?.subContractor?.id,
					templateTitle: currentChecklist?.checkListTemplate.title,
					workPackageId: currentIssueId.length
						? undefined
						: form.getFieldValue('workPackageId') || currentChecklist?.workPackageId,
					locationDescription: form.getFieldValue('location') || currentChecklist?.location
				}}
				checklistType={currentChecklist?.templateType}
			/>
			<Card className="at-workcard" style={{ width: '100%' }} title="Основные параметры">
				<AddFileModal
					fileModalVisible={fileModalVisible}
					setFileModalVisible={setFileModalVisible}
					currentItemId={currentItemId}
				/>
				<Row justify="space-between" style={{ marginBottom: 5 }}>
					<Col>
						{currentChecklist?.isMobile! && (
							<Tag style={{ fontSize: '16px' }} color="blue">
								mobile
							</Tag>
						)}
					</Col>
					<Col>
						<YandexTrackerButton text={'Сообщить об ошибке'} />
					</Col>
				</Row>
				{currentChecklist && (
					<Descriptions
						// title="Основные параметры:"
						column={12}
						bordered
						labelStyle={{
							fontWeight: 'bold',
							width: '20%',
							alignItems: 'center',
							padding: '2px 8px'
						}}
						contentStyle={{
							width: '80%',
							padding: '0px 8px'
						}}
						// className="at-descriptions"
					>
						{currentProjectType === 'УКС НДК' && (
							<Descriptions.Item label="Шифр" span={12}>
								{!currentUser?.isAdmin &&
								(currentUser?.id !== currentChecklist?.owner?.id || allDisabled) ? (
									<p style={{ padding: '4px', margin: 0 }}>{currentChecklist?.checkListCipher}</p>
								) : (
									<Form.Item
										name="checkListCipher"
										initialValue={currentChecklist?.checkListCipher}
									>
										<Input variant="borderless" disabled={allDisabled} allowClear />
									</Form.Item>
								)}
							</Descriptions.Item>
						)}
						{currentProjectType === 'УКС НДК' && (
							<Descriptions.Item label={<label>Пакет СМР</label>} span={12}>
								{!currentUser?.isAdmin &&
								(currentUser?.id !== currentChecklist?.owner?.id || allDisabled) ? (
									<p style={{ padding: '4px', margin: 0 }}>
										{workPackages?.find(wp => wp.id === currentChecklist?.workPackageId)?.name}
									</p>
								) : (
									<Form.Item
										name="workPackageId"
										initialValue={currentChecklist?.workPackageId}
										// rules={[{ required: true, message: 'Укажите пакет СМР' }]}
									>
										<Select
											variant="borderless"
											style={{ width: '100%', textOverflow: 'ellipsis' }}
										>
											{workPackages?.map((item, index) => (
												<Option key={index} value={item.id}>
													{item.name}
												</Option>
											))}
										</Select>
									</Form.Item>
								)}
							</Descriptions.Item>
						)}
						<Descriptions.Item
							label={
								<label>
									Название
									<span style={{ color: 'red' }}> *</span>
								</label>
							}
							span={12}
						>
							{!currentUser?.isAdmin &&
							(currentUser?.id !== currentChecklist?.owner?.id || allDisabled) ? (
								<p style={{ padding: '4px', margin: 0 }}>{currentChecklist?.title}</p>
							) : (
								<Form.Item
									name="title"
									rules={[{ required: true, message: 'Укажите название' }]}
									initialValue={currentChecklist?.title}
								>
									<Input variant="borderless" disabled={allDisabled} />
								</Form.Item>
							)}
						</Descriptions.Item>

						<Descriptions.Item label="Тип чек-листа" span={12}>
							<p style={{ padding: '4px', margin: 0 }}>{currentProjectType}</p>
						</Descriptions.Item>

						<Descriptions.Item label="Дата создания" span={12}>
							<p style={{ padding: '4px', margin: 0 }}>
								{currentChecklist.createdAt
									? dayjs(currentChecklist.createdAt).format(DATE_FORMAT.view)
									: '-'}
							</p>
						</Descriptions.Item>

						{currentProjectType === 'УК КС' && (
							<Descriptions.Item label="Владелец" span={12}>
								<p style={{ padding: '4px', margin: 0 }}>{currentChecklist?.owner?.name}</p>
							</Descriptions.Item>
						)}

						<Descriptions.Item
							label={
								<label>
									Описание
									<span style={{ color: 'red' }}> *</span>
								</label>
							}
							labelStyle={{ fontWeight: 'bold' }}
							span={12}
						>
							{!currentUser?.isAdmin &&
							(currentUser?.id !== currentChecklist?.owner?.id || allDisabled) ? (
								<p style={{ padding: '4px', margin: 0 }}>{currentChecklist?.description}</p>
							) : (
								<Form.Item
									name="description"
									initialValue={currentChecklist?.description}
									rules={[
										{ required: true, message: 'Укажите описание' },
										{ min: 3, message: 'Описание чек-листа минимум 3 символа' }
									]}
								>
									<Input.TextArea
										variant="borderless"
										style={{ width: '100%' }}
										disabled={allDisabled}
									/>
								</Form.Item>
							)}
						</Descriptions.Item>

						{currentProjectType === 'УКС НДК' && (
							<Descriptions.Item label="Объём" span={12}>
								{!currentUser?.isAdmin &&
								(currentUser?.id !== currentChecklist?.owner?.id || allDisabled) ? (
									<p style={{ padding: '4px', margin: 0 }}>{currentChecklist?.capacity}</p>
								) : (
									<Form.Item name="capacity" initialValue={currentChecklist?.capacity}>
										<Input variant="borderless" allowClear />
									</Form.Item>
								)}
							</Descriptions.Item>
						)}

						<Descriptions.Item
							label={
								<label>
									{currentProjectType === 'УК КС'
										? 'Ответственный представитель УК'
										: 'Ответственный'}
									<span style={{ color: 'red' }}> *</span>
								</label>
							}
							span={12}
						>
							{!currentUser?.isAdmin &&
							(allDisabled ||
								(((currentProjectType === 'УК КС' && !canSetAssignedToUkKC) ||
									(currentProjectType === 'УКС НДК' && !canSetAssignedToUKNDK)) &&
									currentUser?.id !== currentChecklist?.owner?.id)) ? (
								currentChecklist!?.assignedTo!?.map(a => (
									<p style={{ padding: '4px', margin: 0 }}>{a.title}</p>
								))
							) : (
								<AssignedTo form={form} onUpdateChecklist={onUpdateChecklist} allDisabled={false} />
							)}
						</Descriptions.Item>

						<Descriptions.Item
							label={
								<label>
									Генподрядчик
									<span style={{ color: 'red' }}> *</span>
								</label>
							}
							span={12}
						>
							{!currentUser?.isAdmin &&
							(currentUser?.id !== currentChecklist?.owner?.id || allDisabled) ? (
								<p style={{ padding: '4px', margin: 0 }}>{currentChecklist?.contractor?.name}</p>
							) : (
								<Form.Item
									name="contractorId"
									initialValue={currentChecklist?.contractor?.id!}
									rules={[{ required: true, message: 'Укажите подрядчика' }]}
								>
									<Select
										variant="borderless"
										showSearch
										optionFilterProp="children"
										onChange={value => onContractorChange(value)}
										disabled={allDisabled}
									>
										{contractors?.map(contractor => (
											<Option key={contractor?.id!} value={contractor?.id!}>
												{contractor?.name!}
											</Option>
										))}
									</Select>
								</Form.Item>
							)}
						</Descriptions.Item>

						<Descriptions.Item
							label={
								<label>
									Субподрядчик
									<span style={{ color: 'red' }}> *</span>
								</label>
							}
							span={12}
						>
							{!currentUser?.isAdmin &&
							(currentUser?.id !== currentChecklist?.owner?.id || allDisabled) ? (
								<p style={{ padding: '4px', margin: 0 }}>{currentChecklist?.subContractor?.name}</p>
							) : (
								<Form.Item
									name="subContractorId"
									initialValue={currentChecklist?.subContractor?.id!}
									rules={[{ required: true, message: 'Укажите субподрядчика' }]}
								>
									<Select
										variant="borderless"
										showSearch
										optionFilterProp="children"
										disabled={
											selectedContractorId === null || !subContractors.length || allDisabled
										}
									>
										{subContractors.map(subContractor => (
											<Option key={subContractor?.id!} value={subContractor?.id!}>
												{subContractor?.name!}
											</Option>
										))}
									</Select>
								</Form.Item>
							)}
						</Descriptions.Item>

						{currentProjectType !== 'УК КС' && (
							<Descriptions.Item
								label={
									<label>
										Локация
										<span style={{ color: 'red' }}> *</span>
									</label>
								}
								span={12}
							>
								{!currentUser?.isAdmin &&
								(currentUser?.id !== currentChecklist?.owner?.id || allDisabled) ? (
									<p style={{ padding: '4px', margin: 0 }}>{currentChecklist?.location}</p>
								) : (
									<Form.Item
										name="location"
										initialValue={currentChecklist?.location || 'Не указана'}
										rules={[{ required: true, message: 'Укажите локацию' }]}
									>
										<Input variant="borderless" disabled={allDisabled} allowClear />
									</Form.Item>
								)}
							</Descriptions.Item>
						)}

						{currentChecklist!?.bySection! && (
							<Descriptions.Item
								label={
									<label>
										Секции
										<span style={{ color: 'red' }}> *</span>
									</label>
								}
								span={12}
							>
								{allDisabled ||
								currentChecklist.owner?.id !== currentUser?.id ||
								(currentProjectType === 'УКС НДК' &&
									!checkPermissions([PERMISSIONS.ChecklistsEditUksNdc])) ||
								(currentProjectType === 'УК КС' &&
									!checkPermissions([PERMISSIONS.ChecklistsEditUkKc])) ? (
									<p style={{ padding: '4px', margin: 0 }}>
										{currentChecklist!?.doorSections!?.join(', ')!}
									</p>
								) : (
									<Form.Item
										name="doorSections"
										initialValue={currentChecklist?.doorSections!}
										rules={[{ required: true, message: 'Укажите секции' }]}
									>
										<Select
											variant="borderless"
											tagRender={tagRender}
											value={currentChecklist?.doorSections!}
											mode="multiple"
											options={Array.from(Array(currentChecklist!?.sectionCount!).keys()).map(
												item => ({
													value: item + 1,
													label: item + 1,
													disabled: checkLocalDoorSections(item)
												})
											)}
										/>
									</Form.Item>
								)}
							</Descriptions.Item>
						)}

						<Descriptions.Item
							label={
								<label>
									Плановая дата проверки
									<span style={{ color: 'red' }}> *</span>
								</label>
							}
							span={12}
						>
							{canEditPlannedDate && !allDisabled ? (
								<Form.Item
									name="plannedDate"
									rules={[{ required: true, message: 'Укажите плановую дату проверки' }]}
									initialValue={
										dayjs(currentChecklist?.plannedDate).isValid()
											? dayjs(currentChecklist?.plannedDate)
											: null
									}
								>
									<DatePicker
										variant="borderless"
										locale={locale}
										style={{ width: '100%' }}
										format={DATE_FORMAT.viewWithHours}
										showTime={true}
										hideDisabledOptions
										disabledDate={current => {
											let pastDate = dayjs().add(1, 'day').format(DATE_FORMAT.view)
											return current && current < dayjs(pastDate, DATE_FORMAT.view)
										}}
										disabledTime={disabledDateTime}
									/>
								</Form.Item>
							) : (
								<p style={{ padding: '4px', margin: 0 }}>
									{dayjs(currentChecklist.plannedDate).isValid()
										? dayjs(currentChecklist!?.plannedDate!).format(DATE_FORMAT.viewWithHours)
										: '-'}
								</p>
							)}
						</Descriptions.Item>

						<Descriptions.Item label="Фактическая дата проверки" span={12}>
							<Form.Item
								name="factDate"
								initialValue={
									dayjs(currentChecklist?.factDate).isValid()
										? dayjs(currentChecklist?.factDate)
										: null
								}
							>
								{!currentUser?.isAdmin ? (
									<p style={{ padding: '4px', margin: 0 }}>
										{dayjs(currentChecklist.factDate).isValid()
											? dayjs(currentChecklist?.factDate!).format(DATE_FORMAT.view)
											: '-'}
									</p>
								) : (
									<DatePicker
										variant="borderless"
										locale={locale}
										style={{ width: '100%' }}
										format={DATE_FORMAT.view}
										disabledDate={current => {
											let pastDate = dayjs().format(DATE_FORMAT.dto)
											return current && current < dayjs(pastDate, DATE_FORMAT.dto)
										}}
										disabled={allDisabled}
									/>
								)}
							</Form.Item>
						</Descriptions.Item>
					</Descriptions>
				)}
			</Card>
			<Divider />
			<Sections
				form={form}
				onUploadClickHandler={onUploadClickHandler}
				setIssueModalVisible={setIssueModalVisible}
				// checkPermissions={checkPermissions}
			/>
		</>
	)
}

export default ChecklistForm

const tagRender = (props: CustomTagProps) => {
	const { label, value, closable, onClose } = props
	const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
		event.preventDefault()
		event.stopPropagation()
	}
	return (
		<Tag
			color={'blue'}
			onMouseDown={onPreventMouseDown}
			closable={false}
			onClose={onClose}
			style={{ marginRight: 3 }}
		>
			{label}
		</Tag>
	)
}
