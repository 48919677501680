import { IBase } from 'interfaces/IBase'
import { IIssueType } from 'interfaces/IIssue'
import { API } from './ApiService'

export interface IWorkPackage extends IBase {
	issueTypes: IIssueType[]
	code?: number
}

export const workPackageService = {
	async getList() {
		return await API<IWorkPackage[]>({
			url: `/v1/workPackage`,
			method: 'get'
		}).then(response => response.data)
	},
	async setIssueTypes(workPackageId: string, issueTypesId: string[]) {
		return await API({
			url: `/v1/workPackage/${workPackageId}`,
			method: 'patch',
			data: { issueTypesId }
		}).then(response => response.data)
	},
}