import { CommentOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, Row, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useAppSelector } from 'hooks/appReduxHook'
import { useLocalEstimateState } from 'pages/unmodelPage/localEstimate/model/localEstimateState'
import { useWbsContainerState } from 'pages/unmodelPage/wbsContainer'
import { useMemo } from 'react'
import { useEstimateState } from 'widgets/estimate'
import { useEstimatePositionCommentsState } from 'widgets/estimate-position-comments'
import { EstimatePositionCommentsApi } from 'widgets/estimate-position-comments/api/asmComments.api'
import { IEstimateComment } from '..'

interface IProp {
	type?: 'wbs' | 'local'
}
export const useEstimateCommentsColumns = ({ type }: IProp) => {
	const { user } = useAppSelector(state => state.environment)
	const queryClient = useQueryClient()
	const { setEstimatePositions } = useEstimateState()
	const { updateSection } = useWbsContainerState()
	const { updateSection: updateSectionLocalEstimate } = useLocalEstimateState()

	const toggleChatState = async (id: string, sectionId: string) => {
		if (id) {
			await EstimatePositionCommentsApi.toggleEPChatState(id).then(() => {
				queryClient.invalidateQueries({ queryKey: ['asmComments'] })
				queryClient.invalidateQueries({ queryKey: ['wbsAuthorCommentStatus'] })
				queryClient.invalidateQueries({ queryKey: ['wbsCommentsUsers'] })
				queryClient.invalidateQueries({ queryKey: ['estimatePositionUsers'] })
				queryClient.invalidateQueries({ queryKey: ['estimate-comments'] })
				setEstimatePositions(sectionId!)
			})
		} else {
			type === 'wbs' ? updateSection(sectionId!) : updateSectionLocalEstimate(sectionId!)
		}
	}

	const columns = useMemo<ColumnsType<IEstimateComment>>(
		() => [
			{
				dataIndex: ['section', 'codifier'],
				title: 'Секция',
				width: 80,
				render: (value: string) =>
					value[value.length - 1] === '.' ? value.substring(0, value.length - 1) : value
			},
			{
				dataIndex: 'order',
				title: '№',
				width: 80,
				align: 'center'
			},
			{
				dataIndex: 'name',
				title: 'Позиция сметы'
			},
			{
				width: 96,
				dataIndex: 'id',
				render: (_, record) => (
					<Row wrap={false} align="middle" gutter={8}>
						<Col>
							{/* <CommentOutlined
							style={{
								color: getCommentStatus(record.isCommentsClosed)
							}}
						/> */}
							<Button
								size="small"
								type="text"
								icon={
									<CommentOutlined
									// style={{
									// 	color: getCommentStatus(record.isCommentsClosed)
									// }}
									/>
								}
								onClick={() =>
									useEstimatePositionCommentsState.setState({
										show: true,
										currentId: record.id
									})
								}
							/>
						</Col>
						<Col>
							<Tooltip title={record.isCommentsClosed ? 'Открыть чат' : 'Закрыть чат'}>
								<Button
									disabled={user?.id !== record.mainCommentAuthor?.id}
									type="text"
									danger={!record.isCommentsClosed}
									icon={record.isCommentsClosed ? <UnlockOutlined /> : <LockOutlined />}
									onClick={() => toggleChatState(record.id, record?.section?.id)}
								/>
							</Tooltip>
						</Col>
					</Row>
				)
			}
		],
		[]
	)
	return columns
}
