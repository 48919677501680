import { IBase } from 'interfaces/IBase';
import { API } from './ApiService';
import { IViolation } from './violationsService';

export interface IWorkPackage extends IBase{
    code?: number;
}

export interface INormativeAct extends IBase {
    violations: IViolation[];
    workPackages: IWorkPackage[];
}

export interface INormativeActDto {
    id: string;
    name: string;
    violationIds: string[];
    workPackageIds: string[];
}

export const normativeActsService = {

    async getNormativeActs(workPackageId?: string, violationId?: string) {
    return await API<INormativeAct[]>({
      url: '/v1/actPoints', 
      method: 'get',
      params: { workPackageId, violationId } 
    }).then(response => response.data);
  },

  
  async addNormativeAct(normativeActDto: INormativeActDto) {
    return await API({
      url: '/v1/actPoints', 
      method: 'post',
      data: normativeActDto 
    }).then(response => response.data);
  },

  async updateNormativeAct(normativeActDto: INormativeActDto) {
    return await API({
      url: `/v1/actPoints`,
      method: 'patch',
      data: normativeActDto 
    }).then(response => response.data);
  },

   async deleteNormativeAct(id: string) {
    return await API({
      url: `/v1/actPoints/${id}`,
      method: 'delete'
    }).then(response => response.data);
  }
};
