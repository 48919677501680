import { Button, Col, DatePicker, Form, Modal, Row, Select, Typography } from 'antd'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect } from 'react'
import { MONTHS } from 'shared/constants'
import { MobileReportApi } from '../api/mobile-report-api'
import { IMobileDto, IMobileForm } from '../model/mobile-report-interface'
import { useMobileReportState } from '../model/mobile-report-state'

interface IProps {
	show: boolean
	onClose: () => void
}

const { Title } = Typography

export const MobileReportCreateModal: FC<IProps> = ({ show, onClose }) => {
	const {
		isBlocked,
		filters,
		currentPage,
		setFilters,
		setMobileReport,
		setIsBlocked,
		setTotalItems,
		setTotalPages,
		onPagination
	} = useMobileReportState()
	const [MobileForm] = Form.useForm<IMobileForm>()
	const year = Form.useWatch('year', MobileForm)
	const projects = useAppSelector(state => state.environment.user?.projects)
	const project = useAppSelector(state => state.environment.project)

	const onModalClose = () => {
		setIsBlocked(false)
		MobileForm.resetFields()
		MobileForm.setFieldsValue({ year: undefined })
		onClose()
	}

	useEffect(() => {
		MobileForm.setFieldsValue({
			year: undefined,
			projects: [project.id],
			months: []
		})
		setFilters({
			year: filters?.year ?? +dayjs(new Date()).format('YYYY'),
			projectIds: filters?.projectIds ?? [project.id],
			months: filters?.months ?? []
		})
	}, [show])

	const onGetMobileReport = async () => {
		setIsBlocked(true)
		const data = MobileForm.getFieldsValue()
		const dto: IMobileDto = {
			projectIds: data.projects,
			year: dayjs(data?.year!).isValid()
				? +dayjs(data.year).format('YYYY')
				: +dayjs(new Date()).format('YYYY'),
			months:
				data.year === undefined || data.year === null || data.months?.length === 0
					? []
					: data.months ?? []
		}
		// setFilters(dto)
		await MobileReportApi.get({
			dto,
			limit: 50,
			offset: currentPage === 1 ? 0 : currentPage * 50 - 50
		}).then(response => {
			setMobileReport(response.data)
			setTotalItems(response!.totalItems)
			setTotalPages(response!.totalPages)
			setFilters(dto)
			onPagination(1)
			onModalClose()
		})
	}

	const setAllProjects = () => {
		MobileForm.setFieldValue(
			'projects',
			projects?.map(p => p.id)
		)
		MobileForm.validateFields()
	}

	useEffect(() => {
		if (year === undefined) {
			MobileForm.setFieldValue('months', [])
		}
	}, [year])

	const setAllMonths = () => {
		MobileForm.setFieldValue(
			'months',
			Object.entries(MONTHS)
				.filter(m => m[0] !== '0')
				.map((m: any) => (m[0] = +m[0]))
		)
		MobileForm.validateFields()
	}

	return (
		<Form form={MobileForm} name="mobileForm" onFinish={onGetMobileReport} disabled={isBlocked}>
			<Modal
				destroyOnClose
				onCancel={onClose}
				centered
				open={show}
				title="Создать отчёт"
				okButtonProps={{ disabled: isBlocked }}
				footer={
					<Row justify="space-between">
						<Col>
							<Button
								danger
								disabled={isBlocked}
								onClick={() =>
									MobileForm.setFieldsValue({
										year: undefined,
										projects: undefined,
										months: []
									})
								}
							>
								Сбросить
							</Button>
						</Col>
						<Col>
							<Button disabled={isBlocked} onClick={onClose}>
								Отмена
							</Button>
							,
							<Button disabled={isBlocked} type="primary" onClick={() => MobileForm.submit()}>
								Сформировать
							</Button>
						</Col>
					</Row>
				}
			>
				<Row justify="center">
					<Col
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '.5rem',
							padding: '1rem',
							width: '100%'
						}}
					>
						<>
							<Col>
								<Row align="middle" justify="space-between">
									<Col>
										<Title level={5}>Проекты:</Title>
									</Col>
									<Col>
										<Button type="link" size="small" onClick={setAllProjects}>
											Выбрать все
										</Button>
									</Col>
								</Row>
								<Form.Item
									name="projects"
									rules={[
										{
											required: true,
											message: 'Выберете хотя бы один проект'
										}
									]}
								>
									<Select
										mode="multiple"
										maxTagCount="responsive"
										allowClear
										style={{ width: '100%' }}
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										options={projects?.map(p => ({
											key: p.id,
											label: p.name,
											value: p.id
										}))}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Title level={5}>Год:</Title>
								<Form.Item
									name="year"
									rules={[
										{
											required: true,
											message: 'Выберете год'
										}
									]}
								>
									<DatePicker picker="year" format="YYYY" style={{ width: '100%' }} />
								</Form.Item>
							</Col>
							<Col>
								<Row align="middle" justify="space-between">
									<Col>
										<Title level={5}>Месяцы:</Title>
									</Col>
									<Col>
										<Button type="link" size="small" onClick={setAllMonths}>
											Выбрать все
										</Button>
									</Col>
								</Row>
								<Form.Item name="months">
									<Select
										mode="multiple"
										maxTagCount="responsive"
										// disabled={year === undefined || year === null}
										allowClear
										style={{ width: '100%' }}
										optionFilterProp="children"
										// filterOption={(input, option) =>
										// 	(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										// }
										options={(Object.entries(MONTHS) as any)
											?.filter((month: any) => month[0] !== '0')
											.map((m: any) => ({
												key: m[0],
												label: m[1],
												value: (m[0] = +m[0])
											}))}
									/>
								</Form.Item>
							</Col>
						</>
					</Col>
				</Row>
			</Modal>
		</Form>
	)
}
