import { QuestionCircleOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Form, Input, message, Modal, Select, Tooltip } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAdjustmentPositionCommentsState } from 'widgets/adjustment-position-comments'
import { useAdjustmentState } from 'widgets/adjustment/model/adjustment-state'
import { useEstimateState } from 'widgets/estimate'
import { useEstimatePositionCommentsState } from 'widgets/estimate-position-comments'
import { useShallow } from 'zustand/react/shallow'
import {
	IRegulatoryRequestForm,
	RegulatoryRequestApi,
	useRegulatoryRequestState,
	useRegulatoryRequestTypeSections
} from '..'

type IProps = {
	isAdjustment?: boolean
}

export const RegulatoryRequest: FC<IProps> = ({ isAdjustment }) => {
	const [isLoading, setIsLoading] = useState(false)
	const queryClient = useQueryClient()
	const [requestForm] = Form.useForm<IRegulatoryRequestForm>()
	const { show, toggle } = useRegulatoryRequestState(
		useShallow(state => ({
			show: state.show,
			toggle: state.toggleShow
		}))
	)

	const state = isAdjustment ? useAdjustmentPositionCommentsState : useEstimatePositionCommentsState

	const { currentId } = state(
		useShallow(state => ({
			currentId: state.currentId
		}))
	)

	const { pathname } = useLocation()
	const adjustmentContainer = useAdjustmentState(useShallow(state => state.container))
	const container = useEstimateState(useShallow(state => state.container))
	const { data: typeSections } = useRegulatoryRequestTypeSections()
	const closeHandler = () => {
		requestForm.resetFields()
		toggle(false)
	}

	useEffect(() => {
		if (show && (pathname.includes('unmodel/') || pathname.includes('local/')))
			requestForm.setFieldValue(
				'typeSectionId',
				isAdjustment ? adjustmentContainer?.typeSections.id : container?.typeSections.id
			)
	}, [show])

	const onRequest = async (values: IRegulatoryRequestForm) => {
		setIsLoading(true)
		await RegulatoryRequestApi.send({ ...values, estimatePositionId: currentId! })
			.then(() => {
				message.success('Заявка успешно отправлена')
				queryClient.invalidateQueries({ queryKey: ['wbsContainerComments'] })
				queryClient.invalidateQueries({ queryKey: ['estimatePosition'] })
				queryClient.invalidateQueries({ queryKey: ['estimatePositionUsers'] })
				queryClient.invalidateQueries({ queryKey: ['wbsAuthorCommentStatus'] })
				if (isAdjustment) {
					queryClient.invalidateQueries({ queryKey: ['adjustmentPositionComments'] })
					queryClient.invalidateQueries({ queryKey: ['adjustmentPositionResponsibleUsers'] })
				}
				closeHandler()
			})
			.catch(() => message.error('При отправке произошла ошибка'))
			.finally(() => setIsLoading(false))
	}

	return (
		<Modal
			destroyOnClose
			zIndex={1000000}
			title={
				<span>
					Заявка в НСИ
					<Tooltip
						zIndex={1000000}
						placement="right"
						title="Заявка отправляется в отдел НСИ в виде электронного письма. Как только Ваша заявка будет обработана, Вы получите по почте ответ."
					>
						<QuestionCircleOutlined style={{ marginLeft: '5px', color: 'red' }} />
					</Tooltip>
				</span>
			}
			open={show}
			onOk={() => requestForm.submit()}
			okButtonProps={{ loading: isLoading }}
			onCancel={() => toggle(false)}
			okText="Отправить"
		>
			<Form onFinish={onRequest} form={requestForm} layout="vertical">
				<Form.Item
					name="typeSectionId"
					label="Пакет работ:"
					rules={[{ required: true, message: 'Поле не должно быть пустым' }]}
				>
					<Select
						showSearch
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						options={typeSections?.map(s => ({
							key: s.id,
							value: s.id,
							label: s.name,
							disabled: s.isDeleted
						}))}
					/>
				</Form.Item>
				<Form.Item
					name="description"
					label="Описание:"
					rules={[{ required: true, min: 3, message: 'Поле должно содержать минимум 3 символа' }]}
				>
					<Input.TextArea style={{ height: '80px' }} showCount maxLength={2000} />
				</Form.Item>
			</Form>
		</Modal>
	)
}
