import { useQuery } from '@tanstack/react-query'
import { useEstimateState } from 'widgets/estimate'
import { useEstimatePositionCommentsState } from 'widgets/estimate-position-comments'
import { useShallow } from 'zustand/react/shallow'
import { EstimateCommentsApi, useEstimateCommentsState } from '..'

export const useEstimateComments = (filter?: boolean, filterMyComment?: boolean, type?: 'wbs' | 'local') => {
	const container = useEstimateState(useShallow(state => state.container))
	const showComments = useEstimateCommentsState(useShallow(state => state.showComments))
	return useQuery({
		queryKey: ['estimate-comments', container, showComments, filter, filterMyComment],
		queryFn: async () => {
			if (container && showComments) {
				return await EstimateCommentsApi.getAll(container!.id, filter, filterMyComment, type).then(
					res => res
				)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsAuthorCommentStatus = (id: string) => {
	const { show, currentId } = useEstimatePositionCommentsState()

	return useQuery({
		queryKey: ['estimate-authors', currentId, show],
		queryFn: async () => {
			return await EstimateCommentsApi.getAuthors(id!)
				.then(res => res)
				.catch(() => 'withoutButton')
		},
		refetchOnWindowFocus: false
	})
}
