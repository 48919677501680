import {
	Column,
	ExpandedState,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	Row,
	RowData,
	useReactTable
} from '@tanstack/react-table'
import {
	defaultRangeExtractor,
	elementScroll,
	Range,
	useVirtualizer,
	VirtualizerOptions
} from '@tanstack/react-virtual'
import { isEmpty } from 'lodash'
import { CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import LoaderSpinner from 'UI/loaderSpinner'
import { useShallow } from 'zustand/react/shallow'
import { useEstimateColumnConfig, useEstimateState } from '..'
import { RoundedNumber } from '../model/estimate-helper'
import {
	IEstimatePosition,
	IEstimatePositionMaterial,
	IEstimateSection
} from '../model/estimate-schema'
import { useEstimateRegistryColumns } from '../model/useEstimateRegistryColumns'
import './estimate.sass'

declare module '@tanstack/react-table' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface TableMeta<TData extends RowData> {
		updateData: (rowIndex: number, columnId: string, value: unknown) => void
	}
}

interface IProps {
	type: 'wbs' | 'local'
}

function easeInOutQuint(t: number) {
	return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
}

export const EstimateVirtualize: FC<IProps> = ({ type }) => {
	const {
		container,
		data,
		expandedRows,
		isLoading,
		scrollToNewElement,
		selectedRows,
		setExpandedRows
	} = useEstimateState(
		useShallow(state => ({
			container: state.container,
			data: state.sections,
			expandedRows: state.expandedRows,
			isLoading: state.isLoading,
			scrollToNewElement: state.scrollToNewElement,
			selectedRows: state.selectedRows,
			setExpandedRows: state.setExpandedRows,
			setSelectedRows: state.setSelectedRows
		}))
	)

	const visibleColumns = useEstimateColumnConfig(useShallow(state => state[type]))

	const [expanded, setExpanded] = useState<ExpandedState>({})
	const tableHeight = useMemo(() => {
		const appHeader = document.getElementById('app-header')?.offsetHeight ?? 0
		const breadcrumbs = document.getElementById('app-breadcrumbs')?.offsetHeight ?? 0
		const estimateHeader = document.getElementById('app-estimateHeader')?.offsetHeight ?? 0
		return window.innerHeight - (appHeader + breadcrumbs + estimateHeader + 250)
	}, [])
	const columns = useEstimateRegistryColumns()

	useEffect(() => {
		setExpandedRows(expanded)
	}, [expanded])

	useEffect(() => {
		if (isEmpty(expandedRows)) {
			table.resetExpanded()
		}
	}, [expandedRows])

	const table = useReactTable({
		data,
		columns,

		state: {
			expanded: expandedRows,
			rowSelection: selectedRows.rows,
			columnPinning: {
				left: ['name', 'measureUnit', 'amount', 'rate', 'amountWithRate']
			},
			columnVisibility: {
				priceMaterial: visibleColumns.price,
				priceService: visibleColumns.price,
				totalPrice: visibleColumns.totalPrice,
				totalSumMaterials: visibleColumns.sum,
				totalSumService: visibleColumns.sum,
				totalSum: visibleColumns.totalSum,
				noteSDU: typeof container?.isLocalEstimate !== 'undefined' ? false : visibleColumns.noteSDU,
				noteDZ: typeof container?.isLocalEstimate !== 'undefined' ? false : visibleColumns.noteDZ,
				noteDP: typeof container?.isLocalEstimate !== 'undefined' ? false : visibleColumns.noteDP,

				isNominated: visibleColumns.isNominated,
				confirmedVolumeAmount:
					container?.isLocalEstimate === true
						? false
						: 'confirmedVolume' in visibleColumns
						? visibleColumns.confirmedVolume
						: false,
				confirmedVolumeSum:
					container?.isLocalEstimate === true
						? false
						: 'confirmedVolume' in visibleColumns
						? visibleColumns.confirmedVolume
						: false
			}
		},
		enableColumnResizing: false,
		getRowId: originalRow => originalRow.id,
		getSubRows: row => (!('materialName' in row) ? row.children : []),
		getRowCanExpand: (row: Row<IEstimateSection | IEstimatePosition | IEstimatePositionMaterial>) =>
			!('materialName' in row.original) &&
			(('hasPositions' in row.original && row.original.hasPositions) ||
				(('codifier' in row.original || 'workName' in row.original) &&
					!!row.original.children?.length)),
		onExpandedChange: setExpanded,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel()
	})

	const { rows } = table.getRowModel()

	const parentRef = useRef<HTMLDivElement>(null)

	const activeStickyIndexRef = useRef(0)

	const stickyIndexes = useMemo(() => {
		const arr: number[] = []
		rows.forEach((r, index) => {
			if ('codifier' in r.original && r.getIsExpanded()) {
				arr.push(index)
			}
		})
		return arr
	}, [data, rows])

	const isSticky = (index: number) => stickyIndexes.includes(index)

	const isActiveSticky = (index: number) => activeStickyIndexRef.current === index
	const scrollingRef = useRef<number>()

	const scrollToFn: VirtualizerOptions<any, any>['scrollToFn'] = useCallback(
		(offset, canSmooth, instance) => {
			const duration = 1000
			const start = parentRef?.current?.scrollTop ?? 0
			const startTime = (scrollingRef.current = Date.now())

			const run = () => {
				if (scrollingRef.current !== startTime) return
				const now = Date.now()
				const elapsed = now - startTime
				const progress = easeInOutQuint(Math.min(elapsed / duration, 1))
				const interpolated = start + (offset - start) * progress

				if (elapsed < duration) {
					elementScroll(interpolated, canSmooth, instance)
					requestAnimationFrame(run)
				} else {
					elementScroll(interpolated, canSmooth, instance)
				}
			}
			requestAnimationFrame(run)
		},
		[]
	)

	const virtualizer = useVirtualizer({
		count: rows.length,
		overscan: 15,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 32,
		scrollToFn,
		rangeExtractor: useCallback(
			(range: Range) => {
				activeStickyIndexRef.current =
					[...stickyIndexes].reverse().find(index => range.startIndex >= index) ?? 0
				const next = new Set([activeStickyIndexRef.current, ...defaultRangeExtractor(range)])
				return [...next].sort((a, b) => a - b)
			},
			[stickyIndexes]
		)
	})

	useEffect(() => {
		if (scrollToNewElement) {
			const elem = rows.findIndex(r => r.id === scrollToNewElement)
			virtualizer.scrollToIndex(elem, { align: 'center', behavior: 'smooth' })
			useEstimateState.setState({ scrollToNewElement: undefined })
		}
	}, [scrollToNewElement])

	const getCommonPinningStyles = (
		column: Column<IEstimateSection | IEstimatePosition | IEstimatePositionMaterial>
	): CSSProperties => {
		const isPinned = column.getIsPinned()
		const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left')
		const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right')

		return {
			boxShadow: isLastLeftPinnedColumn
				? '-4px 0 4px -4px gray inset'
				: isFirstRightPinnedColumn
				? '4px 0 4px -4px gray inset'
				: undefined,
			left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
			right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
			position: isPinned ? 'sticky' : 'relative',
			width: column.getSize(),
			maxWidth: column.getSize(),
			zIndex: isPinned ? 1 : 0
		}
	}

	return (
		<>
			{isLoading && <LoaderSpinner />}
			<div
				ref={parentRef}
				style={{
					width: '100%',
					maxWidth: '100dvw',
					height: tableHeight,
					position: 'relative',
					overflow: 'auto'
				}}
			>
				<table
					className="estimate"
					style={{
						display: 'grid',
						width: table
							.getVisibleLeafColumns()
							.reduce((partialSum, a) => partialSum + a.getSize(), 0)
					}}
				>
					<thead className="estimate-header" id="estimate-header">
						{table.getHeaderGroups().map(headerGroup => (
							<tr key={headerGroup.id} className="estimate-header__row">
								{headerGroup.headers.map(header => {
									const columnRelativeDepth = header.depth - header.column.depth

									if (
										!header.isPlaceholder &&
										columnRelativeDepth > 1 &&
										header.id === header.column.id
									) {
										return null
									}

									let rowSpan = 1
									if (header.isPlaceholder) {
										const leafs = header.getLeafHeaders()
										rowSpan = leafs[leafs.length - 1].depth - header.depth
									}

									return (
										<th
											data-columnRelativeDepth={columnRelativeDepth}
											key={header.id}
											rowSpan={rowSpan}
											colSpan={header.colSpan}
											className={
												header.column.getIsPinned()
													? 'estimate-header__cell estimate-header__cell--fixed'
													: 'estimate-header__cell'
											}
											data-qa={header.rowSpan}
											style={{
												width: header.getSize(),
												...getCommonPinningStyles(header.column)
											}}
										>
											{flexRender(header.column.columnDef.header, header.getContext())}
										</th>
									)
								})}
							</tr>
						))}
					</thead>
					<tbody className="estimate-body" style={{ height: `${virtualizer.getTotalSize()}px` }}>
						<tr style={{ height: 32 }}>
							{table.getVisibleLeafColumns().map(cell => (
								<td
									className={
										cell.getIsPinned()
											? 'estimate-body__cell estimate-body__cell--fixed'
											: 'estimate-body__cell'
									}
									key={cell.id}
									style={{
										width: cell.getSize(),
										...getCommonPinningStyles(cell)
									}}
								>
									{cell.id === 'totalSum'
										? RoundedNumber(container?.workTotal.totalSum)
										: cell.id === 'totalSumMaterials'
										? RoundedNumber(container?.workTotal.totalSumMaterials)
										: cell.id === 'totalSumService'
										? RoundedNumber(container?.workTotal.totalSumService)
										: ''}
								</td>
							))}
						</tr>
						{virtualizer.getVirtualItems().map((virtualRow, index) => {
							const row = rows[virtualRow.index] as Row<
								IEstimateSection | IEstimatePosition | IEstimatePositionMaterial
							>

							return (
								<tr
									data-index={row.id}
									className={`estimate-body__row estimate-body__row-expanded estimate-body__row-expanded-level-${
										row.getParentRows().length
									} ${
										'isCopied' in row.original &&
										row.original.isCopied &&
										container?.isCorrection === false &&
										typeof container?.isLocalEstimate === 'undefined'
											? 'estimate-body__row--copy'
											: ''
									} ${
										'isUnrelated' in row.original &&
										row.original.isUnrelated === true &&
										((container?.isCorrection === true &&
											typeof container?.isLocalEstimate === 'undefined') ||
											container?.isLocalEstimate === false)
											? 'workName' in row.original
												? 'estimate-body__row--unrelated'
												: 'estimate-body__row--material-unrelated'
											: ''
									} ${
										'isActualRelationship' in row.original &&
										row.original.isActualRelationship === false
											? 'estimate-body__row--danger'
											: ''
									} ${'workName' in row.original ? 'estimate-body__row--work' : ''}
									`}
									key={virtualRow.key}
									style={{
										...(isSticky(virtualRow.index)
											? {
													background: '#fff',
													borderBottom: '1px solid #ddd',
													zIndex: 9
											  }
											: {}),
										...(isActiveSticky(virtualRow.index)
											? {
													position: 'sticky',
													top: document.getElementById('estimate-header')?.offsetHeight
											  }
											: {
													transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`
											  }),
										height: `${virtualRow.size}px`
									}}
								>
									{row.getVisibleCells().map((cell, i) => {
										return (
											<td
												className={
													cell.column.getIsPinned()
														? 'estimate-body__cell estimate-body__cell--fixed'
														: 'estimate-body__cell'
												}
												key={cell.id}
												style={{
													...getCommonPinningStyles(cell.column),
													paddingLeft:
														cell.column.getIndex() === 0
															? row.depth === 0
																? '1rem'
																: row.depth * 2 + 'rem'
															: 'auto'
												}}
											>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										)
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</>
	)
}
